import React from "react";
import styled from "styled-components";
import styles from "../../../styles";
import twids from './twids'
import moment from "moment";

const TWID = () => {
    const currentTWID = twids[0]

    const openTWIDInNewTab = () => {
        window.open(currentTWID.url, '_blank');
    }

    return <Container onClick={openTWIDInNewTab} backgroundImage={currentTWID.image}>
        <InnerTitle style={{margin: '0', fontSize: '0.8rem'}}>This Week in Destiny</InnerTitle>
        <InnerTitle style={{margin: '0'}}>{moment(currentTWID.date).format('MMM D, YYYY')}</InnerTitle>
    </Container>
}

const InnerTitle = styled.div`
    line-height: normal;
    font-size: 1.6em;
    border-radius: 4px;
    padding: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    user-select: none;
    width: min-content;
    ${styles.MediaQueries.Mobile} {
        margin: 0 20px;
        width: 100%;
        // text-align: center;
    }
    ${styles.MediaQueries.Desktop} {
        margin: 20px;
    }
`

const Container = styled.div`
    display: flex;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.25)),url(${props => props.backgroundImage});
    cursor: pointer;
    padding: 16px;
    flex-direction: column;
    justify-content: end;
    width: 100%;
    -webkit-transition: background-image 0.2s ease-in-out;
    transition: background-image 0.2s ease-in-out;
    ${styles.MediaQueries.Mobile} {
        min-height: 120px;
    }
    &:hover {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)),url(${props => props.backgroundImage});
    }
`

export default TWID