import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation
} from "react-router-dom";
import Home from './components/Home.js'
import Gallery from './components/Gallery.js'
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import MusicNerd from './components/MusicNerd/MusicNerd.js';
import ContactMe from './components/ContactMe.js';
import Gallery3D from './components/Gallery3D.js'
import D2Ghost from './components/D2Ghost/old/D2Ghost.js'
import D2Beta from './components/D2Ghost/beta/D2Beta.tsx'
import './index.css'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Builds from './components/D2Ghost/old/Builds.js';
import Activities from './components/D2Ghost/old/Activities.js';
import Sidebar from './components/common/Sidebar/Sidebar';
import NavigationSidebarLayout from './components/common/Sidebar/NavigationSidebarLayout';
import styled from 'styled-components';
import styles from './styles';
import { ExpandLess, ExpandMore, Home as HomeIcon, Reorder, Link as MUILink, Close } from '@mui/icons-material';
import Game from './components/threejs/Game.js';
import Planets from './components/threejs/Planets.js';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDSLAOKMrQpZvVL9CBoM9AKV16YIAZknfo",
  authDomain: "hysteria-house.firebaseapp.com",
  projectId: "hysteria-house",
  storageBucket: "hysteria-house.appspot.com",
  messagingSenderId: "1081420758072",
  appId: "1:1081420758072:web:06acbe22e6c1fd96733acd",
  measurementId: "G-EMBKMR1QP7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const SidebarTab = {
  Home: 'Home',
  Hypermap: 'Hypermap',
  D2Ghost: 'D2Ghost',
  MusicNerd: 'MusicNerd',
}

const App = () => {
  // const location = useLocation()

  const [title, setTitle] = useState(undefined)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [sidebarTab, setSidebarTab] = useState(SidebarTab.Home)

  console.log(location)

  const updateTitle =  (e) => {
    setTitle(e.target.value)
  }

  const createSidebarItemClickHandler = (tab) => (ev) => {
    setSidebarTab(tab)
    setIsSidebarOpen(!isSidebarOpen)
}

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home openSidebar={() => { setIsSidebarOpen(!isSidebarOpen) }} />}/>
        <Route exact path="/game" element={<Game openSidebar={() => { setIsSidebarOpen(!isSidebarOpen) }} />}/>
        <Route exact path="/planets" element={<Planets openSidebar={() => { setIsSidebarOpen(!isSidebarOpen) }} />}/>
        <Route path="/contact" element={<ContactMe />}/>
        <Route path="/d2ghost" element={<D2Ghost />}/>
        <Route path="/d2beta/*" element={<D2Beta />}/>
        <Route path="/d2ghost/activities" element={<Activities />}/>
        <Route path="/d2ghost/builds/:character?/:buildID?" element={<Builds />}/>
        <Route path="/gallery" element={<Gallery />}/>
        <Route path="/musicnerd" element={<MusicNerd/>}/>
      </Routes>
    </Router>
    
  );
}

const IconButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    ${styles.Animation.Transition}
    border: 1px solid transparent;
    &:hover {
        border: 1px solid #fc15fc;
        path {
            fill: #fc15fc !important;
        }
    }
`

const StyledClose = styled(Close)`
    path {
        fill: white;
        ${styles.Animation.Transition}
    }
`

const StyledReorder = styled(Reorder)`
    fill: inherit;
    path {
        fill: inherit;
    }
`

const SidebarItemText = styled.div`
    color: grey;
    margin-bottom: 8px;
`

const Scrollable = styled.div`
    margin-top: 20px;
    overflow-y: scroll;
    padding-right: 20px;
    flex: 1;
    ${styles.Scrollbar.HysteriaHouse}
`

const SidebarItemLabel = styled.div`
    letter-spacing: 0.1em;
    width: fit-content;
`

const SidebarItem = styled.div`
    width: 100%;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    :not(:last-child) {
        margin-bottom: 10px;
    }
    ${styles.Animation.Transition}
    .label {
        ${props => props.selected ? `color: #fc15fc;` : `color: white;`}
    }
    ${props => props.selected ? `
        border: 1px solid #fc15fc !important;
        ` : `
        border: 1px solid transparent;
    `}
    &:hover {
        border: 1px solid white;
        color: #fc15fc;
    }
`

export default App;
