import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import twids from '../../../old/twids'
import styles from "../../../../../styles"
import TabButton from "../TabButton"

enum ArticleType {
    TWID,
    PatchNotes,
    Maintenance
}

const BungieArticles = () => {
    const [articleType, setArticleType] = useState(ArticleType.TWID)

    const currentTWID = twids[0]

    const openTWIDInNewTab = () => {
        window.open(currentTWID.url, '_blank');
    }

    return <Container backgroundImage={currentTWID.image}>
        <ButtonRow>
            <TabButton 
                onClick={() => { setArticleType(ArticleType.TWID) }}
                selected={articleType === ArticleType.TWID}
            >
                TWID
            </TabButton>
            <TabButton 
                onClick={() => { setArticleType(ArticleType.PatchNotes) }}
                selected={articleType === ArticleType.PatchNotes}
            >
                Patch Notes
            </TabButton>
            <TabButton 
                onClick={() => { setArticleType(ArticleType.Maintenance) }}
                selected={articleType === ArticleType.Maintenance}
            >
                Maintenance
            </TabButton>
        </ButtonRow>
        <InnerTitle>This Week in Destiny</InnerTitle>
    </Container>
}

const InnerTitle = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
    line-height: normal;
    font-family: Lato;
    color: white;
    font-size: 1.6em;
    border-radius: 4px;
    padding: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    user-select: none;
    width: min-content;
    ${styles.MediaQueries.Mobile} {
        // margin: 0 20px;
        width: 100%;
        // text-align: center;
    }
    ${styles.MediaQueries.Desktop} {
        // margin: 20px;
    }
`

const ButtonRow = styled.div`
    position: absolute;
    top: 10px;
    left: 20px;
    display: flex;
`

const zoomAnimation = keyframes`
    0% {
        background-size: auto 200%;
    }
    100% {
        background-size: auto 210%;
    }
`

const zoomOutAnimation = keyframes`
    0% {
        background-size: auto 210%;
    }
    100% {
        background-size: auto 200%;
    }
`

type ContainerProps = {
    backgroundImage: string,
}

const Container = styled.div<ContainerProps>`
    position: relative;
    flex: 1;
    width: 100%;
    background-size: auto 200%;
    background-position: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.5)),url(${props => props.backgroundImage});
    ${styles.Animation.Transition}
    cursor: pointer;
    &:hover {
        animation: ${zoomAnimation} 2s forwards normal;
    }
    :not(&:hover) {
        animation: ${zoomOutAnimation} 2s forwards normal;
    }
`

export default BungieArticles