export const ExoticArmor = {
    Warlock: {
        Helmet: {},
        Arms: {
            ContraverseHolds: {
                name: 'Contraverse Holds',
                lightGG: 'https://www.light.gg/db/items/1906093346/contraverse-hold/',
                image: 'https://www.bungie.net/common/destiny2_content/icons/b667fa414b7d70ed4fe8b34296c76cbb.jpg',
            }
        },
        Chest: {},
        Boots: {},
    },
    Hunter: {
        Helmet: {},
        Arms: {},
        Chest: {},
        Boots: {},
    },
    Titan: {
        Helmet: {},
        Arms: {},
        Chest: {},
        Boots: {},
    },
}