import React, { useEffect, useState } from "react";
import styled from "styled-components";
import styles from "../../../styles";
import { AnimatePresence, motion } from "framer-motion";
import { Box, Flex, Image } from "@chakra-ui/react";
import { ReactComponent as RaidIcon } from 'assets/raid.svg'
import { ReactComponent as EngramIcon } from 'assets/empty-engram.svg'
import { ReactComponent as CrucibleIcon } from 'assets/crucible.svg'
import { ReactComponent as NightfallIcon } from 'assets/strike.svg'
import { ReactComponent as LostSectorIcon } from 'assets/lostSector.svg'
import { raids } from "./raids";
import { getChampionIcon, getCruciblePartyMode, getCrucibleRelentlessMode, getDungeon, getExoticMission, getNightfall, getRaid } from "./util";
import { seasons } from "./seasons";
import moment from "moment";
import { FeaturedTab, FeaturedTabs, ReputationBonus } from "./constants";

const WeeklyOperations = () => {

    const [selectedTab, setSelectedTab] = useState(FeaturedTab.Raid)
    const [isAutoplayEnabled, setIsAutoplayEnabled] = useState(true)
    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px').matches)
    window.matchMedia('(max-width: 768px').addEventListener('change', (ev) => {
        setIsMobile(ev.matches)
    })

    const currentSeason = seasons[0]
    const now = moment().utc();
    const seasonStartMoment = moment(currentSeason.startDate).utc().hour(17)
    const weeksCount = now.diff(seasonStartMoment, 'week')

    const autoplayInterval = setInterval(() => {
        setSelectedTab(prevTab => incrementFeaturedTab(prevTab))
    }, 5000);

    const currentRaid = Object.values(raids).at(-1)
    const currentRotatorRaid = getRaid()
    const currentDungeon = getDungeon()
    const currentNightfall = getNightfall(currentSeason, weeksCount)
    const currentExoticMission = getExoticMission(currentSeason)
    const currentCruciblePartyMode = getCruciblePartyMode(currentSeason)
    const currentCrucibleRelentlessMode = getCrucibleRelentlessMode(currentSeason)
    const weeksSinceFirstChallenge = now.diff(moment(currentRaid?.releaseDate).subtract(4, 'days').add(3, 'weeks'), 'weeks')

    const nth = (n: number) => `${n}${["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}`

    const incrementFeaturedTab = (tab: FeaturedTab) => {
        if (!isAutoplayEnabled) {
            return tab
        }

        switch (tab) {
            case FeaturedTab.Raid:
                return FeaturedTab.Nightfall
            case FeaturedTab.Nightfall:
                return FeaturedTab.WeeklyRaid
            case FeaturedTab.WeeklyRaid:
                return FeaturedTab.WeeklyDungeon
            case FeaturedTab.WeeklyDungeon:
                return FeaturedTab.WeeklyExoticMission
            case FeaturedTab.WeeklyExoticMission:
                return FeaturedTab.Crucible
            case FeaturedTab.Crucible:
                return FeaturedTab.Raid
        }
    }

    useEffect(() => {
        if (!isAutoplayEnabled) {
            clearInterval(autoplayInterval)    
        }

        return () => {
            clearInterval(autoplayInterval)
        };
    }, [ autoplayInterval ])

    const getFeaturedContent = () => {
        switch (selectedTab) {
            case FeaturedTab.Nightfall:
                return <FeaturedContentContainer key='nightfall' {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb>
                                <Flex flexDir='column'>
                                    <BlurbSubtitle>Champions</BlurbSubtitle>
                                    <Flex>
                                        {currentNightfall.strike.champions.map(champion => getChampionIcon(champion))}
                                    </Flex>
                                </Flex>
                            </InnerBlurb>
                            <InnerBlurb  onClick={() => { window.open(currentNightfall.weapon.lightGG, '_blank'); }}>
                                <Flex alignItems='center'>
                                    <WeaponImage src={currentNightfall.weapon.image}/>
                                    <Flex flexDir='column'>
                                        <BlurbSubtitle>Nightfall Weapon</BlurbSubtitle>
                                        <Box>{currentNightfall.weapon.name}</Box>
                                    </Flex>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>{currentNightfall.strike.name}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={currentNightfall.strike.image}/>
                </FeaturedContentContainer> 
            case FeaturedTab.Raid:
                return <FeaturedContentContainer key='raid'  {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb>
                                <RaidIcon style={{width: '40px', height: 'auto', marginRight: '10px'}}/>
                                <Flex flexDir='column' width='min-content'>
                                    <BlurbSubtitle>Active Challenge</BlurbSubtitle>
                                    <Flex>{nth((weeksSinceFirstChallenge % currentRaid.encounters) + 1)} Encounter</Flex>
                                </Flex>
                            </InnerBlurb>
                            <InnerBlurb onClick={() => { window.open(currentRaid.lightGG, '_blank'); }}>
                                <Flex alignItems='center'>
                                    <WeaponImage src={currentRaid.exoticImage}/>
                                    <Flex flexDir='column'>
                                        <BlurbSubtitle>Exotic</BlurbSubtitle>
                                        <Box>{currentRaid.exoticName}</Box>
                                    </Flex>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>{currentRaid.name}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={currentRaid.image}/>
                </FeaturedContentContainer>
            case FeaturedTab.WeeklyRaid:
                return <FeaturedContentContainer key='rotator-raid'  {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb>
                                <Flex justifyContent='space-between' alignItems='center'>
                                    <RaidIcon style={{width: '40px', height: 'auto', marginRight: '10px'}}/>
                                    <Flex style={{display: 'flex', flexDirection: 'column',}} >
                                        <BlurbSubtitle>Challenge</BlurbSubtitle>
                                        <Box fontSize='1em' fontFamily='Lato'>All challenges active</Box>
                                    </Flex>
                                </Flex>
                            </InnerBlurb>
                            <InnerBlurb whileHover={{ scale: 1.1 }} onClick={() => { window.open(currentRotatorRaid.lightGG, '_blank'); }}>
                                <Flex alignItems='center'>
                                    <WeaponImage src={currentRotatorRaid.exoticImage}/>
                                    <Flex flexDir='column'>
                                        <BlurbSubtitle>Exotic</BlurbSubtitle>
                                        <Box>{currentRotatorRaid.exoticName}</Box>
                                    </Flex>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>{currentRotatorRaid.name}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={currentRotatorRaid.image}/>
                </FeaturedContentContainer>
            case FeaturedTab.WeeklyDungeon:
                return <FeaturedContentContainer key='rotator-dungeon'  {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb style={{visibility: currentDungeon.hasMaster ? 'visible' : 'hidden'}}>
                                <Flex alignItems='center'>
                                    <RaidIcon style={{width: '40px', height: 'auto', marginRight: '10px'}}/>
                                    Master mode available
                                </Flex>
                            </InnerBlurb>
                            {currentDungeon.exoticName && <InnerBlurb whileHover={{ scale: 1.1 }} onClick={() => { window.open(currentDungeon.lightGG, '_blank'); }}>
                                <Flex alignItems='center'>
                                    <WeaponImage src={currentDungeon.exoticImage}/>
                                    <Flex flexDir='column'>
                                        <BlurbSubtitle>Exotic</BlurbSubtitle>
                                        <Box>{currentDungeon.exoticName}</Box>
                                    </Flex>
                                </Flex>
                            </InnerBlurb>}
                        </DetailsContainer>
                        <InnerTitle>{currentDungeon.name}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={currentDungeon.image}/>
                </FeaturedContentContainer> 
            case FeaturedTab.Crucible:
                return <FeaturedContentContainer key='crucible' {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb>
                                <Flex style={{display: 'flex', flexDirection: 'column',}} >
                                    <BlurbSubtitle>Party Mode</BlurbSubtitle>
                                    <Box fontSize='1em' fontFamily='Lato'>{currentCruciblePartyMode}</Box>
                                </Flex>
                            </InnerBlurb>
                            <InnerBlurb>
                                <Flex flexDir='column' alignItems={isMobile ? 'start' : 'end'}>
                                    <BlurbSubtitle>Relentless Mode</BlurbSubtitle>
                                    <Box fontSize='1em' fontFamily='Lato'>{currentCrucibleRelentlessMode}</Box>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>Crucible</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={ReputationBonus.Crucible.image}/>
                </FeaturedContentContainer>
            case FeaturedTab.WeeklyExoticMission:
                return <FeaturedContentContainer key='bonus' {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb>
                                <Flex style={{display: 'flex', flexDirection: 'column'}} >
                                    <BlurbSubtitle>Farmable Gear</BlurbSubtitle>
                                    <Box fontSize='1em' fontFamily='Lato'>{currentExoticMission.gear}</Box>
                                </Flex>
                            </InnerBlurb>
                            <InnerBlurb whileHover={{ scale: 1.1 }} onClick={() => { window.open(currentExoticMission.lightGG, '_blank'); }}>
                                <Flex alignItems='center'>
                                    <WeaponImage src={currentExoticMission.exoticImage}/>
                                    <Flex flexDir='column'>
                                        <BlurbSubtitle>Exotic</BlurbSubtitle>
                                        <Box>{currentExoticMission.exoticName}</Box>
                                    </Flex>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>{currentExoticMission.name}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={currentExoticMission.image}/>
                </FeaturedContentContainer>
        }
    }

    const getTabImage = (tab: string) => {
        switch (tab) {
            case FeaturedTab.Crucible:
                return `${currentCruciblePartyMode} & ${currentCrucibleRelentlessMode}`
            case FeaturedTab.WeeklyExoticMission:
                return currentExoticMission.image
            case FeaturedTab.WeeklyDungeon:
                return currentDungeon.image
            case FeaturedTab.WeeklyRaid:
                return currentRotatorRaid.image
            case FeaturedTab.Nightfall:
                return currentNightfall.strike.image
            case FeaturedTab.Raid:
            default:
                return (Object.values(raids).at(-1) as any)?.image
        }
    }
    
    const getTabValue = (tab: string) => {
        switch (tab) {
            case FeaturedTab.Crucible:
                return `${currentCruciblePartyMode} & ${currentCrucibleRelentlessMode}`
            case FeaturedTab.WeeklyExoticMission:
                return currentExoticMission.name
            case FeaturedTab.WeeklyDungeon:
                return currentDungeon.name
            case FeaturedTab.WeeklyRaid:
                return currentRotatorRaid.name
            case FeaturedTab.Nightfall:
                return currentNightfall.strike.name
            case FeaturedTab.Raid:
            default:
                return Object.values(raids).at(-1)?.name
        }
    }

    const getTabIcon = (tab: string) => {
        switch (tab) {
            case FeaturedTab.Crucible:
                return <CrucibleIcon style={{minWidth: '30px', width: '30px', height: 'auto', marginRight: '10px'}} fill='white'/>
            case FeaturedTab.WeeklyExoticMission:
                return <EngramIcon style={{minWidth: '30px', width: '30px', height: 'auto', marginRight: '10px'}} fill='white'/>
            case FeaturedTab.WeeklyDungeon:
                return <RaidIcon style={{minWidth: '30px', width: '30px', height: 'auto', marginRight: '10px'}} fill='white'/>
            case FeaturedTab.WeeklyRaid:
                return <RaidIcon style={{minWidth: '30px', width: '30px', height: 'auto', marginRight: '10px'}} fill='white'/>
            case FeaturedTab.Nightfall:
                return <NightfallIcon style={{minWidth: '30px', width: '30px', height: 'auto', marginRight: '10px'}} fill='white'/>
            case FeaturedTab.Raid:
            default:
                return <RaidIcon style={{minWidth: '30px', width: '30px', height: 'auto', marginRight: '10px'}} fill='white'/>
        }
    }

    return <Container>
        <FeaturedItemList>
            {FeaturedTabs.map((tab: string) => <FeaturedItem
                selected={selectedTab === tab}
                image={getTabImage(tab)}
                onClick={() => { 
                    setSelectedTab(tab);
                    setIsAutoplayEnabled(false); 
                }}
            >
                {!isMobile && getTabIcon(tab)}
                <div style={{display: 'flex', flex: 1, flexDirection: 'column', overflow: 'hidden'}}>
                    <div style={{ color: 'grey' }}>{tab}</div>
                    <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{getTabValue(tab)}</div>
                </div>
            </FeaturedItem>)}
        </FeaturedItemList>
        <RowItem style={{flexDirection: 'column', justifyContent: 'center', position: 'relative', padding: 0, flex: 1.25, height: '100%'}}>
            <AnimatePresence exitBeforeEnter>
                {getFeaturedContent()}
            </AnimatePresence>
        </RowItem>
    </Container>
}

const Container = styled.div`
    display: flex;
    flex: 1;
    max-width: 100%;
    justify-content: space-around;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        min-height: 280px;
    }
`

const FeaturedContent = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`

const FeaturedContentContainer = styled(motion.div)`
    position: relative;
    height: 100%;
`

const DetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column-reverse;
    }
`

const WeaponImage = styled(Image)`
    height: 2em;
    width: 2em;
    border: 1px solid white;
    margin-right: 10px;
`

type BackgroundImageProps = {
    image: any,
}

const BackgroundImage = styled.div<BackgroundImageProps>`
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.25)),url(${props => props.image});
`

const BlurbSubtitle = styled.div`
    width: min-content;
    border-bottom: 1px solid white;
    font-size: 14px;
    // color: grey;
    margin-bottom: 2px;
`

const InnerBlurb = styled(motion.div)`
    width: fit-content;
    display: flex;
    padding: 6px 10px;
    background-color: rgba(0,0,0,0.5);
    border: 1px solid transparent;
    backdrop-filter: blur(8px);
    white-space: nowrap;
    user-select: none;
    transition: all 0.2s ease-in;
    fill: white;
    margin-top: 10px;
    ${styles.MediaQueries.Mobile} {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: 2px 10px;
    }
    ${styles.MediaQueries.Desktop} {
        :not(:last-child) {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        :last-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }
    }
    border: 1px solid transparent;
    ${props => props.onClick && `
        cursor: pointer;
        border: 1px solid white;
        &:hover {
            background-color: white;
            color: black !important;
            fill: black !important;
        }
    `}
`

const InnerTitle = styled.div`
    line-height: normal;
    font-size: 1.8em;
    border-radius: 4px;
    padding: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    user-select: none;
    width: min-content;
    max-width: 100%;
    ${styles.MediaQueries.Mobile} {
        padding: 10px 20px;
        width: 100%;
        font-size: 1.6em;
    }
    ${styles.MediaQueries.Desktop} {
        padding: 20px;
    }
`

type FeaturedItemProps = {
    selected: boolean,
    image: string,
}

const FeaturedItem = styled.div<FeaturedItemProps>`
    cursor: pointer;
    display: flex;
    padding: 2px 14px;
    align-items: center;
    flex: 1;
    max-height: 80px;
    overflow: hidden;
    font-size: 0.9em;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%,  rgba(0, 0, 0, 0.8) 35%, rgba(0, 0, 0, 0.2) 100%), url("${props => props.image}");
    background-size: cover;
    background-position: center;
    background-color: rgba(0,0,0,0.85);
    &:hover {
        * { color: white !important; }
        background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%,  rgba(0, 0, 0, 0.8) 35%, rgba(0, 0, 0, 0.1) 100%), url("${props => props.image}");
    }
    ${props => props.selected && `
        background-color: #2222a2 !important;
        color: white !important;
        * { color: white !important; }
        background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%,  rgba(0, 0, 0, 0.8) 35%, rgba(0, 0, 0, 0) 100%), url("${props.image}");
        opacity: 1 !important;
    `}
    transition: all 0.2s ease-in;
    ${styles.MediaQueries.Mobile} {
        min-height: 50px;
        font-size: 0.8em;
        min-width: 100px;
        max-width: 100px;
        padding: 2px 8px;
        // justify-content: center;
        border-bottom: 1px solid grey;
        :not(:last-child) {
            border-right: 1px solid grey;
        }
    }
    ${styles.MediaQueries.Desktop} {
        min-width: 140px;
        max-width: 200px;
        :not(:last-child) { 
            border-bottom: 1px solid grey;
        }
        &:hover {
            background-color: rgba(255,255,255,0.1);
        }
    }
`

const FeaturedItemList = styled.div`
    display: flex;
    white-space: nowrap;
    font-size: 0.9em;
    :not(:last-child) { 
        border-right: 1px solid grey;
    }
    ${styles.MediaQueries.Mobile} {
        overflow-x: scroll;
        ${styles.Scrollbar.Transparent}
    }
    ${styles.MediaQueries.Desktop} {
        flex-direction: column;
    }
`

const RowItem = styled.div`
    display: flex;
    padding: 10px;
    flex: ${props => props.flex || 1};
    :not(:last-child) { 
        border-right: 1px solid grey;
    }
`

export default WeeklyOperations