import React from 'react';
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'

class HomePage extends React.Component {
    render() {
        const { nextPage } = this.props;

        return (<>
            <motion.div
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                transition={{
                    duration: 1.6
                }}
            >
                <Heading  marginTop='3%' fontSize={{sm: '1.5em', xl: '2em'}} color="whiteAlpha.800">Professional Experience</Heading>
            </motion.div>
            <Flex flex={1} direction='column' justify='space-around' w='100%'>
                <motion.div
                    initial={{
                        opacity: 0,
                        x: '80%'
                    }}
                    animate={{
                        opacity: 1,
                        x: '0%',
                    }}
                    transition={{
                        duration: 1,
                    }}
                >
                    <Flex w='100%' align='center'>
                        <Image
                            src="workday.jpeg"
                            alt="Workday"
                            height="auto"
                            borderRadius="15px"
                            width='20%'
                            maxWidth='200px'
                            margin='20px 0'
                            border='1px solid #333333'
                            marginLeft='4vw'
                        />
                        <Box h='100%' flex={1} margin='0 20px'>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.800' fontWeight='bold' w='100%' textAlign='center'>Workday (2019 - Current)</Text>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.600' w='100%' textAlign='center'>Tech Stack: React, Redux, Typescript</Text>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.600' w='100%' textAlign='center'>Position: Sr. Associate Software Engineer</Text>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.600' w='100%' textAlign='center'>
                                <li>Create reusable React web components and maintain a large scale analytics platform UI</li>
                            </Text>
                        </Box>
                    </Flex>
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0,
                        x: '80%'
                    }}
                    animate={{
                        opacity: 1,
                        x: '0%',
                    }}
                    transition={{
                        duration: 1,
                    }}
                >
                    <Flex w='100%' align='center'>
                        <Image
                            src="hypermap.png"
                            alt="HyperMap"
                            height="auto"
                            borderRadius="15px"
                            width='20%'
                            maxWidth='200px'
                            margin='20px 0'
                            border='1px solid #333333'
                            marginLeft='4vw'
                        />
                        <Box h='100%' flex={1} margin='0 20px'>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.800' fontWeight='bold' w='100%' textAlign='center'>HyperMap (2018 - Current)</Text>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.600' w='100%' textAlign='center'>Tech Stack: React, Typescript, Google Firebase, Material UI</Text>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.600' w='100%' textAlign='center'>Position: CTO</Text>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.600' w='100%' textAlign='center'>
                                <li>Web Design and UI, API Web services, Python scripting, Web Security, and Data Structuring</li>
                            </Text>
                        </Box>
                    </Flex>
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0,
                        x: '80%'
                    }}
                    animate={{
                        opacity: 1,
                        x: '0%',
                    }}
                    transition={{
                        duration: 1,
                    }}
                >
                    <Flex w='100%' align='center'>
                        <Image
                            src="contracts.png"
                            alt="Freelance"
                            height="auto"
                            borderRadius="15px"
                            width='20%'
                            maxWidth='200px'
                            margin='20px 0'
                            border='1px solid #333333'
                            marginLeft='4vw'
                        />
                        <Box h='100%' flex={1} margin='0 20px'>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.800' fontWeight='bold' w='100%' textAlign='center'>Contract Work</Text>
                            <Text fontSize={['3vw', '1.2vw']} color='whiteAlpha.600' w='100%' textAlign='center'>
                                <li>Porsche: ThreeJS Responsive Generative Art for Web</li>
                                <li>The Boring Company: Shopify API Tool</li>
                            </Text>
                        </Box>
                    </Flex>
                </motion.div>
            </Flex>
            {/* <Button 
                background='linear-gradient(-45deg, #871ac7, #e73c7e)' 
                color='whiteAlpha.900'
                onClick={nextPage}
                _hover={{ border: `1px solid #cccccc`  }}
                marginBottom='3%'
            >
                {"Education >"}
            </Button> */}
        </>)
    }
}

export default HomePage;