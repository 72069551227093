import { useEffect, useState, useRef } from 'react'
import { Flex, Box, Image } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import { useNavigate, useParams, useMatch, Outlet } from "react-router-dom";
import { ChampionTypes, FeaturedTabs, PlanetaryTabs, ReputationBonus, classes, menu } from './constants.ts'
import { planets } from './planets'
import { raids } from './raids'
import { seasons } from './seasons'
import { getAltarsWeapon, getCruciblePartyMode, getCrucibleRelentlessMode, getSKStoryMission, getDungeon, getLightfallStoryMission, getPartition, getExoticMission, getExoSimulation, getEmpireHunt, getLostSector, getLostSectorArmor, getNightfall, getRaid, getTerminalOverload, getWQStoryMission, getWellspring } from './util'
import styled from 'styled-components';
import { ReactComponent as RaidIcon } from 'assets/raid.svg'
import { ReactComponent as CrucibleIcon } from 'assets/crucible.svg'
import { ReactComponent as NightfallIcon } from 'assets/strike.svg'
import { ReactComponent as LostSectorIcon } from 'assets/lostSector.svg'
import moment from 'moment';
import TWID from './TWID';
import styles from '../../../styles.ts';
import LostSector from './LostSector.tsx';
import { getChampionIcon } from './util';
import DailyRotators from './DailyRotators.tsx';
import WeeklyOperations from './WeeklyOperations.tsx';
import PlanetaryReport from './PlanetaryReport.tsx';
import { ExpandLess, ExpandMore, Home as HomeIcon, Reorder, Link as MUILink, Close } from '@mui/icons-material';
import Sidebar from '../../common/Sidebar/Sidebar.tsx';
import NavigationSidebarLayout from '../../common/Sidebar/NavigationSidebarLayout.tsx';

const fadeProps = {
    initial: {opacity: 0, y: -20},
    exit: {opacity: 0, y: -20},
    animate: {opacity: 1, y: 0},
    transition: {duration: 0.5}
}

// const authorize = async () => {
//     const clientId = "YOUR_CLIENT_ID";
//     const clientSecret = "YOUR_CLIENT_SECRET";
//     const redirectUri = "YOUR_CALLBACK_URL";
//     const code = new URLSearchParams(window.location.search).get("code");
  
//     const response = await fetch("https://www.bungie.net/en/OAuth/Authorize", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded",
//         "Authorization": "Basic " + btoa(clientId + ":" + clientSecret),
//       },
//       body: `grant_type=authorization_code&code=${code}&client_id=${clientId}&client_secret=${clientSecret}&redirect_uri=${redirectUri}`,
//     });
  
//     const data = await response.json();
//     const accessToken = data.access_token;

//     return accessToken
// }

// async function getPublicVendorItems(apiKey, callback) {
//     const baseUrl = "https://www.bungie.net/Platform";
//     const headers = { 
//         "X-API-Key": apiKey,
//     };
  
//     try {
//       // Fetch the vendor's items
//       const response = await fetch(
//         `${baseUrl}/Destiny2/Vendors/?components=400,401,402`,
//         { headers }
//       );
  
//       if (!response.ok) {
//         throw new Error(`Error fetching vendor items: ${response.statusText}`);
//       }
  
//       const data = await response.json();
//       const vendors = data.Response.vendors.data;
  
//     //   console.log(data.Response.sales.data['2190858386'].saleItems)
//     //   return data.Response.sales.data['2190858386'].saleItems

//       // Extract the vendor's items
//     //   const vendor = vendor.itemComponents.sales.data;
//       const itemDetails = [];
  
//       // Fetch the details of each item
//       const items = Object.keys(data.Response.sales.data['2190858386'].saleItems).map(key => data.Response.sales.data['2190858386'].saleItems[key].itemHash)
//     //   console.log(items)
//       for (const itemHash of items) {
//         // console.log(itemHash)
//         const itemResponse = await fetch(`${baseUrl}/Destiny2/Manifest/DestinyInventoryItemDefinition/${itemHash}`, { headers });
//         const itemData = await itemResponse.json();
//         itemDetails.push(itemData);
//       }
  
//       console.log("Vendor Items:", itemDetails);
//       callback(itemDetails)
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   }

async function getAbilities(apiKey, membershipType, destinyMembershipId, characterId, vendorHash) {
    const baseUrl = "https://www.bungie.net/Platform";
    const headers = { 
        "X-API-Key": apiKey,
    };
  
    try {
      // Fetch the vendor's items
    //   const response = await fetch(
    //     `${baseUrl}/Destiny2/${membershipType}/Profile/${destinyMembershipId}/Character/${characterId}/Vendors/${vendorHash}/?components=400,401,402`,
    //     { headers }
    //   );

      const response = await fetch(`https://www.bungie.net/Platform/Destiny2/2/Profile/${destinyMembershipId}/Character/${characterId}/?components=400,401,402`, { headers })
  
      if (!response.ok) {
        throw new Error(`Error fetching vendor items: ${response.statusText}`);
      }
  
      const data = await response.json();
      const vendor = data.Response.vendor.data;
  
      // Extract the vendor's items
      const items = vendor.itemComponents.sales.data;
      const itemDetails = [];
  
      // Fetch the details of each item
      for (const itemHash in items) {
        const itemResponse = await fetch(`${baseUrl}/Destiny2/Manifest/DestinyInventoryItemDefinition/${itemHash}`, { headers });
        const itemData = await itemResponse.json();
        itemDetails.push(itemData.Response.displayProperties.name);
      }
  
      console.log("Vendor Items:", itemDetails);
      return itemDetails;
    } catch (error) {
      console.error("Error:", error);
    }
  }

async function getVendorItems(apiKey, membershipType, destinyMembershipId, characterId, vendorHash) {
    const baseUrl = "https://www.bungie.net/Platform";
    const headers = { 
        "X-API-Key": apiKey,
    };
  
    try {
      // Fetch the vendor's items
    //   const response = await fetch(
    //     `${baseUrl}/Destiny2/${membershipType}/Profile/${destinyMembershipId}/Character/${characterId}/Vendors/${vendorHash}/?components=400,401,402`,
    //     { headers }
    //   );

      const response = await fetch("https://www.bungie.net/platform/Destiny/Manifest/InventoryItem/1274330687/", { headers })
  
      if (!response.ok) {
        throw new Error(`Error fetching vendor items: ${response.statusText}`);
      }
  
      const data = await response.json();
      const vendor = data.Response.vendor.data;
  
      // Extract the vendor's items
      const items = vendor.itemComponents.sales.data;
      const itemDetails = [];
  
      // Fetch the details of each item
      for (const itemHash in items) {
        const itemResponse = await fetch(`${baseUrl}/Destiny2/Manifest/DestinyInventoryItemDefinition/${itemHash}`, { headers });
        const itemData = await itemResponse.json();
        itemDetails.push(itemData.Response.displayProperties.name);
      }
  
      console.log("Vendor Items:", itemDetails);
      return itemDetails;
    } catch (error) {
      console.error("Error:", error);
    }
  }
  
// Usage
const apiKey = "24aa9641a6b542de9e71873604e70c20";
const membershipType = 2; // Steam, for example
const destinyMembershipId = "4611686018449742101";
const characterId = "2305843009260303799";
const vendorHash = "863940356";
  
getVendorItems(apiKey, membershipType, destinyMembershipId, characterId, vendorHash);
getAbilities(apiKey, membershipType, destinyMembershipId, characterId, vendorHash)

const D2Ghost = (props) => {
    const match = useMatch('/d2ghost/:?tab');
    

    const navigate = useNavigate();
    const params = useParams();
    console.log(match)

    // const [path, setPath] = useState([])
    const [sidebarDir, setSidebarDir] = useState(1)
    const [sidebarContent, setSidebarContent] = useState(undefined)
    const [vendors, setVendors] = useState([])
    const [selectedFeaturedTab, setSelectedFeaturedTab] = useState(FeaturedTabs.Raid)
    const [selectedPlanetaryTab, setSelectedPlanetaryTab] = useState(PlanetaryTabs.Neomuna)
    const [isFeaturedAutoplayEnabled, setIsFeaturedAutoplayEnabled] = useState(true)
    const [isPlanetaryAutoplayEnabled, setIsPlanetaryAutoplayEnabled] = useState(false/true)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px').matches)
    window.matchMedia('(max-width: 768px').addEventListener('change', (ev) => {
        setIsMobile(ev.matches)
    })

    const currentSeason = seasons[0]
    const seasonStartMoment = moment(currentSeason.startDate).utc().hour(17)
    
    const now = moment().utc();
    let timerID
    // console.log(moment().utc().hour(17).diff(moment().utc(), 'hours'))

    const contentRef = useRef(null)

    const weeksCount = now.diff(seasonStartMoment, 'week')
    const daysCount = now.diff(seasonStartMoment, 'day')
    const thisWeekTuesday = moment(seasonStartMoment).add(weeksCount, 'weeks').format('MMM D, YYYY')
    const thisWeekMonday = moment(seasonStartMoment).add(weeksCount, 'weeks').add(6, 'days').format('MMM D, YYYY')

    const currentRotatorRaid = getRaid(weeksCount)
    const currentDungeon = getDungeon(weeksCount)
    const currentNightfall = getNightfall(currentSeason, weeksCount)
    const currentExoticMission = getExoticMission(currentSeason)
    const currentLostSector = getLostSector(currentSeason, daysCount)
    const currentLFStoryMission = getLightfallStoryMission()
    const currentWQStoryMission = getWQStoryMission()
    const currentSKStoryMission = getSKStoryMission()
    const currentCruciblePartyMode = getCruciblePartyMode(currentSeason)
    const currentCrucibleRelentlessMode = getCrucibleRelentlessMode(currentSeason)
    const currentEmpireHunt = getEmpireHunt(currentSeason)
    const currentExoSimulation = getExoSimulation(currentSeason)

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    useEffect(() => {
        if (contentRef) {
            contentRef.current.scrollTop = -contentRef.current.scrollHeight
        }
    }, [contentRef.current])

    // useEffect(() => {
    //     getPublicVendorItems(apiKey, setVendors)
    // })

    const nth = (n) => `${n}${["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}`

    const getTabContent = () => {
        switch(params.tabID) {
            default:
                return <motion.div ref={contentRef} key='home' {...fadeProps} style={{display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', flex: 1}}>
                    <Flex flexDir={isMobile ? 'column-reverse' : 'column'} flex={1} justifyContent='space-between'>
                        <Bubble title='This Week in Destiny'>
                            <TWID/>
                        </Bubble>
                        <Flex flex={3}>
                            {!isMobile && <Flex flexDir='column' flex={1} justifyContent='space-between'>
                                <Flex 
                                    flex={1} 
                                    margin='10px' 
                                    fontFamily='Lato' 
                                    textTransform='uppercase'
                                    flexDir='column'
                                >
                                    <Box
                                        border='1px solid white'
                                        height='min-content'
                                        width='100%'
                                        padding='18px'
                                        letterSpacing='0.2em'
                                        cursor='pointer'
                                        transition='all 0.2s ease-in'
                                        marginBottom='20px'
                                        backdropFilter='blur(2px)' 
                                        _hover={{
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }}
                                        onClick={() => { goToPage('builds') }}
                                    >
                                        Builds
                                    </Box>
                                    <Box
                                        border='1px solid white'
                                        height='min-content'
                                        width='100%'
                                        padding='18px'
                                        letterSpacing='0.2em'
                                        cursor='pointer'
                                        transition='all 0.2s ease-in'
                                        marginBottom='20px'
                                        backdropFilter='blur(2px)' 
                                        _hover={{
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }}
                                        onClick={() => { goToPage('activities') }}
                                    >
                                        Activities
                                    </Box>
                                    <Box
                                        border='1px solid white'
                                        height='min-content'
                                        width='100%'
                                        padding='18px'
                                        letterSpacing='0.2em'
                                        cursor='pointer'
                                        transition='all 0.2s ease-in'
                                        marginBottom='20px'
                                        backdropFilter='blur(2px)' 
                                        _hover={{
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }}
                                        onClick={() => { goToPage('gear') }}
                                    >
                                        Gear
                                    </Box>
                                    <Box
                                        border='1px solid white'
                                        height='min-content'
                                        width='100%'
                                        padding='18px'
                                        letterSpacing='0.2em'
                                        cursor='pointer'
                                        transition='all 0.2s ease-in'
                                        marginBottom='20px'
                                        backdropFilter='blur(2px)' 
                                        _hover={{
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }}
                                        onClick={() => { goToPage('info') }}
                                    >
                                        Info
                                    </Box>
                                    <Box
                                        border='1px solid white'
                                        height='min-content'
                                        width='100%'
                                        padding='18px'
                                        letterSpacing='0.2em'
                                        cursor='pointer'
                                        transition='all 0.2s ease-in'
                                        marginBottom='20px'
                                        backdropFilter='blur(2px)' 
                                        _hover={{
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }}
                                        onClick={() => { goToPage('suggest') }}
                                    >
                                        Suggest
                                    </Box>
                                </Flex>
                            </Flex>}
                            <Flex flexDir='column' flex={1} justifyContent='space-between'>
                                <Bubble minHeight='50%' flex={1} title={<Flex alignItems='center'><LostSectorIcon fill='white' style={{marginRight: '10px'}} height='20px' />Lost Sector</Flex>}>
                                    <LostSector lostSector={currentLostSector}/>
                                </Bubble>
                                <Bubble flex={1} title='Daily Rotators'>
                                    <DailyRotators/>
                                </Bubble>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex flexDirection='column' flex={1}>
                        <Bubble title='Weekly Operations' flex={0}>
                            <WeeklyOperations/>
                        </Bubble>
                        {/* <Bubble style={{overflowX: 'auto'}} title='Eververse Bright Dust Offerings'>
                            {vendors?.map(v => <img style={{width: '40px', height: '40px'}} src={`https://www.bungie.net${v.Response.displayProperties.icon}`} />)}
                        </Bubble>
                        <Bubble title='Xur'>
                            <Flex flex={1} justifyContent='center' alignItems='center' color='grey'>
                                Xur has not yet been spotted this week
                            </Flex>
                        </Bubble> */}
                        {/* <Bubble title='Ada and Banshee'>
                        </Bubble> */}
                        <Flex flexDirection='column' flex={1}>
                            <Bubble maxHeight='100%' title='Planetary Report'>
                                <PlanetaryReport/>
                            </Bubble>
                        </Flex>
                    </Flex>
                </motion.div>
        }
    }

    // const featuredInterval = setInterval(() => {
    //     setSelectedFeaturedTab(prevTab => incrementFeaturedTab(prevTab))
    // }, 5000);        


    // const planetaryInterval = setInterval(() => {
    //     setSelectedPlanetaryTab(prevTab => incrementPlanetaryTab(prevTab))
    // }, 5000);

    // useEffect(() => {
    //     if (!isPlanetaryAutoplayEnabled) {
    //         clearInterval(planetaryInterval)    
    //     }
    //     if (!isFeaturedAutoplayEnabled) {
    //         clearInterval(featuredInterval)
    //     }

    //     return () => {
    //         console.log('clearing autoplay intervals')
    //         clearInterval(featuredInterval)
    //         clearInterval(planetaryInterval)
    //     };
    // }, [ featuredInterval, planetaryInterval ])

    const goToPage = (tab) => {
        navigate(`/d2ghost/${tab}`)
    }

    const currentRaid = Object.values(raids).at(-1)
    const weeksSinceFirstChallenge = now.diff(moment(currentRaid.releaseDate).subtract(4, 'days').add(3, 'weeks'), 'weeks')

    // useEffect(() => {
    //     setSidebarContent(<motion.div
    //         key={path[path.length - 1] || 'main'}
    //         initial={{ opacity: 0, x: 100 * sidebarDir }}
    //         exit={{ opacity: 0, x: 100 * sidebarDir }}
    //         animate={{ opacity: 1, x: 0 }}
    //         transition={{ duration: 0.5 }}
    //     >
    //         <Flex
    //             flexDir='column'
    //             margin='40px 20px'
    //         >
    //             <Box
    //                 borderBottom='1px solid grey'
    //                 color='grey'
    //                 letterSpacing='0.4em'
    //             >
    //                 info
    //             </Box>
    //             {getSidebarValues().map(item => <Flex
    //                 key={item}
    //                 border='1px solid white'
    //                 color='white'
    //                 width='280px'
    //                 height='48px'
    //                 alignItems='center'
    //                 paddingLeft='12px'
    //                 fontFamily='Roboto'
    //                 margin='10px 0'
    //                 cursor='pointer'
    //                 onClick={() => { updatePath(item) }}
    //                 _hover={{
    //                     color: 'rgb(40,40,40)',
    //                     backgroundColor: 'white'
    //                 }}
    //             >
    //                 {item}
    //             </Flex>)}
    //         </Flex>
    //     </motion.div>)
    // }, [path])

    return <Flex 
        h='100vh' 
        w='100vw'
        maxH='-webkit-fill-available'
        // maxW='-webkit-fill-available'
        backgroundColor='rgb(10,10,10)'
        overflowY='hidden'
    >
        <Sidebar onClose={toggleSidebar} isOpen={isSidebarOpen}>
            <NavigationSidebarLayout onClose={toggleSidebar}/>
        </Sidebar>
        <AnimatePresence exitBeforeEnter>
            {/* {sidebarContent} */}
            <Outlet/>
            <Content>
                <Flex minHeight='60px' alignItems='center' margin='10px 20px' overflow='hidden' >
                    <Flex borderRadius='50%' cursor='pointer' justifyContent='center' alignItems='center' transition='all 0.2s ease-in' marginRight='20px' width='40px' height='40px' border='1px solid transparent' _hover={{ border: '1px solid white' }} onClick={toggleSidebar}>
                        <Reorder/>
                    </Flex>
                    <Flex onClick={() => { navigate('/d2ghost') }} userSelect='none' _hover={{ backgroundColor: 'white', color: 'black' }} transition='all 0.2s ease-in' cursor='pointer' alignItems='center' backgroundColor='#171717' borderRadius='4px' border='1px solid white' padding='1px 10px'>
                        <Image width='40px' height='40px' marginRight='10px' src='/d2ghost.png' />
                        D2Ghost
                    </Flex>
                    {!isMobile && <>
                        <Flex height='44px' backgroundColor={currentSeason.color} borderRadius='4px' padding='2px 10px' marginLeft='20px' flexDir='column'>
                            <Box padding='0' fontSize='12px' textDecor='underline'>Season {currentSeason.number}</Box>
                            <Box padding='0' lineHeight='normal' fontSize='16px'>{currentSeason.name}</Box>
                        </Flex>
                        <Flex height='44px' backgroundColor='#2222a2' borderRadius='4px' padding='2px 10px' marginLeft='20px' flexDir='column'>
                            <Box padding='0' fontSize='12px' textDecor='underline'>Week {weeksCount + 1}</Box>
                            <Box whiteSpace='nowrap' padding='0' fontSize='16px'>{`${thisWeekTuesday} - ${thisWeekMonday}`}</Box>
                        </Flex>
                        <Flex justifyContent='center' height='44px' backgroundColor='#ab1416' borderRadius='4px' padding='2px 10px' marginLeft='20px' flexDir='column'>
                            <Box padding='0' fontSize='12px' textDecor='underline'>Weekly Bonus</Box>
                            <Box padding='0' lineHeight='normal' fontSize='16px'>Valor (Crucible)</Box>
                        </Flex>
                    </>}
                </Flex>
                <AnimatePresence exitBeforeEnter>
                    {getTabContent()}
                </AnimatePresence>
            </Content>
        </AnimatePresence>
    </Flex>
}

const Content = styled(motion.div)`
    display: flex;
    height: 100%;
    width: 100%;
    color: white;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4),rgba(0, 0, 0, 0.4)),url(https://cdn.wallpapersafari.com/54/28/GiwTOz.jpg);
    background-size: cover;
    background-position: center; 
    flex-direction: column;
    ${styles.MediaQueries.Mobile} {
        overflow-y: auto;
    }
`

const TabHeader = styled.div`
    font-family: Lato;
    font-size: 72px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    border-bottom: 1px solid white;
    height: fit-content;
    width: 100%;
`

const BlurbSubtitle = styled.div`
    width: min-content;
    border-bottom: 1px solid white;
    font-size: 14px;
    // color: grey;
    margin-bottom: 2px;
`

const InnerTitle = styled.div`
    line-height: normal;
    font-size: 1.8em;
    border-radius: 4px;
    padding: 0 2px;
    backdrop-filter: blur(1px);
    background-color: rgba(0,0,0,0.1);
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    user-select: none;
    width: min-content;
    ${styles.MediaQueries.Mobile} {
        margin: 0 20px;
        width: 100%;
        text-align: center;
    }
    ${styles.MediaQueries.Desktop} {
        margin: 20px;
    }
`

const InnerBlurb = styled(motion.div)`
    width: fit-content;
    display: flex;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 6px 10px;
    background-color: rgba(0,0,0,0.5);
    border: 1px solid transparent;
    backdrop-filter: blur(8px);
    white-space: nowrap;
    user-select: none;
    transition: all 0.2s ease-in;
    fill: white;
    margin-top: 10px;
    ${props => props.onClick && `
        cursor: pointer;
        border: 1px solid white;
        &:hover {
            background-color: white;
            color: black !important;
            fill: black !important;
        }
    `}
`

const ColumnItem = styled.div`
    ${props => props.clickable && 'cursor: pointer;'}
    display: flex;
    padding: 4px 10px;
    flex: 1;
    overflow: hidden;
    :not(:last-child) { 
        border-bottom: 1px solid grey;
    }
`

const FeaturedItem = styled.div`
    cursor: pointer;
    display: flex;
    padding: 2px 14px;
    align-items: center;
    flex: 1;
    max-height: 80px;
    min-width: 140px;
    background-color: rgba(0,0,0,0.85);
    ${props => props.selected && `
        background-color: #2222a2 !important;
        color: white !important;
        opacity: 1 !important;
    `}
    &:hover {
        background-color: rgba(255,255,255,0.1);
    }
    :not(:last-child) { 
        border-bottom: 1px solid grey;
    }
    transition: all 0.2s ease-in;
    ${styles.MediaQueries.Mobile} {
        min-height: 40px;
    }
`

const RowItem = styled.div`
    display: flex;
    padding: 10px;
    flex: ${props => props.flex || 1};
    :not(:last-child) { 
        border-right: 1px solid grey;
    }
`

const BubbleContainer = styled.div`
    ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
    ${props => props.minHeight && `min-height: ${props.minHeight};`}
    display: flex;
    flex: ${props => props.flex || 1};
    padding: 10px;
    flex-direction: column;
    ${styles.MediaQueries.Mobile} {
        max-width: 100%;
    }
`

const InnerBubble = styled.div`
    border: 1px solid rgba(255,255,255,0.8);
    border-radius: 4px;
    flex: 1;
    font-family: 'Lato';
    display: flex;
    overflow: hidden;
    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(4px);
    box-shadow: 10px 5px 5px rgba(0,0,0,0.3);
`

const Title = styled.div`
    margin-left: 15px;
    padding: 2px 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: 'Lato';
    width: fit-content;
    background-color: #2222a2;
`

const Bubble = (props) => {
    return <BubbleContainer flex={props.flex}>
        <Title>{props.title}</Title>
        <InnerBubble>
            {props.children}
        </InnerBubble>
    </BubbleContainer>
}

export default D2Ghost