export enum Element {
    Void='Void',
    Solar='Solar',
    Arc='Arc',
    Stasis='Stasis',
    Strand='Strand',
}

export const Grenades = {
    [Element.Arc]: {},
    [Element.Solar]: {},
    [Element.Void]: {
        Vortex: {
            name: 'Vortex Grenade',
            image: 'https://www.bungie.net/common/destiny2_content/icons/12997544665705bbbd94742268ce7091.jpg',
        },
    },
    [Element.Stasis]: {},
    [Element.Strand]: {},
}

export const Fragments = {
    [Element.Arc]: {},
    [Element.Solar]: {},
    [Element.Void]: {
        Remnants: {
            name: 'Echo of Remnants',
            image: 'https://www.bungie.net/common/destiny2_content/icons/ce12bd0f246e834c8f7e102079814bf9.jpg',
        },
        Harvest: {
            name: 'Echo of Harvest',
            image: 'https://www.bungie.net/common/destiny2_content/icons/6bd23524f7129761043724acbe90c7b5.jpg',
        },
        Undermining: {
            name: 'Echo of Undermining',
            image: 'https://www.bungie.net/common/destiny2_content/icons/b114e9d97c42a68b19ab7876a221b354.jpg',
        },
        Instability: {
            name: 'Echo of Instability',
            image: 'https://www.bungie.net/common/destiny2_content/icons/0ad46f9c0c14535c4d5776daf48e871e.jpg',
        },
    },
    [Element.Stasis]: {},
    [Element.Strand]: {},
}
export const Warlock = {
    [Element.Arc]: {
        Super: {
            ChaosReach: {
                name: 'Chaos Reach',
                image: '',
            },
        }
    },
    [Element.Void]: {
        Super: {
            NovaBomb: {
                name: 'Nova Bomb',
                image: 'https://www.bungie.net/common/destiny2_content/icons/b1efa0eaa710653d85e2fcf5321047fb.png',
            },
        },
        Grenade: {
            Vortex: {
                name: 'Vortex Grenade',
                image: 'https://www.bungie.net/common/destiny2_content/icons/12997544665705bbbd94742268ce7091.jpg',
            },
        },
        Melee: {
            PocketSingularity: {
                name: 'Pocket Singularity',
                image: 'https://www.bungie.net/common/destiny2_content/icons/afd8d014a0cf2e76a73172beae7ef0ee.jpg',
            },
        },
        ClassAbility: {
            HealingRift: {
                name: 'Healing Rift',
                image: 'https://www.bungie.net/common/destiny2_content/icons/12e2bcbfb817e4f67ec6eeda111c0790.jpg',
            },
            EmpoweringRift: {
                name: 'Empowering Rift',
                image: 'https://www.bungie.net/common/destiny2_content/icons/7ed7831666e47cd300a5b1529584c18c.jpg',
            },
        },
        Aspects: {
            ChaosAccelerant: {
                name: 'Chaos Accelerant',
                image: 'https://www.bungie.net/common/destiny2_content/icons/5a8a03d4876d34a89db6aedf6189ab86.jpg',
            },
            FeedTheVoid: {
                name: 'Feed the Void',
                image: 'https://www.bungie.net/common/destiny2_content/icons/178bb0e78e55c5b960aa6f42660b9b66.jpg',
            },
            ChildOfTheOldGods: {
                name: 'Child of the Old Gods',
                image: 'https://www.bungie.net/common/destiny2_content/icons/ced6965ddcda23fdc728639aaf0ebd4d.jpg',
            },
        }
    },
    [Element.Solar]: {},
    [Element.Stasis]: {},
    [Element.Strand]: {},
}

export const Titan = {
    [Element.Arc]: {},
    [Element.Void]: {},
    [Element.Solar]: {},
    [Element.Stasis]: {},
    [Element.Strand]: {},
}
export const Hunter = {
    [Element.Arc]: {},
    [Element.Void]: {},
    [Element.Solar]: {},
    [Element.Stasis]: {},
    [Element.Strand]: {},
}