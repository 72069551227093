import { Add, Search } from "@mui/icons-material"
import React from "react"
import styled from "styled-components"
import styles from "../../../../../styles"
import { ReactComponent as HelmetIcon } from '../../../../../assets/helmet.svg'
import { ReactComponent as SkyburnerIcon } from '../../../../../assets/skyburner.svg'
import Card from "../Card"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

const Gear = () => {
    const navigate = useNavigate()

    return <Container {...styles.MotionProps.fadeInOutDown}>
        <Card
            title="Exotic Armor"
            subtitle="Powerful armor sported by each class."
            icon={<HelmetIcon style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            onClick={() => { navigate('/d2beta/gear/exoticArmor') }}
        />
        <Card
            title="Exotic Weapons"
            subtitle="Weapons of light, darkness, and everything inbetween."
            icon={<SkyburnerIcon style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            onClick={() => { navigate('/d2beta/gear/exoticWeapons') }}
        />
    </Container>
}

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5), rgba(0,0,0,0.7)), url("https://images.contentstack.io/v3/assets/blte410e3b15535c144/blta307a7a34b4252a9/645bcef77578d5d76ce67f81/Exotic-tuning-article-long.jpg");
    background-position: center;
    background-size: cover;
`

export default Gear