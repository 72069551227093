import { ChampionTypes } from "./constants"

export const lostSectors = {
    // EDZ
    TerminusEast: {
        name: 'Terminus East',
        image: '',
        destination: 'EDZ',
    },
    Atrium: {
        name: 'Atrium',
        image: '',
        destination: 'EDZ',
    },
    WidowsWalk: {
        name: "Widow's Walk",
        image: '',
        destination: 'EDZ',
    },
    ScavengersDen: {
        name: "Scavenger's Den",
        image: '',
        destination: 'EDZ',
    },
    TheDrain: {
        name: 'The Drain',
        image: '',
        destination: 'EDZ',
    },
    WhisperedFalls: {
        name: 'Whispered Falls',
        image: '',
        destination: 'EDZ',
    },
    TheWeep: {
        name: 'The Weep',
        image: '',
        destination: 'EDZ',
    },
    PathfindersCrash: {
        name: "Pathfinder's Crash",
        image: '',
        destination: 'EDZ',
    },
    ExcavationSiteXII: {
        name: 'Excavation Site XII',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/edz_lost_sector_excavation_site_xii.jpg',
        destination: 'EDZ',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    ThePit: {
        name: 'The Pit',
        image: '',
        destination: 'EDZ',
    },
    SkydockIV: {
        name: 'Skydock IV',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/skydock_iv.jpg',
        destination: 'EDZ',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    TheQuarry: {
        name: 'The Quarry',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/edz_lost_sector_the_quarry.jpg',
        destination: 'EDZ',
    },
    FloodedChasm: {
        name: 'Flooded Chasm',
        image: '',
        destination: 'EDZ',
    },
    HallowedGrave: {
        name: 'Hallowed Grave',
        image: '',
        destination: 'EDZ',
    },
    CavernOfSouls: {
        name: 'Cavern of Souls',
        image: '',
        destination: 'EDZ',
    },
    Shaft13: {
        name: 'Shaft 13',
        image: '',
        destination: 'EDZ',
    },
    // Nessus
    Orrery: {
        name: 'Orrery',
        image: '',
        destination: 'Nessus',
    },
    Rift: {
        name: 'Rift',
        image: '',
        destination: 'Nessus',
    },
    CarrionPit: {
        name: 'Carrion Pit',
        image: '',
        destination: 'Nessus',
    },
    TheConflux: {
        name: 'The Conflux',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/nessus_the_conflux.jpg',
        destination: 'Nessus',
    },
    AncientsHaunt: {
        name: '',
        image: '',
        destination: 'Nessus',
    },
    // Dreaming City
    BayOfDrownedWishes: {
        name: 'Bay of Drowned Wishes',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/dreaming_city_bay_of_drowned_wishes.jpg',
        destination: 'Dreaming City',
    },
    ChamberOfStarlight: {
        name: 'Chamber of Starlight',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/dreaming_city_chamber_of_starlight.jpg',
        destination: 'Dreaming City',
    },
    AphelionsRest: {
        name: "Aphelion's Rest",
        image: 'https://www.bungie.net/img/destiny_content/pgcr/dreaming_city_aphelions_rest.jpg',
        destination: 'Dreaming City',
    },
    // Moon
    K1Logistics: {
        name: 'K1 Logistics',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/moon_k1_logistics.jpg',
        destination: 'Moon',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    K1CrewQuarters: {
        name: 'K1 Crew Quarters',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/moon_k1_crew_quarters.jpg',
        destination: 'Moon',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    K1Communion: {
        name: 'K1 Communion',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/moon_k1_communion.jpg',
        destination: 'Moon',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    K1Revalation: {
        name: 'K1 Revalation',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/moon_k1_revelation.jpg',
        destination: 'Moon',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    // Europa
    Perdition: {
        name: 'Perdition',
        image: '',
        destination: 'Europa',
    },
    BunkerE15: {
        name: 'Bunker E15',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/europa-lost-sector-overhang.jpg',
        destination: 'Europa',
        champions: [ChampionTypes.Overload, ChampionTypes.Barrier]
    },
    ConcealedVoid: {
        name: 'Concealed Void',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/europa-lost-sector-frost.jpg',
        destination: 'Europa',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    // Cosmodrome
    ExodusGarden2A: {
        name: 'Exodus Garden 2A',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/cosmodrome-lost-sector-graveyard.jpg',
        destination: 'Cosmodrome',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    VelesLabyrinth: {
        name: 'Veles Labyrinth',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/cosmodrome-lost-sector-dry-sea.jpg',
        destination: 'Cosmodrome',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    // Throne World
    Extraction: {
        name: 'Extraction',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/gateway_ls.jpg',
        destination: 'Throne World',
        champions: [ChampionTypes.Overload, ChampionTypes.Unstoppable]
    },
    Metamorphosis: {
        name: 'Metamorphosis',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/bayou_ls.jpg',
        destination: 'Throne World',
        champions: [ChampionTypes.Overload, ChampionTypes.Unstoppable],
    },
    Sepulcher: {
        name: 'Sepulcher',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/canal_ls.jpg',
        destination: 'Throne World',
        champions: [ChampionTypes.Unstoppable, ChampionTypes.Barrier]
    },
    // Neomuna
    GildedPrecept: {
        name: 'Gilded Precept',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/neomuna_lost_sector_gilded_precept.jpg',
        destination: 'Neomuna',
    },
    Thrilladrome: {
        name: 'Thrilladrome',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/neomuna_lost_sector_thrilladrome.jpg',
        destination: 'Neomuna',
    },
    HydroponicsDelta: {
        name: 'Hydroponics Delta',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/neomuna_lost_sector_hydroponics_delta.jpg',
        destination: 'Neomuna',
    },
}