import React from 'react';
import { motion } from 'framer-motion'

class SlideIn extends React.Component {
  render() {
    const { children } = this;

    return <motion.div
      initial={{
          opacity: 0,
          x: '80%'
      }}
      animate={{
          opacity: 1,
          x: '0%',
      }}
      transition={{
          duration: 1,
      }}
  >
    {children}
  </motion.div>
  }
}

export default SlideIn;
