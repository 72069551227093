import { PlanetaryTab } from "./constants"

export const LightfallStoryMissions = [
    {
        name: 'First Contact',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_mission_chaos_orbit.jpg'
    },
    {
        name: 'Under Siege',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_mission_haste.jpg'
    },
    {
        name: 'Downfall',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_mission_lancer.jpg'
    },
    {
        name: 'Breakneck',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_mission_suplex.jpg'
    },
    {
        name: 'On The Verge',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_mission_workout.jpg'
    },
    {
        name: 'No Time Left',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_mission_beastmaster.jpg'
    },
    {
        name: 'Headlong',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_mission_unleashed.jpg'
    },
    {
        name: 'Desperate Measures',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_mission_suplex.jpg'
    },
]

export const WitchQueenStoryMissions = [
    {
        name: 'The Arrival',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_genesis.jpg'
    },
    {
        name: 'The Investigation',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_lost.jpg'
    },
    {
        name: 'The Ghosts',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_tangle.jpg'
    },
    {
        name: 'The Communion',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_igloo.jpg'
    },
    {
        name: 'The Mirror',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_recall.jpg'
    },
    {
        name: 'The Cunning',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_brimstone.jpg'
    },
    {
        name: 'The Last Chance',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_escalade.jpg'
    },
    {
        name: 'The Ritual',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_dethrone.jpg'
    },
]

export const ShadowkeepStoryMissions = [
    {
        name: 'A Mysterious Disturbance',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/story_a_mysterious_disturbance.jpg',
    },
    {
        name: 'In Search of Answers',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/story_in_search_of_answers.jpg',
    },
    {
        name: 'Ghosts of Our Past',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/story_ghosts_of_our_past.jpg',
    },
    {
        name: 'The Scarlet Keep',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/story_the_scarlet_keep.jpg',
    },
    {
        name: 'In The Deep',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/story_in_the_deep.jpg',
    },
    {
        name: 'The Nightmare Cometh',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/story_the_nightmare_cometh.jpg',
    },
    {
        name: 'Beyond',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/story_beyond.jpg',
    },
]

export const Partition = {
    HardReset: {
        name: 'Hard Reset',
    },
    Ordnance: {
        name: 'Ordnance',
    },
    Backdoor: {
        name: 'Backdoor',
    },
}

export const AscendantChallenge = {
    AgonarchAbyss: {
        name: 'AgonarchAbyss',
        curse: 'Weak',
    },
    CimmerianGarrison: {
        name: 'Cimmerian Garrison',
        curse: 'Medium',
    },
    Ouroborea: {
        name: 'Ouroborea',
        curse: 'Strong',
    },
    ForfeitShrine: {
        name: 'Forfeit Shrine',
        curse: 'Weak',
    },
    ShatteredRuins: {
        name: 'Shattered Ruins',
        curse: 'Medium',
    },
    KeepofHonedEdges: {
        name: 'Keep of Honed Edges',
        curse: 'Strong',
    },
}

export const ExoSimulation = {
    Survival: {
        name: 'Survival',
        image: '',
    },
    Agility: {
        name: 'Agility',
        image: '',
    },
    Safeguard: {
        name: 'Safeguard',
        image: '',
    },
}

export const EmpireHunts = {
    TheTechnocrat: {
        name: 'The Technocrat',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/empire-hunt-chili.jpg',
    },
    TheWarrior: {
        name: 'The Warrior',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/empire-hunt-chowder.jpg',
    },
    TheDarkPriestess: {
        name: 'The Dark Priestess',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/empire-hunt-gumbo.jpg',
    },
}

export const WeaponRotations = {
    AltarsOfSorrow: [
        {
            name: 'Blasphemer',
            image: 'https://www.bungie.net/common/destiny2_content/icons/ecf8dd2ef1247260ebc509143c90482f.jpg',
            lightGG: 'https://www.light.gg/db/items/2782847179/blasphemer/',
        },
        {
            name: 'Apostate',
            image: 'https://www.bungie.net/common/destiny2_content/icons/b3b3538f2445cb51b46b829595f5dcdb.jpg',
            lightGG: 'https://www.light.gg/db/items/2164448701/apostate/',
        },
        {
            name: 'Heretic',
            image: 'https://www.bungie.net/common/destiny2_content/icons/11c64dd593413b7489406f06f90099ad.jpg',
            lightGG: 'https://www.light.gg/db/items/3067821200/heretic/'
        },
    ],
    Wellspring: [
        {
            name: "Father's Sins",
            type: 'Defend',
            image: 'https://www.bungie.net/common/destiny2_content/icons/88f8dc84635c93e4cd2b0eded9575648.jpg',
            lightGG: 'https://www.light.gg/db/items/3865728990/fathers-sins/',
        },
        {
            name: 'Come to Pass',
            type: 'Attack',
            image: 'https://www.bungie.net/common/destiny2_content/icons/42c740833f7ac00b78355473a1161ed7.jpg',
            lightGG: 'https://www.light.gg/db/items/927567426/come-to-pass/',
        },
        {
            name: 'Tarnation',
            type: 'Defend',
            image: 'https://www.bungie.net/common/destiny2_content/icons/7fe6e137bf2355666de676608c813eca.jpg',
            lightGG: 'https://www.light.gg/db/items/2721157927/tarnation/',
        },
        {
            name: 'Fel Teradiddle',
            type: 'Attack',
            image: 'https://www.bungie.net/common/destiny2_content/icons/b310e3fcc77442904c6ef28124be4d01.jpg',
            lightGG: 'https://www.light.gg/db/items/1399109800/fel-taradiddle/',
        },
    ],
    TerminalOverload: [
        {
            name: 'Circular Logic',
            zone: 'Zephyr Concourse',
            image: 'https://www.bungie.net/common/destiny2_content/icons/ff0b4c92f672aac0ca1e8e2911080de2.jpg',
            lightGG: 'https://www.light.gg/db/items/2187717691/circular-logic/',
        },
        {
            name: 'Basso Ostinato',
            zone: 'Ahisma Park',
            image: 'https://www.bungie.net/common/destiny2_content/icons/f37e9fc32d2f6d8bc0ffb67736a487ba.jpg',
            lightGG: 'https://www.light.gg/db/items/2573900604/basso-ostinato/',
        },
        {
            name: 'Synchronic Roulette',
            zone: 'Liming Harbor',
            image: 'https://www.bungie.net/common/destiny2_content/icons/b8c9c1694f6ad3bab2a9a3d84cb115cc.jpg',
            lightGG: 'https://www.light.gg/db/items/811403305/synchronic-roulette/',
        },
    ]
}

export const planets = {
    [PlanetaryTab.Neomuna]: {
        name: 'Neomuna',
        expansion: 'Lightfall',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_mission_suplex.jpg'
    },
    [PlanetaryTab.ThroneWorld]: {
        name: 'Throne World',
        expansion: 'Witch Queen',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_keeper.jpg'
    },
    [PlanetaryTab.Europa]: {
        name: 'Europa',
        expansion: 'Beyond Light',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/empire-hunt-goulash.jpg'
    },
    [PlanetaryTab.Moon]: {
        name: 'Moon',
        expansion: 'Shadowkeep',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/story_in_the_deep.jpg'
    },
    [PlanetaryTab.DreamingCity]: {
        name: 'Dreaming City',
        expansion: 'Forsaken',
        image: 'https://i.redd.it/w8wi5ft0bt131.jpg'
    },
}