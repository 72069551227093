import React, { Component } from "react";
import * as THREE from "three";
// import { OrbitControls } from "./OrbitControls";

// const functional = () => {
//     const { unmount } = this;

//     var scene = new THREE.Scene();
//     var camera = new THREE.PerspectiveCamera( 25, window.innerWidth/window.innerHeight, 0.1, 1000 );
//     var renderer = new THREE.WebGLRenderer({ alpha: true,  antialias: true });
//     renderer.setSize(window.innerWidth, window.innerHeight)
//     // renderer.setClearColor( 0x000000, 0 ); // the default

//     window.addEventListener('resize', this.resize, false)

//     renderer.setClearColor( 0x0C0C0C, 1 );

//     // const controls = new OrbitControls( camera, renderer.domElement );

//     // const canvas = document.querySelector('canvas')
//     // canvas.style.width = '100%';
//     // canvas.style.height = '100%';

//     this.mount.appendChild( renderer.domElement );

//     var ringMaterial = new THREE.PointsMaterial({ color: 0x00eeff, size: 0.2  });
//     const ringParticleCount = 100;
//     const ringGeometry = new THREE.BufferGeometry();
//     this.ringPositions = new Float32Array(ringParticleCount * ringParticleCount * 3);
//     for (let i = 0; i < ringParticleCount; i++) {
//         for (let j = 0; j < ringParticleCount; j++) {
//             this.ringPositions[((i * ringParticleCount) + j) * 3] = (Math.random() * 40) - 20//i - ((ringParticleCount - 1) / 2)
//             this.ringPositions[(((i * ringParticleCount) + j) * 3)+1] = (Math.random() * 40) - 20//j - ((ringParticleCount - 1) / 2)
//             this.ringPositions[(((i * ringParticleCount) + j) * 3)+2] = (Math.random() * 40) - 20//0
//         }
//     //   this.ringArray.push({ position: vector, number: i })
//     }
//     ringGeometry.setAttribute('position', new THREE.BufferAttribute(this.ringPositions, 3));
//     const  ringMesh = new THREE.Points(ringGeometry, ringMaterial)
//     ringMesh.castShadow = true;
//     ringMesh.rotateX(Math.PI / 2)
//     scene.add(ringMesh)

//     // camera.position.z = 25;
//     camera.position.y = 3;
//     // camera.rotateX(-Math.PI / 24)
//     camera.rotateZ(-Math.PI)
//     // create a point light
//     const pointLight =
//     new THREE.PointLight(0xFFFFFF);

//     // set its position
//     pointLight.position.x = 10;
//     pointLight.position.y = 50;
//     pointLight.position.z = 130;

//     // add to the scene
//     scene.add(pointLight);

//     var animate = function () {
//     if (unmount) {
//       return;
//     }

//     setTimeout( function() {
//       requestAnimationFrame( animate );
//     }, 1000 / 70 );
    
//       // cubes.forEach(cube => {
//       //   cube.rotation.x += 0.01;
//       //   cube.rotation.y += 0.01;
//       //   cube.position.x += (Math.floor(Math.random() * 2) - 0.5) / 20;
//       //   cube.position.y += (Math.floor(Math.random() * 2) - 0.5) / 20;
//       //   cube.position.z += (Math.floor(Math.random() * 2) - 0.5) / 20;
//       // })

//       ringMesh.rotateX(0.001)

//       renderer.render( scene, camera );
//     };

//     animate(this.unmount);
//   }

//     return <div style={{ position: 'absolute', zIndex: -1 }} ref={ref => (this.mount = ref)} />
// }

class Game extends Component {
  unmount = false;

  componentWillUnmount() {
    this.unmount = true;
  }

  componentDidMount() {
    const { unmount } = this;

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 25, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer({ alpha: true,  antialias: true });
    this.renderer = renderer;
    renderer.setSize(window.innerWidth, window.innerHeight)
    // renderer.setClearColor( 0x000000, 0 ); // the default

    renderer.setClearColor( 0x010452, 1 );

    // const controls = new OrbitControls( camera, renderer.domElement );

    // const canvas = document.querySelector('canvas')
    // canvas.style.width = '100%';
    // canvas.style.height = '100%';

    this.mount.appendChild( renderer.domElement );

    // const floorMaterial = new THREE.MeshStandardMaterial({color: 'grey'});
    // const floorGeometry = new THREE.PlaneGeometry(1000, 1000)
    // const floor = new THREE.Mesh(floorGeometry, floorMaterial)
    // floor.rotation.x = -Math.PI / 2;
    // scene.add(floor)

    const stageMaterial = new THREE.MeshStandardMaterial({color: '#E122D7'});
    const stageGeometry = new THREE.CylinderGeometry( 50, 50, 2, 8 );     // const stageGeometry = new THREE.PlaneGeometry(50, 50)
    const stage = new THREE.Mesh(stageGeometry, stageMaterial)
    // stage.rotation.x = -Math.PI / 2;
    stage.rotation.y = -Math.PI / 8;
    scene.add(stage)

    const material = new THREE.MeshStandardMaterial( {color: 'blue'} );
    const geometry = new THREE.SphereGeometry(5)
    const sphere = new THREE.Mesh( geometry, material )
    sphere.position.y = 8
    sphere.castShadow = true; //default is false
    sphere.receiveShadow = false; //default
    scene.add(sphere)

    const controls = {
      up: false,
      down: false,
      left: false,
      right: false,
      jump: false
    }    

    // move towards the user, and up
    camera.position.z = 140;
    camera.position.y = 45;
    camera.rotation.x = -Math.PI / 12;

    // create a point light
    const pointLight = new THREE.PointLight(0xFFFFFF, 1, 1000);
    pointLight.castShadow = true; // default false

    // set its position
    pointLight.position.x = 20;
    pointLight.position.y = 100;
    pointLight.position.z = 20;

    // add to the scene
    scene.add(pointLight);

    var animate = function () {
    if (unmount) {
      return;
    }

    const adjustPosition = (direction) => {
      switch (direction) {
        case 'jump':
          if (sphere.position.y === 6) {
            sphere.position.y += 6;
          }
          break;
        case 'left':
          sphere.position.x -= 0.5;
          break;
        // case 'down':
        //   sphere.position.z -= 0.5;
        //   break;
        case 'right':
          sphere.position.x += 0.5;
          break;
        // case 'up':
        //   sphere.position.z += 0.5;
        //   break;
        default:
          return;
      }
    }

    setTimeout( function() {
      requestAnimationFrame( animate );
    }, 1000 / 70 );

      Object.keys(controls).forEach(key => controls[key] && adjustPosition(key) )
      if (sphere.position.y > 6) {
        sphere.position.y = Math.max(sphere.position.y - 0.15, 6)
      }

      renderer.render( scene, camera );
    };

    const resize = () => {
      // Update camera aspect ratio
     camera.aspect = window.innerWidth / window.innerHeight;
     camera.updateProjectionMatrix();

     // Update renderer size
     renderer.setSize(window.innerWidth, window.innerHeight);
     // if (this.renderer){
       

     //   const canvas = this.renderer.domElement.current;
     //   // look up the size the canvas is being displayed
     //   const width = canvas.clientWidth;
     //   const height = canvas.clientHeight;
     
     //   // adjust displayBuffer size to match
     //   if (canvas.width !== width || canvas.height !== height) {
     //     // you must pass false here or three.js sadly fights the browser
     //     this.renderer.setSize(width, height, false);
     //     this.camera.aspect = width / height;
     //     this.camera.updateProjectionMatrix();
     
     //     // update any render target sizes here
     //   }
     // }
   }

   window.addEventListener('resize', resize, false)
   window.addEventListener('orientationchange', resize, false)
   addEventListener("keydown", (event) => {
    console.log(event.keyCode)
    switch (event.keyCode) {
      case 32:
        controls.jump = true;
        break;
      case 37:
        controls.left = true;
        break;
      case 38:
        controls.down = true;;
        break;
      case 39:
        controls.right = true;
        break;
      case 40:
        controls.up = true;
        break;
      default:
        return;
    }
   });
   addEventListener("keyup", (event) => {
    switch (event.keyCode) {
      case 32:
        controls.jump = false;
        break;
      case 37:
        controls.left = false;
        break;
      case 38:
        controls.down = false;;
        break;
      case 39:
        controls.right = false;
        break;
      case 40:
        controls.up = false;
        break;
      default:
        return;
    }
   });

    animate(this.unmount);
  }

  render() {
    return (
      <div style={{ position: 'absolute', zIndex: -1, }} ref={ref => (this.mount = ref)} />
    )
  }
}

export default Game;