import { Add, Search } from "@mui/icons-material"
import React from "react"
import styled from "styled-components"
import styles from "../../../../../styles"
import Card from '../Card'
import { motion } from "framer-motion"
import { ReactComponent as RaidIcon } from '../../../../../assets/raid.svg'
import { ReactComponent as DungeonIcon } from '../../../../../assets/dungeon.svg'
import { ReactComponent as StrikeIcon } from '../../../../../assets/strike.svg'
import { ReactComponent as EngramIcon } from '../../../../../assets/empty-engram.svg'
import { ReactComponent as LostSectorIcon } from '../../../../../assets/lostSector.svg'
import { useNavigate } from "react-router-dom"

const Activities = () => {
    const navigate = useNavigate()
    
    return <Container {...styles.MotionProps.fadeInOutDown}>
        <Card
            icon={<RaidIcon style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            title='Raids'
            subtitle="Endgame content created for teams of 6."
            onClick={() => { navigate('/d2beta/activities/raids') }}
        />
        <Card
            icon={<DungeonIcon style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            title='Dungeons'
            subtitle="Endgame content created for teams of 3."
            onClick={() => { navigate('/d2beta/activities/dungeons') }}
        />
        <Card
            icon={<StrikeIcon style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            title='Strikes'
            subtitle="3 man missions available at multiple difficulties."
            onClick={() => { navigate('/d2beta/activities/strikes') }}
        />
        <Card
            icon={<EngramIcon style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            title='Exotic Missions'
            subtitle="3 man missions with a unqiue reward."
            onClick={() => { navigate('/d2beta/activities/exoticMissions') }}
        />
        <Card
            icon={<LostSectorIcon style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            title='Lost Sectors'
            subtitle="Hidden areas with exotic armor."
            onClick={() => { navigate('/d2beta/activities/lostSectors') }}
        />
        <Card
            icon={<Add style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            title='Other'
            subtitle="Planetary activities, campaigns, etc."
            onClick={() => { navigate('/d2beta/activities/other') }}
        />
    </Container>
}

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5), rgba(0,0,0,0.7)), url("https://images.contentstack.io/v3/assets/blte410e3b15535c144/bltc315f1ee2b91c1a3/65f312f9039fdd4524336f7e/FrontPageDesktop_1920x590.jpg");
    background-position: center;
    background-size: cover;
`

export default Activities