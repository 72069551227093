import React from 'react';

class AnaglyphText extends React.Component {
    render() {
        const { children, style } = this.props;

        return (
            <div style={{display: 'inline-block',position: 'relative', margin: "0", padding: '2px', ...style }}>
                <h1 style={{userSelect: 'none', margin: 0, opacity: 0}}>{children}</h1>
                <h1 style={{position: 'absolute', color: '#9a92ad', top: 0, zIndex: 2, userSelect: 'none', margin: 0}}>{children}</h1>
                <h1 style={{position: 'absolute', color: 'blue', top:-3, left: -3, userSelect: 'none', opacity: 0.8, margin: 0}}>{children}</h1>
                <h1 style={{position: 'absolute', color: 'red', top: 3, left: 3, userSelect: 'none', opacity: 0.8, margin: 0}}>{children}</h1>
            </div>
        );
    }
}

export default AnaglyphText;
