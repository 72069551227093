import { Add, Search } from "@mui/icons-material"
import React from "react"
import styled from "styled-components"
import styles from "../../../../../styles"
import { motion } from "framer-motion"
import Card from "../Card"
import { useNavigate } from "react-router-dom"


const Builds = () => {
    const navigate = useNavigate()

    return <Container {...styles.MotionProps.fadeInOutDown}>
        <Card 
            title='Create'
            subtitle='Create a new build, and share it.'
            icon={<Add style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            onClick={() => { console.log('yo'); navigate('/d2beta/builds/create') }}
        />
        <Card 
            title='Search'
            subtitle='See builds made by others.'
            icon={<Search style={{width: 'auto', height: '100px', fill: 'white'}}/>}
            onClick={() => { navigate('/d2beta/builds/search') }}
        />
    </Container>
}

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5), rgba(0,0,0,0.7)), url("https://images.contentstack.io/v3/assets/blte410e3b15535c144/blt5b61db0110339444/66156caa7cacdcc904d4a855/FrontPageBanner_PC_1920x590.jpg")
`

export default Builds