import React, { useState, useEffect, createRef } from 'react';
import { AnimatePresence, motion } from 'framer-motion'
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button } from "@chakra-ui/react";
import { ExpandLess, ExpandMore, Home as HomeIcon, Link } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom'

import Fade from './Fade'

const fadeVariants = {
    exit: { opacity: 0 },
    initial: { opacity: 0 },
    animate: { opacity: 1 },
}

function Gallery() {
    const [isHovered, setHovered] = useState(false)
    const [mousePos, setMousePos] = useState({});
    const [scrollPos, setScrollPos ] = useState(0)

    const scrollableRef = createRef()

    const projects = [
        {
            title: 'HyperMap',
            description: 'An interactive map application that displays various routes from multiple Hyperloop companies.',
            backgroundColor: 'rgba(0,200,255, 0.5)',
            link: 'https://hypermap.co',
            logo: 'hyperloopFav.png',
            preview: 'hypermapPreview.png'
        },
        {
            title: 'D2 Ghost',
            description: 'A reference site for helpful information I\'ve compiled about Destiny 2. Displays information about weekly and daily rotating events, as well as builds, guides, and other miscellaneous info for players.',
            backgroundColor: 'rgba(40,40,40, 0.3)',
            route: 'd2ghost',
            logo: 'd2ghost.png',
            preview: 'd2ghostPreview.png'
        },
        {
            title: 'MusicNerd',
            description: 'A Spotify clone that pulls additional information about the currently playing song. Lyrics come from Genius, alternate/early versions of the song from YouTube, and other nerdy stuff.',
            backgroundColor: 'rgba(193,193,193, 0.3)',
            route: 'musicnerd',
            logo: 'musicnerd.png',
            preview: 'musicnerdPreview.png'
        },
    ]

    const scrollDown = () => {
        setScrollPos(scrollPos + 1)
    }

    const scrollUp = () => {
        setScrollPos(scrollPos - 1)
    }

    const scroll = () => {
        scrollableRef.current.scrollTo({
            top: (scrollPos) * window.innerHeight,
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        scroll()
    }, [scrollPos])

    useEffect(() => {
        const handleMouseMove = (event) => {
          setMousePos({ x: event.clientX, y: event.clientY });
        };
    
        window.addEventListener('mousemove', handleMouseMove);
    
        // Update the window height on resize
        const handleResize = () => {
            console.log(scrollPos)
            scroll()
            // setWindowHeight(window.innerHeight);
        };
    
        // Add the event listener for window resize
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('mousemove',handleMouseMove);
          // Clean up the event listener on component unmount
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return <Flex
        className='container'
        ref={scrollableRef}
        width='100%'
        minHeight={window.innerHeight}
        height='100vh'
        maxHeight='-webkit-fill-available'
        overflow='hidden'
        backgroundColor='#0c0c0c'
        flexDir='column'
        scrolltop={scrollPos}
    >
        <Flex
            as={RouterLink}
            to='/'
            className='HomeIcon'
            width='60px'
            height='60px'
            color='white'
            justifyContent='center'
            alignItems='center'
            position='absolute'
            top='20px'
            left='20px'
            borderRadius='50%'
            cursor='pointer'
            zIndex='2'
            border='1px solid transparent'
            backgroundColor='rgba(255,255,255,0.2)'
            transition='all 0.2s ease-in'
            _hover={{ border: '1px solid white' }}
        >
            <HomeIcon />
        </Flex>
        <AnimatePresence>
        {scrollPos > 0 && <Fade key='1'><Flex
            className='upButtton'
            width='60px'
            height='60px'
            color='white'
            justifyContent='center'
            alignItems='center'
            position='absolute'
            top='20px'
            left={`${(window.innerWidth / 2) - 30}px`}
            borderRadius='50%'
            cursor='pointer'
            backgroundColor='rgba(255,255,255,0.2)'
            border='1px solid transparent'
            transition='all 0.2s ease-in'
            _hover={{ border: '1px solid white' }}
            zIndex='2'
            onClick={scrollUp}
        >
            <motion.div
                animate={{ y: 10 }}
                transition={{ repeat: Infinity, repeatType: 'reverse', duration: 2 }}
            >
                <ExpandLess style={{width: '40px', height: '40px'}}/>
            </motion.div>
        </Flex></Fade>}
        {scrollPos < projects.length - 1 && <Fade key='2'><Flex
            className='downButton'
            width='60px'
            height='60px'
            color='white'
            justifyContent='center'
            alignItems='center'
            position='absolute'
            bottom='20px'
            left={`${(window.innerWidth / 2) - 30}px`}
            borderRadius='50%'
            cursor='pointer'
            backgroundColor='rgba(255,255,255,0.2)'
            zIndex='2'
            border='1px solid transparent'
            transition='all 0.2s ease-in'
            _hover={{ border: '1px solid white' }}
            onClick={scrollDown}
        >
            <motion.div
                animate={{ y: 10 }}
                transition={{ repeat: Infinity, repeatType: 'reverse', duration: 2 }}
            >
                <ExpandMore style={{width: '40px', height: '40px'}}/>
            </motion.div>
        </Flex></Fade>}
        </AnimatePresence>
        <Flex position='absolute' display='flex' height='100%' alignItems='center' width='100%' justifyContent='center'>
            <Flex 
                flexDir='column'
                border='1px solid rgba(255,255,255,0.7)'
                minWidth='700px'
                maxWidth='700px'
                height='500px'
                borderRadius='15px'
                backgroundImage='linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1))'
                boxShadow='2px 2px 5px rgba(0, 0, 0, 0.3)'
                transition='transform 0.5s ease-out' /* add a smooth transition */
                perspective='500px' /* set the perspective */
                overflow='hidden'
                color='white'
                padding='40px'
                margin='40px'
                // _hover={{
                //     _after: {
                //         content: '""',
                //         position: 'relative',
                //         top: `${mousePos.y - 425}px`,
                //         left: `${mousePos.x - 75}px`,
                //         minWidth: '300px',
                //         maxWidth: '300px',
                //         minHeight: '300px',
                //         maxHeight: '300px',
                //         borderRadius: '50%',
                //         backgroundImage: 'linear-gradient(to bottom right, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.05))',
                //         transform: 'translateX(-50%) translateY(-50%)',
                //         opacity: 1,
                //         transition: 'opacity 0.5s ease-out', /* add a smooth fade-in effect */
                //     }
                // }}
            >
                <AnimatePresence exitBeforeEnter>
                    <motion.div exit={{ opacity: 0 }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} key={projects[scrollPos].title}>
                        <Flex
                            alignItems='center'
                            marginBottom='20px'
                        >
                            {projects[scrollPos].logo ? <Image marginRight='40px' minWidth='80px' width='80px' minHeight='80px' height='80px' src={projects[scrollPos].logo} /> : <Flex
                                backgroundColor='lightskyblue'
                                minWidth='80px'
                                width='80px'
                                minHeight='80px'
                                height='80px'
                                borderRadius='50%'
                                marginRight='40px'
                            />}
                            <div style={{ fontSize: '40px', fontFamily: 'Lato'}}>
                                {projects[scrollPos].title}
                            </div>
                        </Flex>
                        <Flex minHeight='200px'>
                            {projects[scrollPos].description}
                        </Flex>
                        <Box>
                            {projects[scrollPos].link && <Flex height='40px' margin='20px 0' alignItems='center'>
                                <Link/>
                                <a style={{ marginLeft: '20px', textDecoration: 'underline'}} href={projects[scrollPos].link}>{projects[scrollPos].link}</a>
                            </Flex>}
                            {projects[scrollPos].route && <Flex height='40px' margin='20px 0' alignItems='center'>
                                <Link/>
                                <RouterLink style={{ marginLeft: '20px', textDecoration: 'underline'}} to={`/${projects[scrollPos].route}`}>{`https://hysteria.house/${projects[scrollPos].route}`}</RouterLink>
                            </Flex>}
                            <Flex height='40px' margin='20px 0' alignItems='center'>
                                {projects[scrollPos].github ? <>
                                    <Link/>
                                    <a style={{ marginLeft: '20px', textDecoration: 'underline'}} href={projects[scrollPos].link}>{projects[scrollPos].link}</a>
                                </> : <Box opacity={0.5}>The GitHub repo for this project is not currently available.</Box>}                                    
                            </Flex>
                        </Box>
                    </motion.div>
                </AnimatePresence>
            </Flex>
            <AnimatePresence exitBeforeEnter>
                {projects[scrollPos].preview ? <Fade key={projects[scrollPos].preview}><Image width='600px' height='360px' src={projects[scrollPos].preview} /></Fade> : <Flex
                    width='600px'
                    height='360px'
                    border='1px solid white'
                />}
            </AnimatePresence>
        </Flex>
        {projects.map(project => <Flex
            className='galleryItem'
            alignItems='center'
            backgroundColor={project.backgroundColor}
            minHeight={window.innerHeight}
            height='100vh'
            width='100%'
        />)}
    </Flex>
}

export default Gallery;
