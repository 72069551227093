import styled from "styled-components";
import styles from "../../../../styles";

type ButtonProps = {
    selected?: boolean,
}

const Button = styled.div<ButtonProps>`
    font-family: Lato;
    font-size: 12px;
    border-radius: 9999px;
    background-color: rgba(0,0,0,0.5);
    color: white;
    margin-right: 10px;
    padding: 8px 14px;
    cursor: pointer;
    ${styles.Animation.Transition}
    ${props => props.selected ? `
        background-color: white;
        color: black;
    ` : `
        &:hover {
            background-color: rgb(255,255,255);
            color: black;
        }
    `}
`

export default Button;