const twids = [
    {
        date: '20231109',
        url: 'https://www.bungie.net/7/en/News/article/this-week-in-destiny-11-09',
        image: 'https://images.contentstack.io/v3/assets/blte410e3b15535c144/blta2f08954f03a15d1/654c2e6ff84ac2040a5f63a9/FrontPageDesktop_1920x590.jpg',
    },
    {
        date:  '20231026',
        url: 'https://www.bungie.net/7/en/News/article/this-week-in-destiny-10-26',
        image: 'https://images.contentstack.io/v3/assets/blte410e3b15535c144/bltfd61e28164dac4fc/653a8c9c5e9b4a0407a4d10b/FrontPageDesktop_1920x590.jpg'
    }
]

export default twids