import React from 'react';
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button, Link } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'

class HomePage extends React.Component {
    render() {
        const { nextPage } = this.props;

        return (<>
            <motion.div
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                transition={{
                    duration: 1.6
                }}
            >
                <Heading  marginTop='6%' fontSize={['7vw', '3vw']} color="whiteAlpha.800">Portfolio</Heading>
            </motion.div>
            <Flex flex={1} direction='column' justify='space-around' w='100%'>
                <motion.div
                    initial={{
                        opacity: 0,
                        x: '80%'
                    }}
                    animate={{
                        opacity: 1,
                        x: '0%',
                    }}
                    transition={{
                        duration: 1,
                    }}
                >
                    <Flex w='100%' align='center'>
                        <Image
                            src="github.png"
                            alt="Workday"
                            height="auto"
                            borderRadius="15px"
                            width='20%'
                            maxWidth='200px'
                            margin='20px 0'
                            border='1px solid #333333'
                            marginLeft='4vw'
                            backgroundColor='whiteAlpha.900'
                        />
                        <Flex h='100%' flex={1} margin='0 20px' direction='column' align='center' textAlign='center'>
                            <Text fontSize={['3.4vw', '1.2vw']} color='whiteAlpha.800' fontWeight='bold' w='100%' textAlign='center'>My Github</Text>
                            <Link fontSize={['3.4vw', '1.2vw']} color='blue.300' href='https://github.com/jkimminau/fractol'>fractol: A Fractal Visualizer</Link> 
                            <Link fontSize={['3.4vw', '1.2vw']} color='blue.300' href='https://github.com/jkimminau/ReND'>ReND: A Relational Graph using Spotify Data</Link> 
                        </Flex>
                    </Flex>
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0,
                        x: '80%'
                    }}
                    animate={{
                        opacity: 1,
                        x: '0%',
                    }}
                    transition={{
                        duration: 1,
                    }}
                >
                    <Flex w='100%' align='center'>
                        <Image
                            src="linkedin.png"
                            alt="HyperMap"
                            height="auto"
                            borderRadius="15px"
                            width='20%'
                            maxWidth='200px'
                            margin='20px 0'
                            border='1px solid #333333'
                            marginLeft='4vw'
                        />
                        <Flex h='100%' flex={1} margin='0 20px' align='center' direction='column'>
                            <Text fontSize={['3.4vw', '1.2vw']} color='whiteAlpha.800' fontWeight='bold' w='100%' textAlign='center'>LinkedIn</Text>
                            <Link fontSize={['3.4vw', '1.2vw']} color='blue.300' href='https://www.linkedin.com/in/jkimminau/'>My Profile</Link> 
                            {/* <Text fontSize={['3.4vw', '1.2vw']} color='whiteAlpha.600' w='100%' textAlign='center'></Text> */}
                        </Flex>
                    </Flex>
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0,
                        x: '80%'
                    }}
                    animate={{
                        opacity: 1,
                        x: '0%',
                    }}
                    transition={{
                        duration: 1,
                    }}
                >
                    <Flex w='100%' align='center'>
                        <Image
                            src="hackerrank.png"
                            alt="Freelance"
                            height="auto"
                            borderRadius="15px"
                            width='20%'
                            maxWidth='200px'
                            margin='20px 0'
                            border='1px solid #333333'
                            marginLeft='4vw'
                        />
                        <Flex h='100%' flex={1} margin='0 20px' direction='column' align='center'>
                            <Text fontSize={['3.4vw', '1.2vw']} color='whiteAlpha.800' fontWeight='bold' w='100%' textAlign='center'>HackerRank</Text>
                            <Link fontSize={['3.4vw', '1.2vw']} color='blue.300' href='https://www.hackerrank.com/certificates/25f09806088f'>Python Certification</Link> 
                            <Link fontSize={['3.4vw', '1.2vw']} color='blue.300' href='https://www.hackerrank.com/certificates/49f731552658'>React Certification</Link> 
                            {/* <Text color='whiteAlpha.600' w='100%' textAlign='center'></Text> */}
                        </Flex>
                    </Flex>
                </motion.div>
            </Flex>
            {/* <Button 
                background='linear-gradient(-45deg, #871ac7, #e73c7e)' 
                color='whiteAlpha.900'
                onClick={nextPage}
                _hover={{ border: `1px solid #cccccc`  }}
                marginBottom='3%'
            >
                {"Home"}
            </Button> */}
        </>)
    }
}

export default HomePage;