import React from 'react';
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import AnaglyphText from '../AnaglyphText';

class HomePage extends React.Component {
    render() {
        const { nextPage } = this.props;

        return (<>
            <div style={{
                display: 'flex',
            }}>
                <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', justifyContent: 'center', padding: '0 40px'}}>
                    <Heading marginTop='3%'  size='xl' color="whiteAlpha.700" fontSize='40px'>Jamison Kimminau</Heading>
                    <Heading  size='m' color="whiteAlpha.700" fontSize='24px'>Software Developer</Heading>
                    <Flex w='100%' justifyContent='space-around' h='50px' margin='10px 0'>
                        <motion.div whileHover={{scale: 1.1}}>
                            <Button onClick={()=>{}} h='40px' w='40px' borderRadius='50%'>
                                <Image src="linkedin.png" alt="Profile Image" borderRadius='50%' maxWidth='40px' h='auto'/>
                            </Button>
                        </motion.div>
                        <motion.div whileHover={{scale: 1.1}}>
                            <Button onClick={()=>{}} h='40px' w='40px' borderRadius='50%'>
                                <Image src="github.png" alt="Profile Image" borderRadius='50%' maxWidth='40px'/>
                            </Button>
                        </motion.div>
                        <motion.div whileHover={{scale: 1.1}}>
                            <Button onClick={()=>{}} h='40px' w='40px' borderRadius='50%'>
                                <Image src="hackerrank.png" backgroundColor='#333333' alt="Profile Image" borderRadius='50%' maxWidth='40px'/>
                            </Button>
                        </motion.div>
                        <motion.div whileHover={{scale: 1.1}}>
                            <Button onClick={()=>{}} h='40px' w='40px' borderRadius='50%'>
                                <Image src="insta.png" alt="Profile Image" borderRadius='50%' maxWidth='40px'/>
                            </Button>
                        </motion.div>
                    </Flex>
                </div>
                <motion.div
                    initial={{ opacity: 0, y: -120 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{
                        // type: "spring",
                        stiffness: 260,
                        damping: 20,
                        duration: 0.75
                    }}
                    whileHover={{ scale: 1.05 }}
                    style={{
                        width: "33%",
                        maxWidth: '200px',
                        margin: '15px',
                    }}
                >
                    <Image src="prof.jpeg" alt="Profile Image" height="auto" borderRadius="15px"/>
                </motion.div>
            </div>
            {/* <AnaglyphText style={{ fontSize: '32px' }}>About Me </AnaglyphText> 
            <Flex h='100%' flex={1} overflowY='auto' alignItems='center' maxWidth='600px' margin='40px 0'>
                <Text
                    fontFamily='Verdana'
                    fontSize={['3.5vw', '1.1vw']}
                    textAlign='center'
                    color='whiteAlpha.700'
                    height='100%'
                    padding='0 20px'
                    backgroundImage='linear-gradient(0deg, rgba(23,25,35,1), rgb(23,25,35,1)' 
                >
                    Hi! I'm Jamison, a front-end developer based out of Bay Area, California, and 
                    I'm currently working at Workday. Obsessed with all things digital, I'm
                    working on making awesome web apps, beautiful graphics and art,
                    and tutoring others in the wonderful world of code.
                    {<br/>}
                    {<br/>}
                    Hysteria House is my playground to show off my professional talents and personal interests!
                    I'm looking forward to growing in the industry, and it's my dream to help build game-changing,
                    innovative software.
                </Text>
            </Flex> */}
            {/* <Button 
                background='linear-gradient(-45deg, #871ac7, #e73c7e)' 
                color='whiteAlpha.900'
                onClick={nextPage}
                _hover={{ border: `1px solid #cccccc`  }}
                marginBottom='3%'
            >
                {"My Work >"}
            </Button> */}
        </>)
    }
}

export default HomePage;