import React, { useState, useCallback, useEffect } from 'react';
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button, Link, position, Spinner } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import SpotifyWebApi from "spotify-web-api-node"
import useAuth from '../../utils'
import { debounce } from "lodash"
import { getLyrics, getSong } from 'genius-lyrics-api';
import NowPlaying from './NowPlaying';
import Sidebar from './Sidebar';

function Records({ tracks, isPlaying, currentTrack, queueSong }) {
  return (
    <Flex w='100%' flex='1' alignItems='center'>
      <Flex left='0' padding='10px 0' w='80%' overflowX='scroll'>
        <Flex position='relative' alignItems='center'  flexDirection='row-reverse' paddingRight={`${((window.innerWidth / 10) * 3) - 120}px`} w='fit-content' minW='100%' overflow='auto'>
          {!!tracks.length && tracks.map((track, index) => <div style={{width: 'fit-content'}}>
              <motion.div key={`${track.uri}`} animate={{
                rotate: isPlaying && track.uri === currentTrack.uri ? 360 : 0, 
                duration: 6,
                ease: ''
              }} 
              transition={{
                repeat: isPlaying && track.uri === currentTrack.uri ? Infinity : 0,
                duration: isPlaying && track.uri === currentTrack.uri ? 6 : 1, 
                ease: isPlaying && track.uri === currentTrack.uri ? 'linear' : 'anticipate'
              }} style={{
                width: '200px',
                height: '200px',
                margin: '20px'
              }}>
                <motion.div whileHover={{scale: 1.1}}>
                  <Flex justifyContent='center' alignItems='center' backgroundColor="#0C0C0C" w='200px'h='200px' borderRadius='50%' onClick={() => {queueSong(track)}}  cursor='pointer'>
                    <Image border='1px solid rgba(255,255,255,0.2)' src={track.album.images[0].url} borderRadius='50%' w='90%' h='90%'/>
                  </Flex>
                </motion.div>
              </motion.div>
              <Flex maxW='240px' flexDirection='column' alignItems='center' justifyContent='center' padding='0 10px' overflow='hidden'>
                <Box fontWeight='600' maxW='100%' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>{track.name}</Box>
                <div style={{fontSize: '14px', marginTop: '2px'}}>{track.album.name}</div>
                <div style={{fontSize: '14px'}}>{track.artists[0].name}</div>
              </Flex>
            </div>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Records;
