import React from "react"
import styled from "styled-components"
import styles from "../../../styles"
import { ExpandLess, ExpandMore, Home as HomeIcon, Reorder, Link as MUILink, Close, QuestionAnswer } from '@mui/icons-material';
import { ReactComponent as LostSectorIcon } from '../../../assets/lostSector.svg'
import BungieArticles from "./pages/Home/BungieArticles";
import LostSector from "./pages/Home/LostSector";
import { seasons } from '../old/seasons'
import moment from "moment";
import { getAltarsWeapon, getCruciblePartyMode, getCrucibleRelentlessMode, getSKStoryMission, getDungeon, getLightfallStoryMission, getPartition, getExoticMission, getExoSimulation, getEmpireHunt, getLostSector, getLostSectorArmor, getNightfall, getRaid, getTerminalOverload, getWQStoryMission, getWellspring } from '../old/util'
import WeeklyOperations from "./pages/Home/WeeklyOperations";
import Home from './pages/Home/Home'
import Builds from './pages/Builds/Builds'
import Activities from './pages/Activities/Activities'
import Info from './pages/Info/Info'
import Gear from './pages/Gear/Gear'
import { NavLink, Route, Routes, useLocation } from "react-router-dom";
import { ReactComponent as HelmetIcon } from '../../../assets/helmet.svg'
import { ReactComponent as RaidIcon } from '../../../assets/raid.svg'
import { ReactComponent as CatIcon } from '../../../assets/cat.svg'
import { ReactComponent as GoldenGunIcon } from '../../../assets/goldenGun.svg'
import Suggest from "./pages/Suggest/Suggest";
import BuildInspector from "./pages/Builds/BuildInspector";

const D2Beta = () => {
    const location = useLocation()

    const now = moment().utc();
    const currentSeason = seasons[0]
    const seasonStartMoment = moment(currentSeason.startDate).utc().hour(17)
    const daysCount = now.diff(seasonStartMoment, 'day')
    const weeksCount = now.diff(seasonStartMoment, 'week')
    const currentNightfall = getNightfall(currentSeason, weeksCount)
    
    const lostSector = getLostSector(currentSeason, daysCount)

    const urlPrefix = `/d2beta`
    const pathname = location.pathname.replaceAll(urlPrefix, '')

    return <Container>
        <Sidebar>
            <SidebarItem as={NavLink} to='/d2beta' selected={pathname === ''}>
                <HomeIcon fill='#e7e7e7' style={{marginRight: '10px'}}/>
                <span>Home</span>
            </SidebarItem>
            <SidebarItem as={NavLink} to='/d2beta/builds' selected={pathname === '/builds'}>
                <GoldenGunIcon style={{marginRight: '10px', height: '24px', width: 'auto', fill: 'inherit'}}/>
                <span>Builds</span>
            </SidebarItem>
            <SidebarItem as={NavLink} to='/d2beta/activities' selected={pathname === '/activities'}>
                <RaidIcon style={{marginRight: '10px', height: '24px', width: 'auto', fill: 'inherit'}}/>
                <span>Activities</span>
            </SidebarItem>
            <SidebarItem as={NavLink} to='/d2beta/gear' selected={pathname === '/gear'}>
                <HelmetIcon style={{marginRight: '10px', height: '24px', width: 'auto', fill: 'inherit'}}/>
                <span>Gear</span>
            </SidebarItem>
            <SidebarItem as={NavLink} to='/d2beta/info' selected={pathname === '/info'}>
                <CatIcon style={{marginRight: '10px', height: '24px', width: 'auto', fill: 'inherit'}}/>
                <span>Info</span>
            </SidebarItem>
            <SidebarItem as={NavLink} to='/d2beta/suggest' selected={pathname === '/suggest'}>
                <QuestionAnswer style={{marginRight: '10px', height: '24px', width: 'auto', fill: 'inherit'}}/>
                <span>Suggest</span>
            </SidebarItem>
        </Sidebar>
        <Content>
            <Routes>
                <Route path='/' element={<Home />}/>
                <Route path='/builds' element={<Builds />}/>
                <Route path='/builds/:buildID' element={<BuildInspector />}/>
                <Route path='/activities' element={<Activities />}/>
                {/* <Route path='/activities/raids' element={<Raids />}/> */}
                <Route path='/gear' element={<Gear />}/>
                <Route path='/info' element={<Info />}/>
                <Route path='/suggest' element={<Suggest />}/>
            </Routes>
            {/* {<Home/>} */}
        </Content>
    </Container>
}

const Content = styled.div`
    overflow: hidden;
    flex: 1;
    height: calc(100% - 40px);
    margin: 20px;
    background-color: #202020;
    border-radius: 8px;
`

type SidebarItemProps = {
    selected?: boolean
}

const SidebarItem = styled.div<SidebarItemProps>`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-family: Lato;
    color: #e7e7e7;
    font-size: 20px;
    padding: 10px;
    border-radius: 8px;
    letter-spacing: 2px;
    margin: 10px;
    cursor: pointer;
    border: 1px solid transparent;
    fill: #e7e7e7;
    ${styles.Animation.Transition}
    ${props => props.selected ? `
        background-color: white;
        color: black;
        fill: black !important;
        // stroke: black;
    ` : `
        &:hover {
            background-color: rgba(255,255,255,0.1);
            border: 1px solid white;
        }
    `}
`

const Sidebar = styled.div`
    width: 280px;
    height: calc(100% - 40px);
    margin: 20px;
    background-color: #202020;
    border-radius: 8px;
`

const Container = styled.div`
    display: flex;
    height: 100dvh;
    width: 100vw;
    max-height: -webkit-fill-available;
    max-width:-webkit-fill-available;
    background-color: rgb(0,0,0);
    overflow-y: hidden;
`

export default D2Beta