import { nightfallWeapons, nightfalls } from "./nightfalls"
import { lostSectors } from "./lostSectors"

export const seasons = [
    {
        name: 'Season of the Wish',
        startDate: '20231128',
        color: '#589b7c',
        number: 23,
        lostSectorRotation: [
            lostSectors.Sepulcher,
            lostSectors.Extraction,
            lostSectors.Metamorphosis,
            lostSectors.K1Revalation,
            lostSectors.K1Communion,
            lostSectors.K1CrewQuarters,
            lostSectors.ConcealedVoid,
            lostSectors.BunkerE15,
            lostSectors.BayOfDrownedWishes,
            lostSectors.VelesLabyrinth,
            lostSectors.ExodusGarden2A,
        ],
        nightfallRotation: [
            {
                strike: nightfalls.TheLightblade,
                weapon: nightfallWeapons.LoadedQuestion
            },
            {
                strike: nightfalls.HeistBattlegroundMoon,
                weapon: nightfallWeapons.BrayTechOsprey
            },
            {
                strike: nightfalls.TheScarletKeep,
                weapon: nightfallWeapons.Buzzard
            },
            {
                strike: nightfalls.HeistBattlegroundEuropa,
                weapon: nightfallWeapons.TheSwarm
            },
            {
                strike: nightfalls.TheDevilsLair,
                weapon: nightfallWeapons.WardensLaw
            },
            {
                strike: nightfalls.HeistBattlegroundMars,
                weapon: nightfallWeapons.PreAstyanaxIV
            },
        ],
    },
    {
        name: 'Season of the Witch',
        startDate: '20230822',
        color: '#589b7c',
        number: 22,
        lostSectorRotation: [
            lostSectors.Sepulcher,
            lostSectors.Extraction,
            lostSectors.Metamorphosis,
            lostSectors.K1Revalation,
            lostSectors.K1Communion,
            lostSectors.K1CrewQuarters,
            lostSectors.ConcealedVoid,
            lostSectors.BunkerE15,
            lostSectors.BayOfDrownedWishes,
            lostSectors.VelesLabyrinth,
            lostSectors.ExodusGarden2A,
        ],
        nightfallRotation: [
            {
                strike: nightfalls.TheLightblade,
                weapon: nightfallWeapons.LoadedQuestion
            },
            {
                strike: nightfalls.HeistBattlegroundMoon,
                weapon: nightfallWeapons.BrayTechOsprey
            },
            {
                strike: nightfalls.TheScarletKeep,
                weapon: nightfallWeapons.Buzzard
            },
            {
                strike: nightfalls.HeistBattlegroundEuropa,
                weapon: nightfallWeapons.TheSwarm
            },
            {
                strike: nightfalls.TheDevilsLair,
                weapon: nightfallWeapons.WardensLaw
            },
            {
                strike: nightfalls.HeistBattlegroundMars,
                weapon: nightfallWeapons.PreAstyanaxIV
            },
        ],
    },
    {
        name: 'Season of the Deep',
        startDate: '20230523',
        number: 21,
        lostSectorRotation: [],
        nightfallRotation: [],
    },
    {
        name: 'Season of Defiance',
        startDate: '20230228',
        number: 20,
        lostSectorRotation: [
            lostSectors.Thrilladrome,
            lostSectors.HydroponicsDelta,
            lostSectors.GildedPrecept,
            lostSectors.ExcavationSiteXII,
            lostSectors.SkydockIV,
            lostSectors.TheQuarry,
            lostSectors.AphelionsRest,
            lostSectors.ChamberOfStarlight,
            lostSectors.Perdition,
            lostSectors.BunkerE15,
            lostSectors.TheConflux,
        ],
        nightfallRotation: [
            nightfalls.ProvingGround,
            nightfalls.HeistBattlegroundMars,
            nightfalls.HypernetCurrent,
            nightfalls.TheArmsDealer,
            nightfalls.TheGlassway,
            nightfalls.LakeOfShadows,
        ],
    }   
]