export const ExoticType = {
    Farmable: 'Farmable',
    Quest: 'Quest',
    Craftable: 'Craftable',
}

export const classes = {
    Warlock: {
        name: 'Warlock',
        image: 'https://images.contentstack.io/v3/assets/blte410e3b15535c144/bltbba838ab3999bb59/61fac993ce3053345803469f/pose-warlock.png',
    },
    Titan: {
        name: 'Titan',
        image: 'https://images.contentstack.io/v3/assets/blte410e3b15535c144/bltee05d8e76ec70fc5/61fac993e0b919284700d461/pose-titan.png',
    },
    Hunter: {
        name: 'Hunter',
        image: 'https://images.contentstack.io/v3/assets/blte410e3b15535c144/bltc379402f4eb66059/61fac994b7ae7426d5a4d67f/pose-hunter.png',
    }
}

export const menu = {
    Categories: {
        Builds: 'Builds',
        Raids: 'Raids',
    },
    Builds: {
        Warlock: 'Warlock',
        Titan: 'Titan',
        Hunter: 'Hunter',
    }
}

export const ChampionTypes = {
    Barrier: 'Barrier',
    Unstoppable: 'Unstoppable',
    Overload: 'Overload',
}

export const ReputationBonus = {
    Crucible: {
        name: 'Crucible',
        image: 'https://images.contentstack.io/v3/assets/blte410e3b15535c144/blt0beff9b190a3057a/641b66ec2005bb70964c00f8/16-9.jpg',
    },
    Gambit: {
        name: 'Gambit',
        image: 'https://images.contentstack.io/v3/assets/blte410e3b15535c144/blt36ea6a8df63faba6/6351d5d336e63463bbda3f9b/thisweekatbungie–2_17_2022.png'
    },
    Vanguard: {
        name: 'Vanguard',
        image: 'https://images.contentstack.io/v3/assets/blte410e3b15535c144/bltb64e1f55040c632e/64d4026c0a8e994b9c0e8c48/JB_Zavala_Mem_07.jpg'
    }
}

export const enum FeaturedTab {
    Raid='Newest Raid',
    Nightfall='Nightfall',
    WeeklyRaid='Weekly Raid',
    WeeklyDungeon='Weekly Dungeon',
    WeeklyExoticMission='Exotic Mission',
    Crucible='Crucible',
}

export const FeaturedTabs = [
    FeaturedTab.Raid,
    FeaturedTab.Nightfall,
    FeaturedTab.WeeklyRaid,
    FeaturedTab.WeeklyDungeon,
    FeaturedTab.WeeklyExoticMission,
    FeaturedTab.Crucible,
]

export const enum PlanetaryTab {
    Neomuna='Neomuna',
    ThroneWorld='Throne World',
    Europa='Europa',
    Moon='Moon',
    DreamingCity='Dreaming City',
}

export const PlanetaryTabs = [
    PlanetaryTab.Neomuna,
    PlanetaryTab.ThroneWorld,
    PlanetaryTab.Europa,
    PlanetaryTab.Moon,
    PlanetaryTab.DreamingCity,
]