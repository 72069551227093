const styles = {
    Animation: {
        Transition: `
            transition: all 0.2s ease-in;
        `
    },
    MediaQueries: {
        Mobile: `@media screen and (max-width: 768px)`,
        Desktop: `@media screen and (min-width: 768px)`,
    },
    MotionProps: {
        fadeInOutStretch: {
            initial: {height: 0},
            exit: {height: 0},
            animate: {height: 'auto'},
            transition: {duration: 0.2}
        },
        fadeInOut: {
            initial: {opacity: 0},
            exit: {opacity: 0},
            animate: {opacity: 1},
            transition: {duration: 0.5}
        },
        fadeInOutDown: {
            initial: {opacity: 0, y: -20, x: 0},
            exit: {opacity: 0, y: -20, x: 0},
            animate: {opacity: 1, y: 0, x: 0},
            transition: {duration: 0.5}
        },
        slideFadeFromLeft: {
            initial: {opacity: 0, x: -20, y: 0},
            exit: {opacity: 0, x: -20, y: 0},
            animate: {opacity: 1, x: 0, y: 0},
            transition: {duration: 0.5}
        }
    },
    Scrollbar: {
        Transparent: `
            ::-webkit-scrollbar { display: none; }
            &::-webkit-scrollbar { 
                width: 0;
                height: 0;
            }
            &::-webkit-scrollbar-thumb { 
                background-color: transparent;
            }
        `,
        HysteriaHouse: `
            &::-webkit-scrollbar { 
                width: 0.4em;
                height: 0.4em;
            }
            &::-webkit-scrollbar-thumb { 
                background-color: #8e1fb6;
                border: 4px solid transparent;
                border-radius: 16px;
            }
        `,
        D2Ghost: `
            &::-webkit-scrollbar { 
                width: 0.4em;
                height: 0.4em;
                background-color: #101010;
                border: 4px solid transparent;
            }
            &::-webkit-scrollbar-thumb { 
                background-color: white;
                border: 4px solid transparent;
                border-radius: 16px;
            }
        `
    },
}

export default styles;