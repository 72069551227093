import { Flex, Box } from "@chakra-ui/react"
import { motion } from "framer-motion";
import styled from "styled-components"
import React from "react"
import { getChampionIcon, getLostSectorArmor } from "./util";
import { ReactComponent as HelmetIcon } from 'assets/helmet.svg'
import { ReactComponent as ArmsIcon } from 'assets/arms.svg'
import { ReactComponent as ChestIcon } from 'assets/chest.svg'
import { ReactComponent as BootsIcon } from 'assets/boots.svg'
import { seasons } from "./seasons";
import styles from "../../../styles";

type LostSectorProps = {
    lostSector: any,
}

const LostSector = (props: LostSectorProps) => {
    const isMobile = false;
    
    const currentSeason = seasons[0]
    const { lostSector } = props;

    const getArmorIcon = (armorType) => {
        switch (armorType) {
            case 'Arms':
                return <ArmsIcon fill='black' style={{ width: '100%', height: '100%'}}/>
            case 'Chest':
                return <ChestIcon fill='black' style={{ width: '100%', height: '100%'}}/>
            case 'Legs':
                return <BootsIcon fill='black' style={{ width: '100%', height: '100%'}}/>
            default:
            case 'Helmet':
                return <HelmetIcon fill='black' style={{ width: '100%', height: '100%'}}/>
        }
    }

    return <Flex flex={1} position='relative' minHeight={'200px'}>
        <Flex transition='all 0.2s ease-in' position='absolute' w='100%' h='100%' flexDir='column'>
            <Flex flex={1} flexDir='column' justifyContent='space-between' >
                <Flex width='100%' justifyContent='space-between' >
                    <InnerBlurb style={{borderBottomRightRadius: '4px'}}>
                        {lostSector.champions && <Box>
                            <BlurbSubtitle>Champions</BlurbSubtitle>
                            <Flex margin='0 2px'>
                                {lostSector.champions.map(champion => getChampionIcon(champion))}
                            </Flex>
                        </Box>}
                    </InnerBlurb>
                    <Flex w='40px' h='40px' backgroundColor='#d7cf06' borderRadius='8px' margin='10px' >
                        {getArmorIcon(getLostSectorArmor(currentSeason))}
                    </Flex>
                </Flex>
                <Box width='100%' justifyContent='space-between' alignItems='end'>
                    <div>
                        <InnerTitle style={{fontSize: '0.8rem', margin: '0 10px'}}>
                            {lostSector.destination}
                        </InnerTitle>
                        <InnerTitle>
                            {lostSector.name}
                        </InnerTitle>
                    </div>
                </Box>
            </Flex>
        </Flex>
        <Flex flex={1}>
            <Box
                width='100%'
                height='100%'
                backgroundSize='cover'
                backgroundRepeat='none'
                backgroundImage={`linear-gradient(rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.25)),url(${lostSector.image})`}
            />
        </Flex>
    </Flex>
}

const InnerTitle = styled.div`
    line-height: normal;
    font-size: 1.6rem;
    padding: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    user-select: none;
    width: min-content;
    margin: 2px 0 20px 10px;
`

const InnerBlurb = styled(motion.div)`
    width: fit-content;
    display: flex;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    // background-color: rgba(0,0,0,0.5);
    border: 1px solid transparent;
    // backdrop-filter: blur(8px);
    white-space: nowrap;
    user-select: none;
    transition: all 0.2s ease-in;
    fill: white;
    margin-top: 4px;
    margin-left: 10px;
    ${props => props.onClick && `
        cursor: pointer;
        border: 1px solid white;
        &:hover {
            background-color: white;
            color: black !important;
            fill: black !important;
        }
    `}
`

const BlurbSubtitle = styled.div`
    width: min-content;
    border-bottom: 1px solid white;
    font-size: 1rem;
    // color: grey;
    margin-bottom: 2px;
`

export default LostSector