import { Add, Search } from "@mui/icons-material"
import React from "react"
import styled from "styled-components"
import styles from "../../../../../styles"
import { ReactComponent as HelmetIcon } from '../../../../assets/helmet.svg'
import { ReactComponent as SkyburnerIcon } from '../../../../assets/skyburner.svg'
import Card from "../Card"
import { motion } from "framer-motion"
import Modal from "../Modal"

const Suggest = () => {
    return <Container {...styles.MotionProps.fadeInOutDown}>
        <Modal>
            <ModalContent>
                <div style={{ color: '#aaaaaa'}}>
                    <div>• Have an idea for a feature?</div>
                    <div>• Did we get something wrong?</div>
                    <div>• Have a question?</div>
                    <div>• Or anything else??</div>
                </div>
                <div style={{fontSize: '20px'}}>Submit a suggestion below!</div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', marginTop: '20px'}}>
                    <NameInput/>
                    <div>NAME <span style={{opacity: 0.5}}>(optional)</span></div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', width: '100%', marginTop: '20px'}}>
                    <MessageInput/>
                    <div>MESSAGE</div>
                </div>
                <Button>SUBMIT</Button>
            </ModalContent>
        </Modal>
    </Container>
}

const Button = styled.button`
    width: 100%;
    background-color: white;
    color: black;
    border-radius: 4px;
    padding: 10px 20px;
    margin-top: 10px;
    border: 1px solid transparent;
    ${styles.Animation.Transition}
    &:hover {
        border: 1px solid white;
        background-color: transparent;
        color: white;
    }
`

const NameInput = styled.input`
    background-color: transparent;
    border-bottom: 1px solid white;
    font-size: 20px;
    width: 100%;
    outline: 0;
    padding: 4px 12px;
    background-color: rgba(0,0,0,0.25);
`

const MessageInput = styled.textarea`
    background-color: transparent;
    border-bottom: 1px solid white;
    width: 100%;
    outline: 0;
    padding: 4px 12px;
    min-height: 160px;
    max-height: 50vh;
    background-color: rgba(0,0,0,0.25);
`

const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: Lato;
`

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5), rgba(0,0,0,0.7)), url("https://images.contentstack.io/v3/assets/blte410e3b15535c144/blta7a7798a4b5b3ea1/63d05af75c64112cb78da97e/FrontPageBanner_PC_1920x590.jpg");
    background-position: center;
    background-size: cover;
    color: white;
    font-family: Lato;
`

export default Suggest