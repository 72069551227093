import React, { Component } from "react";
import * as THREE from "three";

class MinimalShapes extends Component {
  unmount = false;

  componentWillUnmount() {
    this.unmount = true;
  }

  componentDidMount() {
    const { unmount } = this;

    let x, y = 0
    let scale = 1

    function handleClick(event) {
      scale = 1 + (Math.random() * 0.5)
      edges.material.color.set(getColor(Math.random()))
    }

    function handleMouseMove(event) {
      var eventDoc, doc, body;

      event = event || window.event; // IE-ism

      // If pageX/Y aren't available and clientX/Y are,
      // calculate pageX/Y - logic taken from jQuery.
      // (This is to support old IE)
      if (event.pageX == null && event.clientX != null) {
          eventDoc = (event.target && event.target.ownerDocument) || document;
          doc = eventDoc.documentElement;
          body = eventDoc.body;

          event.pageX = event.clientX +
            (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
            (doc && doc.clientLeft || body && body.clientLeft || 0);
          event.pageY = event.clientY +
            (doc && doc.scrollTop  || body && body.scrollTop  || 0) -
            (doc && doc.clientTop  || body && body.clientTop  || 0 );
      }

      // Use event.pageX / event.pageY here
      x = event.pageX
      y = event.pageY
      // console.log(event.pageX, event.pageY)
  }
  console.log('adding list')
  window.addEventListener('mousemove', handleMouseMove)
  window.addEventListener('click', handleClick)

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 25, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer({ alpha: true,  antialias: true });
    this.renderer = renderer;
    renderer.setSize(window.innerWidth, window.innerHeight)

    renderer.setClearColor( 0x101010, 1 );

    this.mount.appendChild( renderer.domElement );

    const getColor = (strength) => {
        const purple = [142, 31, 182]
        const blue = [22, 164, 207]
        const scaledColor = purple.map((p, i) => p - Math.floor((p - blue[i]) * strength))
        // console.log(`rgb(${scaledColor.toString()})`)
        return `rgb(${scaledColor.toString()})`
    }

    var ringMaterial = new THREE.PointsMaterial({ color: 0x8e1fb6, size: 0.2  });
    const hexagonCount = 200
    const gapSize = 40
    
    let size = 10 + (Math.random() * 5)
    // const material = new THREE.PointsMaterial( {color: getColor(Math.random()),  size: 0.2, transparent: true, opacity: 0.6} );
    const cubeGeometry = new THREE.BoxGeometry(size,size,size)
    var geometry = new THREE.EdgesGeometry( cubeGeometry ); // or WireframeGeometry
    var material = new THREE.LineBasicMaterial( { color: getColor(Math.random()), linewidth: 2 } );
    var edges = new THREE.LineSegments( geometry, material );

    edges.rotation.x = Math.PI / 3

    scene.add(edges)

    camera.position.z = 100;
    // camera.position.y = 3;
    // camera.rotateX(-Math.PI / 24)
    camera.rotateZ(-Math.PI)
    // create a point light
    const pointLight =
    new THREE.PointLight(0xFFFFFF);

    // set its position
    pointLight.position.x = 10;
    pointLight.position.y = 50;
    pointLight.position.z = 1300;

    // add to the scene
    scene.add(pointLight);

    var animate = function () {
    if (unmount) {
      return;
    }

    const rotationSpeed = Math.PI / 256

    setTimeout( function() {
      requestAnimationFrame( animate );
    }, 1000 / 70 );
    
      // edges.rotation.x = y / 100
      // edges.rotation.y = y / 100
      // edges.rotation.z = x / 100
      edges.rotation.z += -rotationSpeed

      const scaleDelta = (((scale - edges.scale.x) / Math.abs(scale - edges.scale.x)) / 100) || 0
      const round = scaleDelta > 0 ? Math.min : Math.max
      edges.scale.x = round(scale, edges.scale.x + scaleDelta)
      edges.scale.y = round(scale, edges.scale.x + scaleDelta)
      edges.scale.z = round(scale, edges.scale.x + scaleDelta)

      renderer.render( scene, camera );
    };

    const resize = () => {
      // Update camera aspect ratio
     camera.aspect = window.innerWidth / window.innerHeight;
     camera.updateProjectionMatrix();

     // Update renderer size
     renderer.setSize(window.innerWidth, window.innerHeight);
     // if (this.renderer){
       

     //   const canvas = this.renderer.domElement.current;
     //   // look up the size the canvas is being displayed
     //   const width = canvas.clientWidth;
     //   const height = canvas.clientHeight;
     
     //   // adjust displayBuffer size to match
     //   if (canvas.width !== width || canvas.height !== height) {
     //     // you must pass false here or three.js sadly fights the browser
     //     this.renderer.setSize(width, height, false);
     //     this.camera.aspect = width / height;
     //     this.camera.updateProjectionMatrix();
     
     //     // update any render target sizes here
     //   }
     // }
   }

   window.addEventListener('resize', resize, false)
   window.addEventListener('orientationchange', resize, false)

    animate(this.unmount);
  }

  render() {
    return (
      <div style={{ position: 'absolute', zIndex: 1, top: 0, left: 0 }} ref={ref => (this.mount = ref)} />
    )
  }
}

export default MinimalShapes;