import { Aspect, ClassAbility, Fragment, Grenade, Melee, MovementAbility, Super } from "components/D2Ghost/constants/abilities";
import { Build } from "./BuildInspector";
import { Class } from "components/D2Ghost/constants/characters";
import { Element } from "components/D2Ghost/constants/element";

export const getGrenades = (build: Build) => {
    if (build.subclass === Element.Prismatic) return build.class?.subclasses[Element.Prismatic].grenades
    return Object.values(Grenade).filter(grenade => build.subclass === grenade.element)
}

export const getMelees = (build: Build) => {
    if (build.subclass === Element.Prismatic) { return build.class?.subclasses[Element.Prismatic].melees }
    return Object.values(Melee).filter(melee => build.subclass === melee.element && build.class.name === melee.class)
}

export const getSupers = (build: Build) => {
    if (build.subclass === Element.Prismatic) return build.class?.subclasses[Element.Prismatic].supers
    return Object.values(Super).filter(superAbility => build.subclass === superAbility.element && build.class.name === superAbility.class)
}

export const getClassAbilities = (build: Build) => {
    if (build.subclass === Element.Prismatic) return Object.values(ClassAbility).filter((classAbility: any) =>  build.class?.name === classAbility?.class)
    return Object.values(ClassAbility).filter((classAbility: any) =>  (classAbility.element === undefined || build.subclass === classAbility.element) && build.class?.name === classAbility?.class)
}

export const getMovementAbilities = (build: Build) => {
    if (build.subclass === Element.Prismatic) return Object.values(MovementAbility).filter((movementAbility: any) =>  build.class?.name === movementAbility?.class)
    return Object.values(MovementAbility).filter((movementAbility: any) =>  (movementAbility.element === undefined || build.subclass === movementAbility.element) && build.class?.name === movementAbility?.class)
}

export const getAspects = (build: Build, selectedAspect?: any) => {
    if (build.subclass === Element.Prismatic) return build.class?.subclasses[Element.Prismatic].aspects.filter((aspect: any) =>  !build.aspects.some(buildAspect => buildAspect.name === aspect.name && buildAspect.name !== selectedAspect?.name))
    return Object.values(Aspect).filter((aspect: any) =>  build.subclass === aspect.element && build.class.name === aspect.class && !build.aspects.some(buildAspect => buildAspect.name === aspect.name && buildAspect.name !== selectedAspect?.name))
}

export const getFragments = (build: Build) => {
    return Object.values(Fragment).filter((fragment: any) =>  build.subclass === fragment.element && !build.fragments.some(buildFragment => buildFragment.name === fragment.name))
}

export const updateClass = (classOption: any, build: Build, setter: (build: Build) => void) => {
    setter({
        ...build,
        class: classOption.value,
        subclass: undefined,
        grenade: undefined,
        melee: undefined,
        super: undefined,
        movementAbility: undefined,
        classAbility: undefined,
        aspects: [],
        fragments: [],
    })
}

export const updateSubClass = (subclass: any, build: Build, setter: (build: Build) => void) => {
    setter({
        ...build,
        subclass: subclass.value,
        grenade: undefined,
        melee: undefined,
        super: undefined,
        movementAbility: undefined,
        classAbility: undefined,
        aspects: [],
        fragments: [],
    })
}

export const updateAspects = (aspect: any, index: number, build: Build, setter: (build: Build) => void) => {
    let copyOfAspects = JSON.parse(JSON.stringify(build.aspects))

    if (copyOfAspects[index]) {
        copyOfAspects[index] = aspect.value
    } else {
        copyOfAspects.push(aspect.value)
    }

    setter({
        ...build,
        aspects: copyOfAspects,
        // fragments: [],
    })
}

export const updateFragments = (fragmentOption: any, index: number, build: Build, setter: (build: Build) => void) => {
    let copyOfFragments = [...build.fragments]

    if (copyOfFragments[index]) {
        copyOfFragments[index] = fragmentOption.value
    } else {
        copyOfFragments.push(fragmentOption.value)
    }

    setter({
        ...build,
        fragments: copyOfFragments,
    })
}