import React, { useState, useCallback, useEffect } from 'react';
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button, Link, position, Spinner } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import SpotifyWebApi from "spotify-web-api-node"
import useAuth from '../../utils'
import { debounce } from "lodash"
import { getLyrics, getSong } from 'genius-lyrics-api';


function NowPlaying({
  currentTrack,
}) {
  return (
    <Box h='80px' w='100%'>
      <AnimatePresence>
        {currentTrack && <motion.div initial={{opacity: 0}} animate={{opacity: 1}} style={{height: '100%'}}>
          <Flex cursor='pointer' borderBottom='1px solid #4d4d4d' borderRight='1px solid #4d4d4d' w='300px' h='100%' maxH='100%' onClick={() => {player && player.togglePlay()}}>
            <Image src={currentTrack.album.images[0].url} h='100%'/>
            <Flex flexDirection='column' justifyContent='center' alignItems='center' flex='1' overflow='hidden'>
              <Box overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' w='90%' textAlign='center' maxH='50%'>{currentTrack.name}</Box>
              <Box overflow='hidden' textOverflow='ellipsis' color='#6e6e6e' whiteSpace='nowrap' w='90%' textAlign='center' maxH='50%'>{currentTrack.artists[0].name}</Box>
            </Flex>
          </Flex>
          {/* <Box position='absolute' top='100px' margin='0 10px' w='280px' border='0.5px solid #4d4d4d' />
          <Box position='absolute' top='95px' left={`${10 + ((280 / currentTrack.duration_ms) * trackPosition )}px`} w='10px' h='10px' borderRadius='50%' backgroundColor='black' /> */}
        </motion.div>}
      </AnimatePresence>
    </Box>    
  );
}

export default NowPlaying;
