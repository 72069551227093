import React from "react"
import styled from "styled-components"
import styles from "../../../../styles"

type ModalProps = {
    children?: React.ReactNode,
}

const Modal = (props: ModalProps) => {
    const { children } = props;
    
    return <Container>
        {children}
    </Container>
}

const Container = styled.div`
    background-color: #2e2e2e;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    min-height: 300px;
    max-height: 100%;
    min-width: 400px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 8px;
    font-size: 16px;
    font-family: Lato;
    ${styles.Animation.Transition}
`

export default Modal