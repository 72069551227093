import React, { Component } from "react";
import * as THREE from "three";
// import { OrbitControls } from "./OrbitControls";

// const functional = () => {
//     const { unmount } = this;

//     var scene = new THREE.Scene();
//     var camera = new THREE.PerspectiveCamera( 25, window.innerWidth/window.innerHeight, 0.1, 1000 );
//     var renderer = new THREE.WebGLRenderer({ alpha: true,  antialias: true });
//     renderer.setSize(window.innerWidth, window.innerHeight)
//     // renderer.setClearColor( 0x000000, 0 ); // the default

//     window.addEventListener('resize', this.resize, false)

//     renderer.setClearColor( 0x0C0C0C, 1 );

//     // const controls = new OrbitControls( camera, renderer.domElement );

//     // const canvas = document.querySelector('canvas')
//     // canvas.style.width = '100%';
//     // canvas.style.height = '100%';

//     this.mount.appendChild( renderer.domElement );

//     var ringMaterial = new THREE.PointsMaterial({ color: 0x00eeff, size: 0.2  });
//     const ringParticleCount = 100;
//     const ringGeometry = new THREE.BufferGeometry();
//     this.ringPositions = new Float32Array(ringParticleCount * ringParticleCount * 3);
//     for (let i = 0; i < ringParticleCount; i++) {
//         for (let j = 0; j < ringParticleCount; j++) {
//             this.ringPositions[((i * ringParticleCount) + j) * 3] = (Math.random() * 40) - 20//i - ((ringParticleCount - 1) / 2)
//             this.ringPositions[(((i * ringParticleCount) + j) * 3)+1] = (Math.random() * 40) - 20//j - ((ringParticleCount - 1) / 2)
//             this.ringPositions[(((i * ringParticleCount) + j) * 3)+2] = (Math.random() * 40) - 20//0
//         }
//     //   this.ringArray.push({ position: vector, number: i })
//     }
//     ringGeometry.setAttribute('position', new THREE.BufferAttribute(this.ringPositions, 3));
//     const  ringMesh = new THREE.Points(ringGeometry, ringMaterial)
//     ringMesh.castShadow = true;
//     ringMesh.rotateX(Math.PI / 2)
//     scene.add(ringMesh)

//     // camera.position.z = 25;
//     camera.position.y = 3;
//     // camera.rotateX(-Math.PI / 24)
//     camera.rotateZ(-Math.PI)
//     // create a point light
//     const pointLight =
//     new THREE.PointLight(0xFFFFFF);

//     // set its position
//     pointLight.position.x = 10;
//     pointLight.position.y = 50;
//     pointLight.position.z = 130;

//     // add to the scene
//     scene.add(pointLight);

//     var animate = function () {
//     if (unmount) {
//       return;
//     }

//     setTimeout( function() {
//       requestAnimationFrame( animate );
//     }, 1000 / 70 );
    
//       // cubes.forEach(cube => {
//       //   cube.rotation.x += 0.01;
//       //   cube.rotation.y += 0.01;
//       //   cube.position.x += (Math.floor(Math.random() * 2) - 0.5) / 20;
//       //   cube.position.y += (Math.floor(Math.random() * 2) - 0.5) / 20;
//       //   cube.position.z += (Math.floor(Math.random() * 2) - 0.5) / 20;
//       // })

//       ringMesh.rotateX(0.001)

//       renderer.render( scene, camera );
//     };

//     animate(this.unmount);
//   }

//     return <div style={{ position: 'absolute', zIndex: -1 }} ref={ref => (this.mount = ref)} />
// }

class MinimalShapes extends Component {
  unmount = false;

  componentWillUnmount() {
    this.unmount = true;
  }

  componentDidMount() {
    const { unmount } = this;

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 25, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer({ alpha: true,  antialias: true });
    this.renderer = renderer;
    renderer.setSize(window.innerWidth, window.innerHeight)
    // renderer.setClearColor( 0x000000, 0 ); // the default

    renderer.setClearColor( 0x101010, 1 );

    // const controls = new OrbitControls( camera, renderer.domElement );

    // const canvas = document.querySelector('canvas')
    // canvas.style.width = '100%';
    // canvas.style.height = '100%';

    this.mount.appendChild( renderer.domElement );

    const getColor = (strength) => {
        const purple = [142, 31, 182]
        const blue = [22, 164, 207]
        const scaledColor = purple.map((p, i) => p - Math.floor((p - blue[i]) * strength))
        // console.log(`rgb(${scaledColor.toString()})`)
        return `rgb(${scaledColor.toString()})`
    }

    var ringMaterial = new THREE.PointsMaterial({ color: 0x8e1fb6, size: 0.2  });
    const boxes = Array(50).fill(1).map(item => {
        const size = (Math.random() * 4) + 2
        const strength = Math.random()
        
        const material = new THREE.PointsMaterial( {color: getColor(Math.random()),  size: 0.2, transparent: true, opacity: (1 - strength) + 0.2} );
        const geometry = Math.random() > 0.5 ? new THREE.BoxGeometry(size,size,size) : (Math.random() > 0.5 ? new THREE.SphereGeometry(size * 0.8, 5, 5) : new THREE.ConeGeometry(size, size * 3))
        const box = new THREE.Mesh( geometry, material )

        box.position.x = (Math.random() * 120) - 60
        box.position.y = (Math.random() * 60) - 30
        box.position.z = ((strength) * -70) - 20

        box.rotation.x = Math.random()
        box.rotation.y = Math.random()
        box.rotation.z = Math.random()

        box.castShadow = true;

        scene.add(box)
        
        return box
    })

    const ringParticleCount = 100;
    const ringGeometry = new THREE.BufferGeometry();
    this.ringPositions = new Float32Array(ringParticleCount * ringParticleCount * 3);
    for (let i = 0; i < ringParticleCount; i++) {
        for (let j = 0; j < ringParticleCount; j++) {
            this.ringPositions[((i * ringParticleCount) + j) * 3] = (Math.random() * 40) - 20//i - ((ringParticleCount - 1) / 2)
            this.ringPositions[(((i * ringParticleCount) + j) * 3)+1] = (Math.random() * 40) - 20//j - ((ringParticleCount - 1) / 2)
            this.ringPositions[(((i * ringParticleCount) + j) * 3)+2] = (Math.random() * 40) - 20//0
        }
    //   this.ringArray.push({ position: vector, number: i })
    }
    ringGeometry.setAttribute('position', new THREE.BufferAttribute(this.ringPositions, 3));
    const ringMesh = new THREE.Points(ringGeometry, ringMaterial)
    ringMesh.castShadow = true;
    ringMesh.rotateX(Math.PI / 2)
    // scene.add(ringMesh)

    camera.position.z = 100;
    // camera.position.y = 3;
    // camera.rotateX(-Math.PI / 24)
    camera.rotateZ(-Math.PI)
    // create a point light
    const pointLight =
    new THREE.PointLight(0xFFFFFF);

    // set its position
    pointLight.position.x = 10;
    pointLight.position.y = 50;
    pointLight.position.z = 1300;

    // add to the scene
    scene.add(pointLight);

    let showWire = false
    const toggleBoxes = () => {
      setTimeout(() => {
        // boxes.forEach(box => box.material.wireframe = !box.material.wireframe)
        showWire = !showWire
        toggleBoxes()
      }, 4000)
    }

    toggleBoxes()

    var animate = function () {
    if (unmount) {
      return;
    }

    setTimeout( function() {
      requestAnimationFrame( animate );
    }, 1000 / 70 );
    
        boxes.forEach((box, index) => {
            box.rotation[['x', 'y', 'z'][index % 3]] += (0.01 / (index % 9));
            if (box.position.x > 120 || box.position.x < -120) {
                box.position.x *= -1
            }
            box.position['x'] += (0.1 / (index % 9) * Math.pow(-1, (index % 2) + 1));
            if (box.position.y > 60 || box.position.y < -60) {
                box.position.y *= -1
            }
            box.position['y'] += (0.1 / (index % 9) * Math.pow(-1, (index % 4) < 2));
            // if (box.position.z > 60 || box.position.z < -60) {
            //     box.position.z *= -1
            // }
            // box.position['z'] += (0.1 / (index % 9) * Math.pow(-1, (index % 2) + 1));
        })

      // cubes.forEach(cube => {
      //   cube.rotation.x += 0.01;
      //   cube.rotation.y += 0.01;
      //   cube.position.x += (Math.floor(Math.random() * 2) - 0.5) / 20;
      //   cube.position.y += (Math.floor(Math.random() * 2) - 0.5) / 20;
      //   cube.position.z += (Math.floor(Math.random() * 2) - 0.5) / 20;
      // })

      ringMesh.rotateX(0.001)

      if (showWire) {
        boxes.forEach(box => {
          if (box.material.opacity > 0.1) { box.material.opacity -= 0.01 }
          else { box.material.wireframe = true }
        })
      } else {
        boxes.forEach(box => {
          if (box.material.wireframe) { box.material.wireframe = false }
          if (box.material.opacity < 1) { box.material.opacity += 0.01 }  
        })
      }
      // boxes.forEach(box => box.material.wireframe = !box.material.wireframe)

      renderer.render( scene, camera );
    };

    const resize = () => {
      // Update camera aspect ratio
     camera.aspect = window.innerWidth / window.innerHeight;
     camera.updateProjectionMatrix();

     // Update renderer size
     renderer.setSize(window.innerWidth, window.innerHeight);
     // if (this.renderer){
       

     //   const canvas = this.renderer.domElement.current;
     //   // look up the size the canvas is being displayed
     //   const width = canvas.clientWidth;
     //   const height = canvas.clientHeight;
     
     //   // adjust displayBuffer size to match
     //   if (canvas.width !== width || canvas.height !== height) {
     //     // you must pass false here or three.js sadly fights the browser
     //     this.renderer.setSize(width, height, false);
     //     this.camera.aspect = width / height;
     //     this.camera.updateProjectionMatrix();
     
     //     // update any render target sizes here
     //   }
     // }
   }

   window.addEventListener('resize', resize, false)
   window.addEventListener('orientationchange', resize, false)

    animate(this.unmount);
  }

  render() {
    return (
      <div style={{ position: 'absolute', zIndex: 1, top: 0, left: 0 }} ref={ref => (this.mount = ref)} />
    )
  }
}

export default MinimalShapes;