import { ExoticType } from "./constants"

export const dungeons = {
    Duality: {
        name: 'Duality',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/dungeon_duality.jpg',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/94c6933727fa885fb2002a8c7aee5e42.jpg',
        exoticName: 'Heartshadow',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/3664831848/heartshadow/',
        hasMaster: true,
        destination: 'Moon',
    },
    SpireOfTheWatcher: {
        name: 'Spire of the Watcher',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/dungeon_spire_of_the_watcher.jpg',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/8c32410000243e6024130f755b23fbe6.jpg',
        exoticName: 'Heirarchy of Needs',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/4174431791/hierarchy-of-needs/',
        hasMaster: true,
        destination: 'Throne World (Mars)',
    },
    PitOfHeresy: {
        name: 'Pit of Heresy',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/dungeon_pit_of_heresy.jpg',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/de34570a93281dc201690cfd146e6d24.jpg',
        exoticName: 'Xenophage',
        exoticType: ExoticType.Quest,
        lightGG: 'https://www.light.gg/db/items/1395261499/xenophage/',
        destination: 'Moon',
    },
    ShatteredThrone: {
        name: 'Shattered Throne',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/mission_labyrinth.jpg',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/8e5d7a68305a0d1e53ccade9398c7e8b.jpg',
        exoticName: 'Wish-ender',
        exoticType: ExoticType.Quest,
        lightGG: 'https://www.light.gg/db/items/814876684/wish-ender/',
        destination: 'Dreaming City',
    },
    Prophecy: {
        name: 'Prophecy',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/dungeon_prophecy.jpg',
        destination: 'Eternity',
    },
    GraspOfAvarice: {
        name: 'Grasp of Avarice',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/30th-anniversary-grasp-of-avarice.jpg',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/b62083eed6a4708e581fc9a061bcc8e9.jpg',
        exoticName: 'Gjallarhorn',
        exoticType: ExoticType.Quest,
        lightGG: 'https://www.light.gg/db/items/1363886209/gjallarhorn/',
        hasMaster: true,
        destination: 'Cosmodrome',
    },
    GhostsOfTheDeep: {
        name: 'Ghosts of the Deep',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/dungeon_ghosts_of_the_deep.jpg',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/4984c634a7d2eca3baafc000a121263d.jpg',
        exoticName: 'The Navigator',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/1441805468/the-navigator/',
        hasMaster: true,
        destination: 'Titan',
    },
}