import React, { useEffect, useState } from "react";
import styled from "styled-components";
import styles from "../../../styles";
import { AnimatePresence, motion } from "framer-motion";
import { Box, Flex, Image } from "@chakra-ui/react";
import { ReactComponent as RaidIcon } from '../../assets/raid.svg'
import { ReactComponent as EngramIcon } from '../../assets/empty-engram.svg'
import { ReactComponent as CrucibleIcon } from '../../assets/crucible.svg'
import { ReactComponent as NightfallIcon } from '../../assets/strike.svg'
import { ReactComponent as LostSectorIcon } from '../../assets/lostSector.svg'
import { raids } from "./raids";
import { getChampionIcon, getCruciblePartyMode, getExoSimulation, getAscendantChallenge, getLightfallStoryMission, getEmpireHunt, getWQStoryMission, getPartition, getSKStoryMission, getCrucibleRelentlessMode, getDungeon, getExoticMission, getNightfall, getRaid } from "./util.js";
import { seasons } from "./seasons";
import moment from "moment";
import { FeaturedTab, FeaturedTabs, PlanetaryTab, PlanetaryTabs, ReputationBonus } from "./constants";
import { planets } from './planets'

const PlanetaryReport = () => {

    const [selectedTab, setSelectedTab] = useState(PlanetaryTab.Neomuna)
    const [isAutoplayEnabled, setIsAutoplayEnabled] = useState(true)
    const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px').matches)
    window.matchMedia('(max-width: 768px').addEventListener('change', (ev) => {
        setIsMobile(ev.matches)
    })

    const currentSeason = seasons[0]
    const now = moment().utc();
    const seasonStartMoment = moment(currentSeason.startDate).utc().hour(17)
    const weeksCount = now.diff(seasonStartMoment, 'week')

    let autoplayInterval: NodeJS.Timer

    const currentRaid = Object.values(raids).at(-1)
    const currentRotatorRaid = getRaid()
    const currentDungeon = getDungeon()
    const currentNightfall = getNightfall(currentSeason, weeksCount)
    const currentExoticMission = getExoticMission(currentSeason)
    const currentCruciblePartyMode = getCruciblePartyMode(currentSeason)
    const currentCrucibleRelentlessMode = getCrucibleRelentlessMode(currentSeason)
    const weeksSinceFirstChallenge = now.diff(moment(currentRaid?.releaseDate).subtract(4, 'days').add(3, 'weeks'), 'weeks')
    const currentLFStoryMission = getLightfallStoryMission()
    const currentPartition = getPartition()
    const currentWQStoryMission = getWQStoryMission()
    const currentSKStoryMission = getSKStoryMission()
    const currentEmpireHunt = getEmpireHunt(currentSeason)
    const currentExoSimulation = getExoSimulation(currentSeason)
    const currentAscendantChallenge = getAscendantChallenge()

    const nth = (n: number) => `${n}${["st","nd","rd"][((n+90)%100-10)%10-1]||"th"}`

    const incrementPlanetaryTab = (tab: PlanetaryTab) => {
        if (!isAutoplayEnabled) {
            return tab
        }

        switch (tab) {
            case PlanetaryTab.Neomuna:
                return PlanetaryTab.ThroneWorld
            case PlanetaryTab.ThroneWorld:
                return PlanetaryTab.Europa
            case PlanetaryTab.Europa:
                return PlanetaryTab.Moon
            case PlanetaryTab.Moon:
                return PlanetaryTab.DreamingCity
            case PlanetaryTab.DreamingCity:
                return PlanetaryTab.Neomuna
        }
    }

    useEffect(() => {
        setTimeout(() => {
            autoplayInterval = setInterval(() => {
                setSelectedTab(prevTab => incrementPlanetaryTab(prevTab))
            }, 5000);
        }, 2500)
    }, [])

    useEffect(() => {
        if (!isAutoplayEnabled) {
            clearInterval(autoplayInterval)    
        }

        return () => { clearInterval(autoplayInterval) };
    }, [ isAutoplayEnabled ])

    const getFeaturedContent = () => {
        switch (selectedTab) {
            case PlanetaryTab.ThroneWorld:
                return <FeaturedContentContainer key={selectedTab} {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb style={{borderTopRightRadius: '4px'}}>
                                <Flex flexDir='column'>
                                    <BlurbSubtitle>Weekly Story Mission</BlurbSubtitle>
                                    <Flex>{currentWQStoryMission.name}</Flex>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>{selectedTab}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={currentWQStoryMission.image}/>
                </FeaturedContentContainer>
            case PlanetaryTab.Europa:
                return <FeaturedContentContainer key={selectedTab} {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb style={{borderTopRightRadius: '4px'}}>
                                <Flex flexDir='column'>
                                    <BlurbSubtitle>Empire Hunt</BlurbSubtitle>
                                    <Flex>{currentEmpireHunt.name}</Flex>
                                </Flex>
                            </InnerBlurb>
                            <InnerBlurb whileHover={{ scale: 1.1 }} style={{borderTopLeftRadius: '4px'}}>
                                <Flex flexDir='column' alignItems={isMobile ? 'start' : 'end'}>
                                    <BlurbSubtitle>Exo Simulation</BlurbSubtitle>
                                    <Flex>{currentExoSimulation.name}</Flex>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>{selectedTab}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={currentEmpireHunt.image}/>
                </FeaturedContentContainer>
            case PlanetaryTab.Moon:
                return <FeaturedContentContainer key={selectedTab} {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb style={{borderTopRightRadius: '4px'}}>
                                <Flex flexDir='column'>
                                    <BlurbSubtitle>Weekly Story Mission</BlurbSubtitle>
                                    <Flex>{currentSKStoryMission.name}</Flex>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>{selectedTab}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={currentSKStoryMission.image}/>
                </FeaturedContentContainer>
            case PlanetaryTab.DreamingCity:
                return <FeaturedContentContainer key={selectedTab} {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb style={{borderTopRightRadius: '4px'}}>
                                <Flex flexDir='column'>
                                    <BlurbSubtitle>Ascendant Challenge</BlurbSubtitle>
                                    <Flex>{currentAscendantChallenge.name}</Flex>
                                </Flex>
                            </InnerBlurb>
                            <InnerBlurb style={{borderTopLeftRadius: '4px'}}>
                                <Flex flexDir='column' alignItems={isMobile ? 'start' : 'end'}>
                                    <BlurbSubtitle>Curse Level</BlurbSubtitle>
                                    <Flex>{currentAscendantChallenge.curse}</Flex>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>{selectedTab}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={planets[PlanetaryTab.DreamingCity].image}/>
                </FeaturedContentContainer>
            case PlanetaryTab.Neomuna:
            default:
                return <FeaturedContentContainer key={selectedTab} {...(isMobile ? styles.MotionProps.slideFadeFromLeft : styles.MotionProps.fadeInOut )}>
                    <FeaturedContent>
                        <DetailsContainer>
                            <InnerBlurb style={{borderTopRightRadius: '4px'}}>
                                <Flex flexDir='column'>
                                    <BlurbSubtitle>Weekly Story Mission</BlurbSubtitle>
                                    <Flex>{currentLFStoryMission.name}</Flex>
                                </Flex>
                            </InnerBlurb>
                            <InnerBlurb whileHover={{ scale: 1.1 }} style={{borderTopLeftRadius: '4px'}}>
                                <Flex flexDir='column' alignItems={isMobile ? 'start' : 'end'}>
                                    <BlurbSubtitle>Partition</BlurbSubtitle>
                                    <Flex>{currentPartition.name}</Flex>
                                </Flex>
                            </InnerBlurb>
                        </DetailsContainer>
                        <InnerTitle>{selectedTab}</InnerTitle>
                    </FeaturedContent>
                    <BackgroundImage image={currentLFStoryMission.image}/>
                </FeaturedContentContainer>
        }
    }

    return <Container>
        <FeaturedItemList>
            {PlanetaryTabs.map((tab: string) => <FeaturedItem
                selected={selectedTab === tab}
                onClick={() => { 
                    setSelectedTab(tab);
                    setIsAutoplayEnabled(false); 
                }}
            >
                <div>
                    <div style={{ color: 'grey' }}>{planets[tab].expansion}</div>
                    <div>{tab}</div>
                </div>
            </FeaturedItem>)}
        </FeaturedItemList>
        <RowItem style={{flexDirection: 'column', justifyContent: 'center', position: 'relative', padding: 0, flex: 1.25, height: '100%'}}>
            <AnimatePresence exitBeforeEnter>
                {getFeaturedContent()}
            </AnimatePresence>
        </RowItem>
    </Container>
}

const Container = styled.div`
    display: flex;
    flex: 1;
    max-width: 100%;
    justify-content: space-around;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
        min-height: 280px;
    }
`

const FeaturedContent = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: space-between;
`

const FeaturedContentContainer = styled(motion.div)`
    position: relative;
    height: 100%;
`

const DetailsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const WeaponImage = styled(Image)`
    height: 2em;
    width: 2em;
    border: 1px solid white;
    margin-right: 10px;
`

type BackgroundImageProps = {
    image: any,
}

const BackgroundImage = styled.div<BackgroundImageProps>`
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.25)),url(${props => props.image});
`

const BlurbSubtitle = styled.div`
    width: min-content;
    border-bottom: 1px solid white;
    font-size: 14px;
    // color: grey;
    margin-bottom: 2px;
`

const InnerBlurb = styled(motion.div)`
    width: fit-content;
    display: flex;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 6px 10px;
    background-color: rgba(0,0,0,0.5);
    border: 1px solid transparent;
    backdrop-filter: blur(8px);
    white-space: nowrap;
    user-select: none;
    transition: all 0.2s ease-in;
    fill: white;
    margin-top: 10px;
    ${styles.MediaQueries.Mobile} {
        padding: 2px 10px;
    }
    ${props => props.onClick && `
        cursor: pointer;
        border: 1px solid white;
        &:hover {
            background-color: white;
            color: black !important;
            fill: black !important;
        }
    `}
`

const InnerTitle = styled.div`
    line-height: normal;
    font-size: 1.8em;
    border-radius: 4px;
    padding: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    user-select: none;
    width: min-content;
    max-width: 100%;
    ${styles.MediaQueries.Mobile} {
        padding: 10px 20px;
        width: 100%;
        font-size: 1.6em;
    }
    ${styles.MediaQueries.Desktop} {
        padding: 20px;
    }
`

type FeaturedItemProps = {
    selected: boolean
}

const FeaturedItem = styled.div<FeaturedItemProps>`
    cursor: pointer;
    display: flex;
    padding: 2px 14px;
    align-items: center;
    flex: 1;
    max-height: 80px;
    overflow: hidden;
    font-size: 0.9em;
    background-color: rgba(0,0,0,0.85);
    ${props => props.selected && `
        background-color: #2222a2 !important;
        color: white !important;
        opacity: 1 !important;
    `}
    transition: all 0.2s ease-in;
    ${styles.MediaQueries.Mobile} {
        min-height: 50px;
        font-size: 0.8em;
        min-width: 100px;
        max-width: 100px;
        padding: 2px 8px;
        // justify-content: center;
        border-bottom: 1px solid grey;
        :not(:last-child) {
            border-right: 1px solid grey;
        }
    }
    ${styles.MediaQueries.Desktop} {
        min-width: 140px;
        max-width: 200px;
        :not(:last-child) { 
            border-bottom: 1px solid grey;
        }
        &:hover {
            background-color: rgba(255,255,255,0.1);
        }
    }
`

const FeaturedItemList = styled.div`
    display: flex;
    white-space: nowrap;
    font-size: 0.9em;
    :not(:last-child) { 
        border-right: 1px solid grey;
    }
    ${styles.MediaQueries.Mobile} {
        overflow-x: scroll;
        ${styles.Scrollbar.Transparent}
    }
    ${styles.MediaQueries.Desktop} {
        flex-direction: column;
    }
`

const RowItem = styled.div`
    display: flex;
    padding: 10px;
    flex: ${props => props.flex || 1};
    :not(:last-child) { 
        border-right: 1px solid grey;
    }
`

export default PlanetaryReport

function setSelectedTab(arg0: (prevTab: any) => any) {
    throw new Error("Function not implemented.");
}
