import { Add, BorderAllRounded } from "@mui/icons-material";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"
import styles from "styles";

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, callback: any) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const useHover = () => {
    const [hovered, setHovered] = useState<boolean>();
    
    const eventHandlers = useMemo(() => ({
      onMouseOver() { setHovered(true); },
      onMouseOut() { setHovered(false); }
    }), []);
    
    return [hovered, eventHandlers];
}

type DropdownProps = {
    placeholder?: string,
    value?: string,
    subtitle?: string,
    options?: any[],
    onClick?: (option: any) => void,
    disabled?: boolean,
    icon?: React.ReactNode,
    label?: string,
    diamond?: boolean,
    description?: string,
}

const Dropdown = (props: DropdownProps) => {
    const [hovered, eventHandlers] = useHover();

    const [isOpen, setIsOpen] = useState(false)

    const { placeholder, value, options, onClick, disabled, icon, label, subtitle, diamond, description } = props;

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, () => { setIsOpen(false) });

    const handleDropdownClick = () => {
        if (options && options.length > 1) {
            setIsOpen(!isOpen)
        }
    }

    useEffect(() => {
        if (value === undefined) {
            setIsOpen(false)
        }
    }, [value])

    useEffect(() => {
        if (!value && options?.length === 1 && onClick) {
            onClick(options[0])
        }
    }, [options])

    return <DropdownContainer ref={wrapperRef}>
        <div style={{marginBottom: '6px', whiteSpace: 'nowrap', width: 'min-content', height: '24px'}}>{label || ' '}</div>
        <DropdownHoverable {...eventHandlers} key={value} disabled={disabled} onClick={disabled ? () => {} : handleDropdownClick}>
            <div style={{display: 'flex', alignItems: 'center', width: 'auto'}}>
                <IconWrapper>
                    {!value ? <Add style={{width: 'auto', height: '100%', fill: 'white', opacity: 'inherit'}}/> : (!icon || typeof icon === 'string' ? <Icon diamond={diamond} icon={icon} /> : icon as React.ReactNode)}
                </IconWrapper>
                <LabelWrapper>
                    <Label placeholder={!value}>
                        {value ? value : placeholder}
                    </Label>
                    {subtitle && <Subtitle>{subtitle}</Subtitle>}
                </LabelWrapper>
            </div>
            {hovered && description && <Description {...styles.MotionProps.fadeInOutStretch}>
                <div style={{padding: '8px 10px'}}>{description}</div>
            </Description>}
        </DropdownHoverable>
        {isOpen && <Options onClick={handleDropdownClick} {...styles.MotionProps.fadeInOutStretch}>
            <div>
                {options?.map(option => <Option onClick={() => {
                    onClick && onClick(option)
                }}>
                    <div style={{display: 'flex', alignItems: 'center', width: 'auto'}}>
                        <IconWrapper>
                            {(!option.icon || typeof option.icon === 'string' ? <Icon diamond={diamond} icon={option.icon} /> : option.icon)}
                        </IconWrapper>
                        <LabelWrapper>
                            <Label>
                                {option.label}
                            </Label>
                            {option.subtitle && <Subtitle>{option.subtitle}</Subtitle>}
                        </LabelWrapper>
                    </div>
                    {option.description && <OptionDescription>
                        {option.description}
                    </OptionDescription>}
                </Option>)}
            </div>
        </Options>}
    </DropdownContainer>
}

const OptionDescription = styled(motion.div)`
    overflow: hidden;
    padding: 8px 10px;
    white-space: pre-line;
    width: fit-content;
    max-width: 380px;
    font-size: 13px;
    opacity: 0.7;
`

const Description = styled(motion.div)`
    position: absolute;
    top: 100%;
    border: 1px solid white;
    background-color: black;
    z-index: 1000;
    border-radius: 8px;
    overflow: hidden;
    white-space: pre-line;
    width: fit-content;
    max-width: 380px;
    font-size: 13px;
    // opacity: 0.7;
`

const Option = styled.div`
    min-height: 80px;
    min-width: min-content;
    width: 100%;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    &:not(&:last-child) {
        border-bottom: 1px solid rgba(255,255,255,0.2);
    }
    color: grey;
    ${styles.Animation.Transition}
    &:hover {
        color: white;
        background-color: rgba(255,255,255,0.1);
    }
`

const Options = styled(motion.div)`
    position: absolute;
    top: 30px;
    z-index: 100;
    border-radius: 8px;
    min-width: 100%;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    ${styles.Scrollbar.D2Ghost}
    &::-webkit-scrollbar-thumb { 
        margin-right: 20px;
    }
    background-color: #202020;
    box-shadow: rgba(0, 0, 0, 0.9) 10px 10px 10px;
    border: 1px solid white;
`

type IconProps = {
    icon?: string,
    diamond?: boolean,
}

const Icon = styled.div<IconProps>`
    border: 1px solid white;
    ${props => props.diamond && `
        border-radius: 100%;
    `}
    height: 100%;
    width: auto;
    background-image: url("${props => props.icon ?? 'https://www.bungie.net/common/destiny2_content/icons/12997544665705bbbd94742268ce7091.jpg'}");
    background-position: center;
    background-size: contain;
`

const IconWrapper = styled.div`
    height: 80px;
    padding: 8px;
    width: 80px;
    min-height: 80px;
    min-width: 80px;
`

const LabelWrapper = styled.div`
    padding: 0 10px;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
`

type LabelProps = {
    placeholder?: boolean,
}

const Label = styled.div<LabelProps>`
    white-space: nowrap;
    opacity: ${props => props.placeholder ? 0.5 : 1};
    font-size: 20px;
`

const Subtitle = styled.div<LabelProps>`
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-height: 42px;
    overflow: hidden;
    opacity: ${props => props.placeholder ? 0.5 : 1};
    font-size: 14px;
`

type DropdownHoverableProps = {
    disabled?: boolean,
}

const DropdownHoverable = styled(motion.div)<DropdownHoverableProps>`
    display: flex;
    position: relative;
    // overflow: hidden;
    flex-direction: column;
    border: 1px solid grey;
    user-select: none;
    min-width: 280px;
    border-radius: 8px;
    width: min-content;
    max-width: 100%;
    ${styles.Animation.Transition}
    ${props => props.disabled ? `opacity: 0.35;` : `
        cursor: pointer;
        &:hover {
            border: 1px solid white;
        }
        box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 8px;
    `}
`

const DropdownContainer = styled.div`
    position: relative;
    width: min-content;
    font-family: Lato;
    min-width: 280px;
    max-width: 50%;
    &:not(&:last-child) {
        padding-right: 20px;
        padding-bottom: 8px;
    }
    (&:last-child) {
        padding-bottom: 20px;
    }
`

export default Dropdown;