import React, { useState } from "react"
import styled from "styled-components"
import styles from "../../../../../styles"
import { ExpandLess, ExpandMore, Home as HomeIcon, Reorder, Link as MUILink, Close } from '@mui/icons-material';
import { ReactComponent as LostSectorIcon } from '../../../../../assets/lostSector.svg'
import BungieArticles from "./BungieArticles";
import LostSector from "./LostSector";
import { seasons } from '../../../old/seasons'
import moment from "moment";
import { getAltarsWeapon,
    getCruciblePartyMode,
    getCrucibleRelentlessMode,
    getSKStoryMission,
    getDungeon,
    getLightfallStoryMission,
    getPartition,
    getExoticMission,
    getExoSimulation,
    getEmpireHunt,
    getLostSector,
    getLostSectorArmor,
    getNightfall,
    getRaid,
    getTerminalOverload,
    getWQStoryMission,
    getWellspring } from '../../../old/util'
import WeeklyOperations from "./WeeklyOperations";
import { motion } from "framer-motion";
import PlanetaryReport from "./PlanetaryReport";

const Home = () => {
    const now = moment().utc();
    const currentSeason = seasons[0]
    const seasonStartMoment = moment(currentSeason.startDate).utc().hour(17)
    const daysCount = now.diff(seasonStartMoment, 'day')
    const weeksCount = now.diff(seasonStartMoment, 'week')
    const currentNightfall = getNightfall(currentSeason, weeksCount)
    
    const lostSector = getLostSector(currentSeason, daysCount)

    return <Container {...styles.MotionProps.fadeInOutDown}>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <div style={{height: '40px', display: 'flex', alignItems: 'center', padding: '0 20px', whiteSpace: 'nowrap', borderBottom: '1px solid white', color: 'white', background: `linear-gradient(to right, #00a3e3,rgba(0, 0, 0, 1))`}}>
                <LostSectorIcon style={{ fill: 'white', width: '20px', height: 'auto', marginRight: '14px' }}/>
                Bungie Updates
            </div>
            <BungieArticles/>
        </div>
        <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
            <div style={{height: '40px', display: 'flex', alignItems: 'center', padding: '0 20px', whiteSpace: 'nowrap', borderBottom: '1px solid white', color: 'white', background: `linear-gradient(to right, #19203B,rgba(0, 0, 0, 1))`}}>
                <LostSectorIcon style={{ fill: 'white', width: '20px', height: 'auto', marginRight: '14px' }}/>
                Weekly Operations
            </div>
            <WeeklyOperations/>
        </div>
        <div style={{flex: 1}}>
            <div style={{width: '100%', height: '100%', color: 'white', display: 'flex', fontFamily: 'Lato',}}>
                <div style={{display: 'flex', flexDirection: 'column', flex: 1,}}>
                    <div style={{height: '40px', display: 'flex', alignItems: 'center', padding: '0 20px', whiteSpace: 'nowrap', borderBottom: '1px solid white',}}>
                        <LostSectorIcon style={{ fill: 'white', width: '20px', height: 'auto', marginRight: '14px' }}/>
                        Lost Sector
                    </div>
                    <div style={{flex: 1, borderRight: '1px solid white'}}>
                        <LostSector lostSector={lostSector}/>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <div style={{height: '40px', display: 'flex', alignItems: 'center', padding: '0 20px', whiteSpace: 'nowrap', borderBottom: '1px solid white',}}>
                        <LostSectorIcon style={{ fill: 'white', width: '20px', height: 'auto', marginRight: '14px' }}/>
                        Planetary Report
                    </div>
                    <div style={{display: 'flex', flex: 1, borderRight: '1px solid white'}}>
                        <PlanetaryReport/>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <div style={{height: '40px', display: 'flex', alignItems: 'center', padding: '0 20px', whiteSpace: 'nowrap', borderBottom: '1px solid white', color: 'white'}}>
                        <LostSectorIcon style={{ fill: 'white', width: '20px', height: 'auto', marginRight: '14px' }}/>
                        Vendors
                    </div>
                </div>
            </div>
        </div>
    </Container>
}

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

export default Home