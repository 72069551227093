import { ChampionTypes } from "./constants.ts"

export const nightfalls = {
    TheArmsDealer: {
        name: 'The Arms Dealer',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_the_arms_dealer.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    LakeOfShadows: {
        name: 'Lake of Shadows',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_lake_of_shadows.jpg',
        champions: [ChampionTypes.Overload, ChampionTypes.Unstoppable]
    },
    ExodusCrash: {
        name: 'Exodus Crash',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_exodus_crash.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    TheInvertedSpire: {
        name: 'The Inverted Spire',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_inverted_spire.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    TheInsightTerminus: {
        name: 'The Insight Terminus',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_glee.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    WardenOfNothing: {
        name: 'Warden of Nothing',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_aries.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable, ChampionTypes.Overload]
    },
    TheCorrupted: {
        name: 'The Corrupted',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_gemini.jpg',
        champions: [ChampionTypes.Overload, ChampionTypes.Unstoppable]
    },
    TheScarletKeep: {
        name: 'The Scarlet Keep',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_the_scarlet_keep.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    TheGlassway: {
        name: 'The Glassway',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/europa-strike-blackbird.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    TheDisgraced: {
        name: 'The Disgraced',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/cosmodrome-strike-gantry.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    TheDevilsLair: {
        name: "The Devil's Lair",
        image: 'https://www.bungie.net/img/destiny_content/pgcr/cosmodrome_devils_lair.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    FallenSABER: {
        name: 'Fallen S.A.B.E.R.',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/cosmodrome_fallen_saber.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Overload]
    },
    ProvingGround: {
        name: 'Proving Grounds',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/nessus_proving_grounds.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    TheLightblade: {
        name: 'The Lightblade',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_lightblade.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    BirthplaceOfTheVile: {
        name: 'Birthplace of the Vile',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/strike_birthplace.jpg',
        champions: [ChampionTypes.Overload, ChampionTypes.Unstoppable]
    },
    HypernetCurrent: {
        name: 'Hypernet Current',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/lightfall_strike_plunger.jpg',
        champions: [ChampionTypes.Overload, ChampionTypes.Unstoppable]
    },
    HeistBattlegroundEuropa: {
        name: 'Heist BG: Europa',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/season_19_battleground_europa.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    HeistBattlegroundMoon: {
        name: 'Heist BG: Moon',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/season_19_battleground_luna.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
    HeistBattlegroundMars: {
        name: 'Heist BG: Mars',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/season_19_battleground_polaris.jpg',
        champions: [ChampionTypes.Barrier, ChampionTypes.Unstoppable]
    },
}

export const nightfallWeapons = {
    WardensLaw: {
        name: "Warden's Law",
        image: 'https://www.bungie.net/common/destiny2_content/icons/5c23f0f9236403e328f28ba89715903b.jpg',
        lightGG: 'https://www.light.gg/db/items/1821529912/wardens-law/',
    },
    TheSwarm: {
        name: "The Swarm",
        image: 'https://www.bungie.net/common/destiny2_content/icons/1202ae344f410909d4c0239026ea173f.jpg',
        lightGG: 'https://www.light.gg/db/items/2759590322/the-swarm/',
    },
    PreAstyanaxIV: {
        name: "Pre Astyanax IV",
        image: 'https://www.bungie.net/common/destiny2_content/icons/5da29afff3d090d23e3fe0123a7a022d.jpg',
        lightGG: 'https://www.light.gg/db/items/192784503/pre-astyanax-iv/',
    },
    LoadedQuestion: {
        name: "Loaded Question",
        image: 'https://www.bungie.net/common/destiny2_content/icons/8ed31fb8f06478487327cdb091b5c4cb.jpg',
        lightGG: 'https://www.light.gg/db/items/3125454907/loaded-question/',
    },
    BrayTechOsprey: {
        name: "BrayTech Osprey",
        image: 'https://www.bungie.net/common/destiny2_content/icons/c69a4ade8774e3ef876530fdf49fc0c4.jpg',
        lightGG: 'https://www.light.gg/db/items/3667553455/braytech-osprey/',
    },
    Buzzard: {
        name: "Buzzard",
        image: 'https://www.bungie.net/common/destiny2_content/icons/a4d80f7658370a29cdd70ef114e6b0bd.jpg',
        lightGG: 'https://www.light.gg/db/items/213264394/buzzard/',
    },
}