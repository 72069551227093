enum Expansions {
    Forsaken='Forsaken',
    Shadowkeep='Shadowkeep',
    BeyondLight='Beyond Light',
    Anniversary30='30th Anniversary',
    WitchQueen='Witch Queen',
    Lightfall='Lightfall',
    FreeToPlay='Free to Play',
}

export default Expansions