import { AnimatePresence, motion } from "framer-motion"
import React, { useState } from "react"
import styled from "styled-components"
import styles from '../../../styles';

type SidebarProps = {
    children: any,
    isOpen: boolean,
    onClose: () => void,
}

const Sidebar = (props: SidebarProps) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    return <AnimatePresence>
        {props.isOpen && <SidebarWrapper {...styles.MotionProps.fadeInOut} onClick={props.onClose}>
            <SidebarContainer
                initial={{ x: -400, opacity: 0 }}
                exit={{ x: -400, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{
                    duration: 0.4,
                }}
                onClick={(ev) => { ev.stopPropagation() }}
            >
                {props.children}                
            </SidebarContainer>
        </SidebarWrapper>}
    </AnimatePresence>
}

const SidebarContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    background-color: rgba(0,0,0,0.85);
    padding: 20px 20px 10px;
    ${styles.MediaQueries.Desktop} {
        width: 400px;
    }
    ${styles.MediaQueries.Mobile} {
        width: 100%;
    }
`

const SidebarWrapper = styled(motion.div)`
    position: absolute;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(4px);
    z-index: 999;
`

const Scrollable = styled.div`
    margin-top: 20px;
    overflow-y: scroll;
    padding-right: 20px;
    flex: 1;
    ${styles.Scrollbar.HysteriaHouse}
`

export default Sidebar