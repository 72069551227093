import React, { Component } from "react";
import * as THREE from "three";
import MoonTexture from './textures/Moon.jpg'

class Game extends Component {
  unmount = false;

  componentWillUnmount() {
    this.unmount = true;
  }

  componentDidMount() {
    const { unmount } = this;

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 25, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer({ alpha: true,  antialias: true });
    this.renderer = renderer;
    renderer.setSize(window.innerWidth, window.innerHeight)
    // renderer.setClearColor( 0x000000, 0 ); // the default

    renderer.setClearColor( 0x111111, 1 );
    // renderer.shadowMap.enabled = true

    // const controls = new OrbitControls( camera, renderer.domElement );

    // const canvas = document.querySelector('canvas')
    // canvas.style.width = '100%';
    // canvas.style.height = '100%';

    this.mount.appendChild( renderer.domElement );

    /**
     * Textures
     */
    const loadingManager = new THREE.LoadingManager()

    loadingManager.onStart = () => {
        console.log('onStart')
    }

    loadingManager.onProgress = () => {
        console.log('onProgress')
    }

    loadingManager.onLoaded = () => {
        console.log('onLoaded')
    }

    loadingManager.onError = (error) => {
        console.log('onError', error)
    }

    const textureLoader = new THREE.TextureLoader(loadingManager)
    const colorTexture = textureLoader.load(MoonTexture)
    const material = new THREE.MeshStandardMaterial({ map: colorTexture })


    // const material = new THREE.MeshStandardMaterial( {color: 'blue'} );
    const geometry = new THREE.SphereGeometry(20)
    const sphere = new THREE.Mesh( geometry, material )
    // sphere.position.y = 50
    sphere.castShadow = true; //default is false
    sphere.receiveShadow = false; //default
    scene.add(sphere)

    var starMaterial = new THREE.PointsMaterial({ color: 0xffffff, size: 0.2  });
    const starParticleCount = 50;
    const starGeometry = new THREE.BufferGeometry();
    this.starPositions = new Float32Array(starParticleCount * starParticleCount * 3);
    for (let i = 0; i < starParticleCount; i++) {
        for (let j = 0; j < starParticleCount; j++) {
            this.starPositions[((i * starParticleCount) + j) * 3] = (Math.random() * 100) - 50//i - ((starParticleCount - 1) / 2)
            this.starPositions[(((i * starParticleCount) + j) * 3)+1] = (Math.random() * 80) - 40//j - ((starParticleCount - 1) / 2)
            this.starPositions[(((i * starParticleCount) + j) * 3)+2] = (Math.random() * 80) - 40//0
        }
    //   this.starArray.push({ position: vector, number: i })
    }
    starGeometry.setAttribute('position', new THREE.BufferAttribute(this.starPositions, 3));
    const  starMesh = new THREE.Points(starGeometry, starMaterial)
    starMesh.castShadow = true;
    starMesh.rotateX(Math.PI / 2)
    scene.add(starMesh)

    const controls = {
      up: false,
      down: false,
      left: false,
      right: false,
      jump: false
    }    

    // move towards the user, and up
    camera.position.z = 40;
    camera.position.y = -10;
    camera.rotation.x = -Math.PI / 12;

    // create a point light
    const pointLight = new THREE.PointLight(0xFFFFFF, 0.8, 1000);
    // pointLight.castShadow = true; // default false

    // set its position
    // pointLight.position.x = 20;
    pointLight.position.y = -100;
    pointLight.position.z = 100;

    // add to the scene
    scene.add(pointLight);

    var animate = function () {
    if (unmount) {
      return;
    }

    
    setTimeout( function() {
      requestAnimationFrame( animate );
    }, 1000 / 70 );

    sphere.rotation.y += 0.0001
    sphere.rotation.z += 0.000001
    starMesh.rotation.x += (0.0001)


      renderer.render( scene, camera );
    };

    const resize = () => {
      // Update camera aspect ratio
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();

     // Update renderer size
     renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

     // if (this.renderer){
       

     //   const canvas = this.renderer.domElement.current;
     //   // look up the size the canvas is being displayed
     //   const width = canvas.clientWidth;
     //   const height = canvas.clientHeight;
     
     //   // adjust displayBuffer size to match
     //   if (canvas.width !== width || canvas.height !== height) {
     //     // you must pass false here or three.js sadly fights the browser
     //     this.renderer.setSize(width, height, false);
     //     this.camera.aspect = width / height;
     //     this.camera.updateProjectionMatrix();
     
     //     // update any render target sizes here
     //   }
     // }
   }

   window.addEventListener('resize', resize, false)
   window.addEventListener('orientationchange', resize, false)
   addEventListener("keydown", (event) => {
    console.log(event.keyCode)
    switch (event.keyCode) {
      case 32:
        controls.jump = true;
        break;
      case 37:
        controls.left = true;
        break;
      case 38:
        controls.down = true;;
        break;
      case 39:
        controls.right = true;
        break;
      case 40:
        controls.up = true;
        break;
      default:
        return;
    }
   });
   addEventListener("keyup", (event) => {
    switch (event.keyCode) {
      case 32:
        controls.jump = false;
        break;
      case 37:
        controls.left = false;
        break;
      case 38:
        controls.down = false;;
        break;
      case 39:
        controls.right = false;
        break;
      case 40:
        controls.up = false;
        break;
      default:
        return;
    }
   });

    animate(this.unmount);
  }

  render() {
    return (
      <div style={{ position: 'absolute', zIndex: -1, }} ref={ref => (this.mount = ref)} />
    )
  }
}

export default Game;