import React, { useState, useCallback, useEffect } from 'react';
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button, Link, position, Spinner } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import SpotifyWebApi from "spotify-web-api-node"
import useAuth from '../../utils'
// import { debounce } from "lodash"
import { getLyrics, getSong } from 'genius-lyrics-api';
import NowPlaying from './NowPlaying';
import Sidebar from './Sidebar';
import Records from './Records';
import styled from 'styled-components'

const scopes = [
  'streaming',
  'user-read-email',
  'user-read-private',
  'user-library-read',
  'user-library-modify',
  'user-read-playback-state',
  'user-modify-playback-state',
]
const clientID = "5fd6bb80e8df4ad8a676982b06e7d490";
const showDialog = true;
const responseType = 'token';

function MusicNerd() {
  const [title, setTitle] = useState('')
  const [songs, setSongs] = useState(undefined)
  const [player, setPlayer] = useState(undefined)
  const [isPlaying, setIsPlaying] = useState(undefined)
  const [currentTrack, setCurrentTrack] = useState(undefined)
  const [tracks, setTracks] = useState([])
  const [isLoadingSearchResults, setIsLoadingSearchResults] =  useState(false)
  const [isLoadingLyrics, setIsLoadingLyrics] =  useState(false)
  const [deviceID, setDeviceID] = useState(undefined)
  const [lyrics, setLyrics] = useState(undefined)
  const [trackPosition, setTrackPosition]= useState(0)

  const isMobile = window.innerHeight > window.innerWidth;

  function getAccessTokenFromUrl() {
    const hashParams = {};
    const r = /([^&;=]+)=?([^&;]*)/g;
    const queryString = window.location.hash.substring(1);
  
    let match;
    while ((match = r.exec(queryString))) {
      hashParams[decodeURIComponent(match[1])] = decodeURIComponent(match[2]);
    }
  
    return hashParams.access_token;
  }

  const accessToken = window.location.hash?.split('=')[1]?.split('&')[0] || null

  const spotifyApi = new SpotifyWebApi({
    clientId: clientID,
    redirectUri: 'http://localhost:3000/musicnerd',
    accessToken: accessToken || undefined
  })

  // Use localhost for dev
  const AUTH_URL=`https://accounts.spotify.com/authorize?client_id=5fd6bb80e8df4ad8a676982b06e7d490&response_type=token&redirect_uri=http://localhost:3000/musicnerd&scope=${scopes.join('%20')}`
  
  const getSongs = useCallback(_.debounce((searchTerm) => {
    spotifyApi.searchTracks(searchTerm).then(res => {
      setSongs(res.body.tracks.items)
    })
  }, 2000), [])

  useEffect(() => {
    setIsLoadingSearchResults(false)
  }, [songs])


  const updateTitle = (e) => {
    setTitle(e.target.value)
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.scdn.co/spotify-player.js";
    script.async = true;

    document.body.appendChild(script);

    window.onSpotifyWebPlaybackSDKReady = () => {
        const player = new window.Spotify.Player({
            name: 'Web Playback SDK',
            getOAuthToken: cb => { cb(accessToken); },
            volume: 0.5
        });

        setPlayer(player);

        player.addListener('ready', ({ device_id }) => {
            setDeviceID(device_id)
            console.log('Ready with Device ID', device_id);
        });

        player.addListener('not_ready', ({ device_id }) => {
            console.log('Device ID has gone offline', device_id);s
        });

        player.addListener('player_state_changed', ( state => {

          if (!state) {
              return;
          }

          // console.log(state)
          // setTrackPosition(state.position)
      
          setIsPlaying(!state.paused);
      }));

        player.connect();
    };
  }, [])

  const incrementProgressBar = () => {
    setTrackPosition(trackPosition + 1000)
  }

  // useEffect(() => {
  //   console.log(trackPosition)
  //   if (isPlaying && trackPosition < currentTrack.duration_ms)
  //     setTimeout(incrementProgressBar, 1000)
  // }, [trackPosition])

  const playTrack = async (track) => {
    await fetch(`https://api.spotify.com/v1/me/player/play?device_id=${deviceID}`, {
      method: 'PUT',
      body: JSON.stringify({ uris: [track.uri] }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      },
    }).finally(() => {
      setCurrentTrack(track)
    })

    setTrackPosition(0)
    const start = Date.now();
    setInterval(function() {
        const delta = Date.now() - start; // milliseconds elapsed since start
        setTrackPosition(delta)
    }, 1000); // update about every second

    setIsLoadingLyrics(true)
    getLyrics({
      apiKey: 'je3qlr41jhKBsllWM7EY0xDpmF-igK9YQWKl4_W5atRCUmmNfqNXoWJZK53NEBiR',
      title: track.name,
      artist: track.artists[0].name,
      optimizeQuery: true
    }).then(res => {
      console.log(res)
      setLyrics(res)
    }).finally(() => {
      setIsLoadingLyrics(false)
    })
    
  }

  const queueSong = (track) => {
    setIsPlaying(false)
    currentTrack && track.uri === currentTrack.uri ? player.togglePlay() : playTrack(track);
    if (!tracks.some(t => t.uri === track.uri)) setTracks([track, ...tracks].slice(0, 10))
  }

  useEffect(() => {
    if (title !== '') {
      setIsLoadingSearchResults(true)
      getSongs(title)
    } else {
      setIsLoadingSearchResults(false)
      getSongs.cancel()
    }
    
  }, [title])

  return (
    <Flex w='100vw' maxH={`${document.documentElement.clientHeight}px`} h='100vh' alignItems='center' justifyContent='center' flexDirection='column' backgroundColor='#c1c1c1' overflow='hidden'> 
    {accessToken ? <Flex id='loaded' w='100%' h='100%'>
      <Flex justifyContent='space-between' alignItems='center' w='100%' h='100%' flexDirection='column'>
        <NowPlaying currentTrack={currentTrack} />
        <Records tracks={tracks} currentTrack={currentTrack} isPlaying={isPlaying} queueSong={queueSong} />
        <SearchBar placeholder='Search for a Song' onChange={updateTitle}  autoFocus/>
        <Box color='#999999' h='35%' marginBottom='5%' boxSizing='border-box' overflowY='scroll' border={`${songs && !isLoadingSearchResults ? 1 : 0}px solid #4d4d4d`}>
          <AnimatePresence>
            {isLoadingSearchResults ? <Spinner/>
            : songs && <motion.div display='flex' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} w='100%' h='100%'>
              <AnimatePresence>
              {songs.map((song, index) => <motion.div key={`${index}:${song.name}`} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                <Flex cursor='pointer' justifyContent='center' color='#2d2d2d' alignItems='center' border='1px solid #4d4d4d' w='300px' h='50px' overflow='hidden' onClick={() => {queueSong(song)}}>
                  <Image src={song.album.images[0].url} h='100%' w='50px'/>
                  <Flex flexDirection='column' justifyContent='center' flex='1' overflow='hidden' alignItems='center'>
                    <Box overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' w='90%' textAlign='center' maxH='50%'>{song.name}</Box>
                    <Box overflow='hidden' textOverflow='ellipsis' color='#6e6e6e' whiteSpace='nowrap' w='90%' textAlign='center' maxH='50%'>{song.artists[0].name}</Box>
                  </Flex>
                </Flex>
              </motion.div>)}
              </AnimatePresence>
            </motion.div>}
          </AnimatePresence>
        </Box>
      </Flex> 
      <Sidebar lyrics={lyrics} isLoadingLyrics={isLoadingLyrics}/>
    </Flex> : <Link 
      border='1px solid #4d4d4d' 
      padding='12px' 
      cursor='pointer' 
      href={AUTH_URL}
    >
      Log in to Spotify
    </Link>
    }
      <Flex _hover={{ backgroundColor: '#4d4d4d', color: '#c1c1c1' }} transition='all 0.2s ease-in' alignItems='center' cursor='pointer' justifyContent='center' position='absolute' left='10%' bottom='10%' border='1px solid #4d4d4d' borderRadius='0' padding='20px' w='200px' h='200px'>Find new music?</Flex>
      {/*accessToken ?
      <Flex justifyContent='space-between' alignItems='center' w='100%' h='100%' flexDirection='column'>
        <NowPlaying currentTrack={currentTrack} />
        <Records tracks={tracks} currentTrack={currentTrack} isPlaying={isPlaying} queueSong={queueSong} />
        <SearchBar placeholder='Search for a Song' onChange={updateTitle}  autoFocus/>
        <Box color='#999999' h='35%' marginBottom='5%' boxSizing='border-box' overflowY='scroll' border={`${songs && !isLoadingSearchResults ? 1 : 0}px solid #4d4d4d`}>
          <AnimatePresence>
            {isLoadingSearchResults ? <Spinner/>
            : songs && <motion.div display='flex' initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} w='100%' h='100%'>
              <AnimatePresence>
              {songs.map(song => <motion.div key={song.name} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
                <Flex  cursor='pointer' justifyContent='center' color='#2d2d2d' alignItems='center' border='1px solid #4d4d4d' w='300px' h='50px' overflow='hidden' onClick={() => {queueSong(song)}}>
                  <Image src={song.album.images[0].url} h='100%' w='50px'/>
                  <Flex flexDirection='column' justifyContent='center' flex='1' overflow='hidden' alignItems='center'>
                    <Box overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap' w='90%' textAlign='center' maxH='50%'>{song.name}</Box>
                    <Box overflow='hidden' textOverflow='ellipsis' color='#6e6e6e' whiteSpace='nowrap' w='90%' textAlign='center' maxH='50%'>{song.artists[0].name}</Box>
                  </Flex>
                </Flex>
              </motion.div>)}
              </AnimatePresence>
            </motion.div>}
          </AnimatePresence>
        </Box>
      </Flex> : 
      <Link border='1px solid #4d4d4d' padding='12px' onClick={() => {}} cursor='pointer' href={AUTH_URL}>
        Log in to Spotify
      </Link>} */}
    </Flex>
  );
}

const SearchBar = styled.input`
  height: 30px;
  background-color: rgba(105, 100, 100, 0.5);
  border-radius: 10px;
  color: #d9d7d7;
  padding: 20px 40px;
  margin: 20px 40px;
  outline: none;
  border: 0 solid blue;
  font-size: 20px;
  text-align: center;
  &::placeholder {
    color: #d9d7d7;
  }
`

export default MusicNerd;
