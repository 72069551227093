import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import twids from '../../../old/twids'
import styles from "../../../../../styles"
import moment from "moment"
import { seasons } from '../../../old/seasons'
import { getAltarsWeapon, getCruciblePartyMode, getCrucibleRelentlessMode, getSKStoryMission, getDungeon, getLightfallStoryMission, getPartition, getExoticMission, getExoSimulation, getEmpireHunt, getLostSector, getLostSectorArmor, getNightfall, getRaid, getTerminalOverload, getWQStoryMission, getWellspring } from '../../../old/util'
import TabButton from "../TabButton"

enum WeeklyOperation {
    Nightfall,
    Raid,
    Dungeon,
    ExoticMission
}

const BungieArticles = () => {
    const [weeklyOperation, setWeeklyOperation] = useState(WeeklyOperation.Nightfall)
    
    const now = moment().utc();
    const currentSeason = seasons[0]
    const currentTWID = twids[0]
    const seasonStartMoment = moment(currentSeason.startDate).utc().hour(17)
    const weeksCount = now.diff(seasonStartMoment, 'week')
    const currentNightfall = getNightfall(currentSeason, weeksCount)

    const openTWIDInNewTab = () => {
        window.open(currentTWID.url, '_blank');
    }

    return <Container backgroundImage={currentNightfall.strike.image}>
        <ButtonRow>
            <TabButton
                onClick={() => { setWeeklyOperation(WeeklyOperation.Nightfall) }}
                selected={weeklyOperation === WeeklyOperation.Nightfall}
            >
                Nightfall
            </TabButton>
            <TabButton
                onClick={() => { setWeeklyOperation(WeeklyOperation.Raid) }}
                selected={weeklyOperation === WeeklyOperation.Raid}
            >
                Raids
            </TabButton>
            <TabButton
                onClick={() => { setWeeklyOperation(WeeklyOperation.Dungeon) }}
                selected={weeklyOperation === WeeklyOperation.Dungeon}
            >
                Dungeons
            </TabButton>
            <TabButton
                onClick={() => { setWeeklyOperation(WeeklyOperation.ExoticMission) }}
                selected={weeklyOperation === WeeklyOperation.ExoticMission}
            >
                Exotic
            </TabButton>
        </ButtonRow>
        <InnerTitle>PsiOps Cosmodrome</InnerTitle>
    </Container>
}

const InnerTitle = styled.div`
    position: absolute;
    bottom: 20px;
    left: 20px;
    line-height: normal;
    font-family: Lato;
    color: white;
    font-size: 1.6em;
    border-radius: 4px;
    padding: 0 2px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    user-select: none;
    width: min-content;
    ${styles.MediaQueries.Mobile} {
        // margin: 0 20px;
        width: 100%;
        // text-align: center;
    }
    ${styles.MediaQueries.Desktop} {
        // margin: 20px;
    }
`

const ButtonRow = styled.div`
    position: absolute;
    top: 10px;
    left: 20px;
    display: flex;
`

const zoomAnimation = keyframes`
    0% {
        background-size: auto 340%;
    }
    100% {
        background-size: auto 350%;
    }
`

const zoomOutAnimation = keyframes`
    0% {
        background-size: auto 350%;
    }
    100% {
        background-size: auto 340%;
    }
`

type ContainerProps = {
    backgroundImage: string,
}

const Container = styled.div<ContainerProps>`
    position: relative;
    flex: 1;
    width: 100%;
    background-position: center;
    background-size: auto 340%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.5)),url(${props => props.backgroundImage});
    ${styles.Animation.Transition}
    cursor: pointer;
    &:hover {
        animation: ${zoomAnimation} 2s forwards normal;
    }
    :not(&:hover) {
        animation: ${zoomOutAnimation} 2s forwards normal;
    }
`

export default BungieArticles