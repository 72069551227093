import LooksIcon from '@mui/icons-material/Looks';
import React from 'react';

export enum Element {
    Void='Void',
    Solar='Solar',
    Arc='Arc',
    Stasis='Stasis',
    Strand='Strand',
    Prismatic='Prismatic',
}

export const ElementIcon = {
    [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/32b112a9460e6f0e2b9ee15dc53fe1c1.png',
    [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/fedcb91b7ab0584c12f0e9fec730702b.png',
    [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/949af7a61d60a8e6071282daafa9e6e9.png',
    [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/6e441ffa8c8171ce9caf71e51b72fc19.png',
    [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/41c0024ce809085ac16f4e0777ea0ac4.png',
    [Element.Prismatic]: <LooksIcon style={{width: '100%', height: "auto"}}/>,
}