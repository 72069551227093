import { ExoticType } from "./constants.ts"
import Expansions from './expansions.ts'

export const raids = {
    LastWish: {
        name: 'Last Wish',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/raid_beanstalk.jpg',
        exoticName: 'One Thousand Voices',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/51c53df606cca474dce3cadbf7d5ce28.jpg',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/2069224589/one-thousand-voices/',
        destination: 'Dreaming City',
        expansion: Expansions.Forsaken,
    },
    GardenOfSalvation: {
        name: 'Garden of Salvation',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/raid_garden_of_salvation.jpg',
        exoticName: 'Divinity',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/c6aa03536fd68b5fca5ad6b83ea0cf1e.jpg',
        exoticType: ExoticType.Quest,
        lightGG: 'https://www.light.gg/db/items/4103414242/divinity/',
        destination: 'Moon',
        expansion: Expansions.Shadowkeep,
    },
    DeepStoneCrypt: {
        name: 'Deep Stone Crypt',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/europa-raid-deep-stone-crypt.jpg',
        exoticName: 'Eyes of Tomorrow',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/9caeff89015f02ad52e6fefe95398b01.jpg',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/2399110176/eyes-of-tomorrow/',
        destination: 'Europa',
        expansion: Expansions.BeyondLight,
    },
    VaultOfGlass: {
        name: 'Vault of Glass',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/vault_of_glass.jpg',
        exoticName: 'Vex Mythoclast',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/111a10b59029fc6a9ca5e821267e6f6c.jpg',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/4289226715/vex-mythoclast/',
        hasMaster: true,
        destination: 'Venus',
        expansion: Expansions.FreeToPlay,
    },
    VowOfTheDisciple: {
        name: 'Vow of the Disciple',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/raid_nemesis.jpg',
        exoticName: 'Collective Obligation',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/238ab90ba2f858ebb8a5a1797a13fdd4.jpg',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/3505113722/collective-obligation/',
        hasMaster: true,
        destination: 'Throne World',
        expansion: Expansions.WitchQueen,
    },
    KingsFall: {
        name: "King's Fall",
        image: 'https://www.bungie.net/img/destiny_content/pgcr/raid_kings_fall.jpg',
        exoticName: 'Touch of Malice',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/106a8a40a6e55b5ec5088a26d1ed979d.jpg',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/1802135586/touch-of-malice/',
        hasMaster: true,
        destination: 'Dreadnought',
        expansion: Expansions.FreeToPlay,
    },
    RootOfNightmares: {
        name: 'Root of Nightmares',
        image: 'https://www.bungie.net/img/destiny_content/pgcr/raid_root_of_nightmares.jpg',
        exoticName: 'Conditional Finality',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/c9b4d65adcdfcadde871e5961ce912fb.jpg',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/3371017761/conditional-finality/',
        hasMaster: true,
        destination: 'Neomuna',
        expansion: Expansions.Lightfall,
    },
    CrotasEnd: {
        releaseDate: '20230901',
        encounters: 4,
        name: "Crota's End",
        image: 'https://www.bungie.net/img/destiny_content/pgcr/raid_crotas_end.jpg',
        exoticName: 'Necrochasm',
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/52e8bb636771f4731da3f73f06fcad04.jpg',
        exoticType: ExoticType.Farmable,
        lightGG: 'https://www.light.gg/db/items/1034055198/necrochasm/',
        hasMaster: true,
        destination: 'Moon',
        expansion: Expansions.FreeToPlay,
    },
}