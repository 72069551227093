import moment from 'moment';

import { raids } from "./raids"
import { dungeons } from "./dungeons"
import { LightfallStoryMissions, WitchQueenStoryMissions, WeaponRotations, EmpireHunts, ExoSimulation, ShadowkeepStoryMissions, Partition, AscendantChallenge } from "./planets"
import { ReactComponent as UnstoppableIcon } from 'assets/unstop.svg'
import { ReactComponent as BarrierIcon } from 'assets/barrier.svg'
import { ReactComponent as OverloadIcon } from 'assets/overload.svg'
import { seasons } from './seasons'
import { CrucibleModes } from './crucible'
import { ExoticMissions } from './exoticMissions';
import { ChampionTypes } from './constants';

export const getRaid = () => {
    const week = moment().utc().diff(moment(seasons[0].startDate).utc().hour(17), 'weeks')
    const rotatingRaids = Object.values(raids).slice(0, -1);

    return rotatingRaids[(week) % rotatingRaids.length];
}

export const getDungeon = () => {
    const week = moment().diff(moment(seasons[0].startDate).utc().hour(17), 'weeks')
    const rotatingDungeons = Object.values(dungeons).slice(0, -1);

    return rotatingDungeons[week % rotatingDungeons.length];
}

export const getLightfallStoryMission = () => {
    const week = moment().diff(moment('20220209').utc().hour(17), 'weeks')
    console.log(week % LightfallStoryMissions.length)
    return LightfallStoryMissions[(week % LightfallStoryMissions.length)]
}

export const getWQStoryMission = () => {
    const week = moment().diff(moment('20220308').utc().hour(17), 'weeks')
    return WitchQueenStoryMissions[week % WitchQueenStoryMissions.length]
}

export const getSKStoryMission = () => {
    const week = moment().diff(moment('20191117').utc().hour(17), 'weeks')
    return ShadowkeepStoryMissions[week % ShadowkeepStoryMissions.length]
}

export const getExoticMission = (season) => {
    const seasonStartMoment = moment(season.startDate).utc().hour(17)
    const weeksCount = moment().diff(seasonStartMoment, 'weeks')

    const exoticMissions = [
        ExoticMissions.Presage,
        ExoticMissions.VoxObscura,
        ExoticMissions.SeraphShield,
    ]

    return exoticMissions[weeksCount % exoticMissions.length]
}

export const getNightfall = (season, week) => {
    return season.nightfallRotation[week % 6];
}

export const getPartition = () => {
    const now = moment();
    const seasonOfDefianceStartMoment = moment('20231107').utc().hour(17)
    const weeks = now.diff(seasonOfDefianceStartMoment, 'weeks')

    const partitions = [
        Partition.HardReset,
        Partition.Backdoor,
        Partition.Ordnance,
    ]

    return partitions[weeks % partitions.length]
}

export const getExoSimulation = (season) => {
    const seasonStartMoment = moment(season.startDate).utc().hour(17)
    const weeksCount = moment().diff(seasonStartMoment, 'weeks')

    const exoSimulations = [
        ExoSimulation.Survival,
        ExoSimulation.Agility,
        ExoSimulation.Safeguard,
    ]

    return exoSimulations[weeksCount % exoSimulations.length]
}

export const getEmpireHunt = (season) => {
    const seasonStartMoment = moment(season.startDate).utc().hour(17)
    const weeksCount = moment().diff(seasonStartMoment, 'weeks')

    const empireHunts = [
        EmpireHunts.TheDarkPriestess,
        EmpireHunts.TheTechnocrat,
        EmpireHunts.TheWarrior,
    ]

    return empireHunts[((weeksCount + 2) % empireHunts.length)]
}

export const getLostSector = (season, day) => {
    return season.lostSectorRotation[day % season.lostSectorRotation.length]
}

export const getCruciblePartyMode = (season) => {
    const seasonStartMoment = moment(season.startDate).utc().hour(17)
    const weeksCount = moment().diff(seasonStartMoment, 'weeks')
    
    const partyModes = [
        CrucibleModes.MomentumControl,
        CrucibleModes.Mayhem,
        CrucibleModes.TeamScorched,
    ]
    
    return partyModes[weeksCount % partyModes.length]
}

export const getCrucibleRelentlessMode = (season) => {
    const seasonStartMoment = moment(season.startDate).utc().hour(17)
    const weeksCount = moment().diff(seasonStartMoment, 'weeks')
    
    const relentlessModes = [
        CrucibleModes.Rift,
        CrucibleModes.ZoneControl,
        CrucibleModes.Clash,
        CrucibleModes.Supremacy,
    ]
    
    return relentlessModes[weeksCount % relentlessModes.length]
}

export const getLostSectorArmor = (season, day) => {
    const now = moment();
    const seasonStartMoment = moment(season.startDate).utc().hour(17)
    const daysCount = now.diff(seasonStartMoment, 'day')

    const armors = [
        'Chest',
        'Helmet',
        'Legs',
        'Arms',
    ]

    return armors[daysCount % 4]
}

export const getAltarsWeapon = () => {
    const now = moment();
    const seasonOfSeraphStartMoment = moment('20221206').utc().hour(17)
    const daysCount = now.diff(seasonOfSeraphStartMoment, 'day')

    return WeaponRotations.AltarsOfSorrow[daysCount % 3]
}

export const getChampionIcon = (champType) => {
    switch (champType) {
        case ChampionTypes.Barrier:
            return <BarrierIcon title='Barrier' style={{height: '1.6em', width: '1.6em', marginRight: '3px',fill: 'white'}}/>
        case ChampionTypes.Overload:
            return <OverloadIcon title='Overload' style={{height: '1.6em', width: '1.6em', marginRight: '3px',fill: 'white'}}/>
        case ChampionTypes.Unstoppable:
        default:
            return <UnstoppableIcon title='Unstoppable' style={{height: '1.6em', width: '1.6em', marginRight: '3px',fill: 'white'}}/>
    }
}

export const getWellspring = () => {
    const now = moment();
    const seasonOfSeraphStartMoment = moment('20221206').utc().hour(17)
    const daysCount = now.diff(seasonOfSeraphStartMoment, 'day')

    return WeaponRotations.Wellspring[daysCount % 4]
}

export const getTerminalOverload = () => {
    const now = moment();
    const seasonOfDefianceStartMoment = moment('20220228').utc().hour(17)
    const daysCount = now.diff(seasonOfDefianceStartMoment, 'day') + 1

    return WeaponRotations.TerminalOverload[daysCount % 3]
}

export const getAscendantChallenge = () => {
    const now = moment();
    const seasonOfDefianceStartMoment = moment('20230103').utc().hour(17)
    const weeksCount = now.diff(seasonOfDefianceStartMoment, 'weeks')

    const challenges = [
        AscendantChallenge.AgonarchAbyss,
        AscendantChallenge.CimmerianGarrison,
        AscendantChallenge.Ouroborea,
        AscendantChallenge.ForfeitShrine,
        AscendantChallenge.ShatteredRuins,
        AscendantChallenge.KeepofHonedEdges,
    ]

    return challenges[weeksCount % challenges.length]
}