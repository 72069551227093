import React from "react"
import styled from "styled-components"
import styles from "../../../../styles"

type CardProps = {
    title: string,
    subtitle?: string,
    icon: React.ReactNode,
    onClick?: () => void,
}

const Card = (props: CardProps) => {
    const { icon, title, subtitle, onClick } = props;

    return <Container onClick={onClick}>
        {icon}
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
    </Container>
}

const CardSubtitle = styled.div`
    color: grey;
    font-size: 16px;
    text-align: center;
`

const CardTitle = styled.div`
    color: white;
    font-size: 26px;
    margin-bottom: 10px;
`

const Container = styled.div`
    background-color: #2e2e2e;
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
    min-height: 300px;
    max-height: 300px;
    min-width: 240px;
    max-width: 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 8px;
    justify-content: center;
    font-family: Lato;
    cursor: pointer;
    ${styles.Animation.Transition}
    &:hover {
        background-color: white;
        * {
            color: black !important;
            fill: black !important;
        }
    }
`

export default Card