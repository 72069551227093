import { Flex } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import styled from "styled-components";
import { getAltarsWeapon, getTerminalOverload, getWellspring } from "./util";

const DailyRotators = () => {
    const altarsWeapon = getAltarsWeapon()
    const currentWellspring = getWellspring()
    const currentTerminalOverload = getTerminalOverload()

    return <Flex flexDir='column' w='100%' fontSize='1em' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
        <ColumnItem style={{ backgroundSize: 'cover', backgroundPosition: 'bottom', backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.25)),url("https://www.bungie.net/img/destiny_content/pgcr/patrol_moon.jpg")', justifyContent: 'space-between', alignItems: 'center', height: `${100/3}px`}}>
            <ActivityLabel> 
                <div style={{ fontSize: '0.9rem', borderBottom: '1px solid white'}}>Altars of Sorrow</div>
                <div style={{fontSize: '1.1rem'}}>{altarsWeapon.name}</div>
            </ActivityLabel>
            <motion.img onClick={() => { window.open(altarsWeapon.lightGG, '_blank'); }} whileHover={{scale: 1.1}} style={{ cursor: 'pointer', border: '1px solid white', height: '50px', width: '50px', margin: '20px 0'}} alt={altarsWeapon.name} src={`${altarsWeapon.image}`} />    
        </ColumnItem>
        <ColumnItem style={{backgroundSize: 'cover', backgroundPosition: 'bottom', backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.25)),url("https://www.bungie.net/img/destiny_content/pgcr/wellspring_attack_two.jpg")', justifyContent: 'space-between', alignItems: 'center', height: `${100/3}px`}}>
            <ActivityLabel>
                <div style={{ fontSize: '0.9rem', borderBottom: '1px solid white'}}>Wellspring: {currentWellspring.type}</div>
                <div style={{fontSize: '1.1rem'}}>{currentWellspring.name}</div>
            </ActivityLabel>
            <motion.img onClick={() => { window.open(currentWellspring.lightGG, '_blank'); }} whileHover={{scale: 1.1}} style={{ cursor: 'pointer', border: '1px solid red', height: '50px', width: '50px', margin: '10px 0'}} title={currentWellspring.name} src={currentWellspring.image} />    
        </ColumnItem>
        <ColumnItem style={{backgroundSize: 'cover', backgroundPosition: 'bottom', justifyContent: 'space-between', backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.25)),url("https://www.bungie.net/img/destiny_content/pgcr/neomuna_terminal_overload_harbor.jpg")',  alignItems: 'center', height: `${100/3}px`}}>
            <ActivityLabel>
                <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', fontSize: '0.9rem', whiteSpace: 'nowrap', borderBottom: '1px solid white'}}>Terminal Overload: {currentTerminalOverload.zone}</div>
                <div style={{fontSize: '1.1rem'}}>{currentTerminalOverload.name}</div>
            </ActivityLabel>
            <motion.img onClick={() => { window.open(currentTerminalOverload.lightGG, '_blank'); }} whileHover={{scale: 1.1}} style={{ cursor: 'pointer', border: '1px solid white', height: '50px', width: '50px', margin: '10px 0'}} title={currentTerminalOverload.name} src={currentTerminalOverload.image} />    
        </ColumnItem>
    </Flex>
}

const ActivityLabel = styled.div`
    overflow: hidden;
    // background-color: rgba(0,0,0,0.35);
    // backdrop-filter: blur(2px);
    border-radius: 4px;
    padding: 2px 8px;
`

type ColumnItemProps = {
    clickable?: boolean
}

const ColumnItem = styled.div<ColumnItemProps>`
    ${props => props.clickable && 'cursor: pointer;'}
    display: flex;
    padding: 4px 10px;
    flex: 1;
    overflow: hidden;
    :not(:last-child) { 
        border-bottom: 1px solid grey;
    }
`

export default DailyRotators