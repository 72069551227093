import React, { Component } from "react";
import * as THREE from "three";

class MinimalShapes extends Component {
  unmount = false;

  componentWillUnmount() {
    this.unmount = true;
  }

  componentDidMount() {
    const { unmount } = this;

    var scene = new THREE.Scene();
    var camera = new THREE.PerspectiveCamera( 25, window.innerWidth/window.innerHeight, 0.1, 1000 );
    var renderer = new THREE.WebGLRenderer({ alpha: true,  antialias: true });
    this.renderer = renderer;
    renderer.setSize(window.innerWidth, window.innerHeight)

    renderer.setClearColor( 0x101010, 1 );

    this.mount.appendChild( renderer.domElement );

    const getColor = (strength) => {
        const purple = [142, 31, 182]
        const blue = [22, 164, 207]
        const scaledColor = purple.map((p, i) => p - Math.floor((p - blue[i]) * strength))
        // console.log(`rgb(${scaledColor.toString()})`)
        return `rgb(${scaledColor.toString()})`
    }

    var ringMaterial = new THREE.PointsMaterial({ color: 0x8e1fb6, size: 0.2  });
    const hexagonCount = 250
    const gapSize = 40
    const hexagons = Array(hexagonCount).fill(1).map((item, index) => {
        const size = (Math.random() * 4) + 10
        const strength = 1 //Math.random()
        
        const material = new THREE.PointsMaterial( {color: getColor(Math.random()),  size: 0.2, } );
        // const geometry = new THREE.BoxGeometry(size,size,size)
        const extrudeSettings = {
            amount : 2,
            steps : 1,
            bevelEnabled: false,
            curveSegments: 8
        };
        
        const arcShape = new THREE.Shape();
        arcShape.absarc(0, 0, 10 + (strength * 10), 0, Math.PI * 2, 0, false);
        
        const holePath = new THREE.Path();
        holePath.absarc(0, 0, 8 + (strength * 10), 0, Math.PI * 2, true);
        arcShape.holes.push(holePath);
        
        const geometry = new THREE.ExtrudeGeometry(arcShape, extrudeSettings);
        const box = new THREE.Mesh( geometry, material )

        box.position.x = 0
        box.position.y = 0
        box.position.z = 20 + (-gapSize * index)

        // box.rotation.x = Math.random()
        // box.rotation.y = Math.random()
        // box.rotation.z = Math.random()

        box.castShadow = true;

        scene.add(box)
        
        return box
    })

    const ringParticleCount = 100;
    const ringGeometry = new THREE.BufferGeometry();
    this.ringPositions = new Float32Array(ringParticleCount * ringParticleCount * 3);
    for (let i = 0; i < ringParticleCount; i++) {
        for (let j = 0; j < ringParticleCount; j++) {
            this.ringPositions[((i * ringParticleCount) + j) * 3] = (Math.random() * 40) - 20//i - ((ringParticleCount - 1) / 2)
            this.ringPositions[(((i * ringParticleCount) + j) * 3)+1] = (Math.random() * 40) - 20//j - ((ringParticleCount - 1) / 2)
            this.ringPositions[(((i * ringParticleCount) + j) * 3)+2] = (Math.random() * 40) - 20//0
        }
    //   this.ringArray.push({ position: vector, number: i })
    }
    ringGeometry.setAttribute('position', new THREE.BufferAttribute(this.ringPositions, 3));
    const ringMesh = new THREE.Points(ringGeometry, ringMaterial)
    ringMesh.castShadow = true;
    ringMesh.rotateX(Math.PI / 2)
    // scene.add(ringMesh)

    camera.position.z = 100;
    // camera.position.y = 3;
    // camera.rotateX(-Math.PI / 24)
    camera.rotateZ(-Math.PI)
    // create a point light
    const pointLight =
    new THREE.PointLight(0xFFFFFF);

    // set its position
    pointLight.position.x = 10;
    pointLight.position.y = 50;
    pointLight.position.z = 1300;

    // add to the scene
    scene.add(pointLight);

    var animate = function () {
    if (unmount) {
      return;
    }

    setTimeout( function() {
      requestAnimationFrame( animate );
    }, 1000 / 70 );
    
        const furthest = Math.max(hexagons.map(hexagon => hexagon.position.z))
        hexagons.forEach((box, index) => {
            box.rotation.z += 0.01 * Math.pow(-1, index);
            box.position.z += 0.5
            if (box.position.z > 80) {
              box.position.z = furthest - gapSize
            }
            // box.position['x'] += (0.1 / (index % 9) * Math.pow(-1, (index % 2) + 1));
            // if (box.position.y > 60 || box.position.y < -60) {
            //     box.position.y *= -1
            // }
            // box.position['y'] += (0.1 / (index % 9) * Math.pow(-1, (index % 4) < 2));
            // if (box.position.z > 60 || box.position.z < -60) {
            //     box.position.z *= -1
            // }
            // box.position['z'] += (0.1 / (index % 9) * Math.pow(-1, (index % 2) + 1));
        })

      renderer.render( scene, camera );
    };

    const resize = () => {
      // Update camera aspect ratio
     camera.aspect = window.innerWidth / window.innerHeight;
     camera.updateProjectionMatrix();

     // Update renderer size
     renderer.setSize(window.innerWidth, window.innerHeight);
     // if (this.renderer){
       

     //   const canvas = this.renderer.domElement.current;
     //   // look up the size the canvas is being displayed
     //   const width = canvas.clientWidth;
     //   const height = canvas.clientHeight;
     
     //   // adjust displayBuffer size to match
     //   if (canvas.width !== width || canvas.height !== height) {
     //     // you must pass false here or three.js sadly fights the browser
     //     this.renderer.setSize(width, height, false);
     //     this.camera.aspect = width / height;
     //     this.camera.updateProjectionMatrix();
     
     //     // update any render target sizes here
     //   }
     // }
   }

   window.addEventListener('resize', resize, false)
   window.addEventListener('orientationchange', resize, false)

    animate(this.unmount);
  }

  render() {
    return (
      <div style={{ position: 'absolute', zIndex: 1, top: 0, left: 0 }} ref={ref => (this.mount = ref)} />
    )
  }
}

export default MinimalShapes;