import { Description } from "@mui/icons-material";
import { Class, } from "./characters";
import { Element } from "./element"
import LooksIcon from '@mui/icons-material/Looks';
import React from "react";

export enum Stat {
    Mobility='Mobility',
    Resilience='Resilience',
    Recovery='Recovery',
    Discipline='Discipline',
    Intellect='Intellect',
    Strength='Strength',
    Class='Class'
}

export enum ClassKey {
    Warlock='Warlock',
    Titan='Titan',
    Hunter='Hunter',
}

export const MovementAbility = {
    StrafeGlide: {
        name: 'Strafe Glide',
        class: ClassKey.Warlock,
        description: `Jump while airborne to activate Glide and start an airborne drift with strong directional control.`,
        icons: {
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/c066f355e320df124b360384e86aece6.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/4791b9df93b673dd1e67a132032b092e.jpg',
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/71a15700b61f44a8d96772850d481292.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/800f5b922e2215630b83d4a7cdc8cc64.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/800f5b922e2215630b83d4a7cdc8cc64.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/800f5b922e2215630b83d4a7cdc8cc64.png',
        }
    },
    BurstGlide: {
        name: 'Burst Glide',
        class: ClassKey.Warlock,
        description: `Jump while airborne to activate Glide and start an airborne drift with a strong initial boost of speed.`,
        icons: {
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/83a6090aa638a9d756d5ef03e48b7843.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/f7b19afecf6554f32225e80cc57d4fac.jpg',
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/88d1827b19c92ccc9c6aea0e62369f24.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/63af5e661089e6a1abbbe70186573a50.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/63af5e661089e6a1abbbe70186573a50.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/63af5e661089e6a1abbbe70186573a50.png',
        }
    },
    BalancedGlide: {
        name: 'Balanced Glide',
        class: ClassKey.Warlock,
        description: `Jump while airborne to activate Glide and start an airborne drift with both moderate speed and directional control.`,
        icons: {
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/80478b4a287cee04d75599c7fab4e47e.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/54787094e4be70a56fd1ccabb68f878f.jpg',
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/49cfe74194450ed0a6d78fe12cb5e781.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/e2fe3d4207f35f35ad532e8798897f62.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/e2fe3d4207f35f35ad532e8798897f62.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/e2fe3d4207f35f35ad532e8798897f62.png',
        }
    },
    WarlockBlink: {
        name: 'Blink',
        class: ClassKey.Warlock,
        description: `Jump while airborne to teleport a short distance.`,
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6930fabff4175da3425bfe9e1c4b2c54.jpg'
    },
    HighJump: {
        name: 'High Jump',
        class: ClassKey.Hunter,
        description: ``,
        icons: {
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/1c99afbdead82ec1defcbed010ce07db.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/dc0fd818a2524460a372e0ecf6799237.jpg',
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/27879b83074e70dab436973f45b83cac.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/3321f82be875774d96615fb2242079b5.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/3321f82be875774d96615fb2242079b5.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/3321f82be875774d96615fb2242079b5.png',
        }
    },
    StrafeJump: {
        name: 'Strafe Jump',
        class: ClassKey.Hunter,
        description: ``,
        icons: {
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/43b8b5a84ec3bbdc78fb83427f743962.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/66fcf6f378ae895674ea601637166d28.jpg',
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/1e236c74f7d5193df3472765245ca55c.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/1eeb42a2f303c00ded47299ca91aa72f.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/1eeb42a2f303c00ded47299ca91aa72f.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/1eeb42a2f303c00ded47299ca91aa72f.png',
        }
    },
    TripleJump: {
        name: 'Triple Jump',
        class: ClassKey.Hunter,
        description: ``,
        icons: {
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/fb5c90f3df4b9e1d4dede6d807f34ed6.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/e919562f619672aae70ede8fc29e9bad.jpg',
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/733d623b28e7c56d2e1aa4f5d9aae685.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/7d5eb73f61509271037f810480a2e804.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/7d5eb73f61509271037f810480a2e804.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/7d5eb73f61509271037f810480a2e804.png',
        }
    },
    HunterBlink: {
        name: 'Blink',
        class: ClassKey.Hunter,
        description: `Jump while airborne to teleport a short distance.`,
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/df3f7482cf7667181369db14b7115f38.jpg'
    },
    HighLift: {
        name: 'High Lift',
        class: ClassKey.Titan,
        description: `Jump while airborne to activate Lift and launch into the air to greater heights.`,
        icons: {
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/07116cfce5a59222d658729cffb0f29c.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/8c5e43c387cef35435b121f422eaf0c6.jpg',
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/22e574350e9146bd8f5a46772c7db929.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/f19a22d03d53694cb927e11e82899dca.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/f19a22d03d53694cb927e11e82899dca.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/f19a22d03d53694cb927e11e82899dca.png',
        }
    },
    StrafeLift: {
        name: 'Strafe Lift',
        class: ClassKey.Titan,
        description: `Jump while airborne to activate Lift and launch into the air with strong directional control.`,
        icons: {
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/78e14c7fa1b842f2ffc9bdaa24aecc70.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/056b7f222c704d759b793d9202cad169.jpg',
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/212aa3862f79fdc9befc4b2c45df7492.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/416f132a08d1c15a57adc7c528c17ce5.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/416f132a08d1c15a57adc7c528c17ce5.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/416f132a08d1c15a57adc7c528c17ce5.png',
        }
    },
    CatapultLift: {
        name: 'Catapult Lift',
        class: ClassKey.Titan,
        description: `Jump while airborne to activate Lift and launch into the air with a strong initial burst of momentum.`,
        icons: {
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/32bfe820d982e00fac25c9aab277ebc3.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/837b14251d5b776849065059c6594a75.jpg',
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/b00e7edf76ae296eee2cca55c3a57fa6.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/22167767b81dea77781871eeb1e7d3de.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/22167767b81dea77781871eeb1e7d3de.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/22167767b81dea77781871eeb1e7d3de.png',
        }
    },
}

export const Fragment = {
    SparkOfHaste: {
        name: 'Spark of Haste',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/e840532ca79e23311804760b3833e6bb.jpg',
        description: `You have greatly increased resilience, recovery, and mobility while sprinting.`
    },
    SparkOfInstinct: {
        name: 'Spark of Instinct',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/eb9c8ec91e56b1410457aecae09ceeed.jpg',
        description: `While critically wounded, taking damage emits a burst of damaging Arc energy that jolts targets.`
    },
    SparkOfBeacons: {
        name: 'Spark of Beacons',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/8924af183dc3e6200536171a72c9fd77.jpg',
        description: `While you are amplified, your Arc Special weapon final blows create a blinding explosion.`
    },
    SparkOfResistance: {
        name: 'Spark of Resistance',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/10b793e347263d81cc0404acf91aadc7.jpg',
        stats: { [Stat.Strength]: 10 },
        description: `While surrounded by combatants, you are more resistant to incoming damage.`
    },
    SparkOfMomentum: {
        name: 'Spark of Momentum',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/b8cfaccdc40ccdd6a3fb4f544dcb7700.jpg',
        description: `Sliding over ammo bricks reloads your equipped weapon and grants a small amount of melee energy.`
    },
    SparkOfShock: {
        name: 'Spark of Shock',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2f49889b1fe7bcf01ef12b2cdc0fb511.jpg',
        stats: { [Stat.Discipline]: -10 },
        description: `Your Arc grenades jolt targets.`
    },
    SparkOfIons: {
        name: 'Spark of Ions',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2a7f5d325fc745877be70c443427d15b.jpg',
        description: `Defeating a jolted target creates an Ionic Trace.`
    },
    SparkOfDischarge: {
        name: 'Spark of Discharge',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/c883bf91f42e9c4b9c9ddce1ba2d2de5.jpg',
        stats: { [Stat.Strength]: -10 },
        description: `Arc weapon final blows have a chance to create an Ionic Trace.`
    },
    SparkOfFrequency: {
        name: 'Spark of Frequency',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/4979f2f84c3da353c19815106267beb9.jpg',
        description: `Melee hits greatly increase your reload speed for a short duration.`
    },
    SparkOfFocus: {
        name: 'Spark of Focus',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/cfcdcc8f7d07111f6079ad8f869273c6.jpg',
        stats: { [Stat.Class]: -10 },
        description: `After sprinting for a short time, your class ability regeneration is increased.`
    },
    SparkOfRecharge: {
        name: 'Spark of Recharge',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/10b793e347263d81cc0404acf91aadc7.jpg',
        description: `While critically wounded, your melee and grenade energy regenerates more quickly.`
    },
    SparkOfMagnitude: {
        name: 'Spark of Magnitude',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/0b39167c8fc628482dea26cf43d78ec9.jpg',
        description: `Your lingering Arc grenades (Lightning Grenade, Pulse Grenade, and Storm Grenade) have extended duration.`
    },
    SparkOfAmplitude: {
        name: 'Spark of Amplitude',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/4138dfce109db20c49877c08852f13a8.jpg',
        description: `Rapidly defeating targets while you are amplified creates an Orb of Power.`
    },
    SparkOfFeedback: {
        name: 'Spark of Feedback',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/58a935c2948d5f20d060bc87a0ad25d2.jpg',
        stats: { [Stat.Resilience]: 10 },
        description: `Taking melee damage briefly increases your outgoing melee damage.`
    },
    SparkOfVolts: {
        name: 'Spark of Volts',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/bb6175746eecf3160a591efe343a3fdf.jpg',
        stats: { [Stat.Recovery]: 10 },
        description: `Finishers make you amplified.`
    },
    SparkOfBrilliance: {
        name: 'Spark of Brilliance',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d38a4297cfe3ec89427f68ef92b076e5.jpg',
        stats: { [Stat.Intellect]: 10 },
        description: `Defeating a blinded target with precision damage creates a blinding explosion.`
    },
    EmberOfMercy: {
        name: 'Ember of Mercy',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5ca8c8de03f981b9c984a1f2bdea0f61.jpg',
        stats: { [Stat.Resilience]: 10 },
        description: `When you revive an ally, you and other nearby allies gain restoration. Picking up a Firesprite grants restoration.`
    },
    EmberOfResolve: {
        name: 'Ember of Resolve',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/24b60501785856e2898417115e3b2afd.jpg',
        description: `Solar grenade final blows cure you.`
    },
    EmberOfSingeing: {
        name: 'Ember of Singeing',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/c9e392abb5417ecab2dccd85fe23c00f.jpg',
        description: `Your class ability recharges faster when you scorch targets.`
    },
    EmberOfBenevolence: {
        name: 'Ember of Benevolence',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/0b5cf537c6ad5d80cbdd3675d0e7134d.jpg',
        stats: { [Stat.Discipline]: -10 },
        description: `Applying restoration, cure, or radiant to allies grants increased grenade, melee, and class ability regeneration for a short duration.`
    },
    EmberOfBeams: {
        name: 'Ember of Beams',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2f12ba3df56de7c0e2790f481cb29a52.jpg',
        stats: { [Stat.Intellect]: 10 },
        description: `Your Solar Super projectiles have stronger target acquisition.`
    },
    EmberOfEmpyrean: {
        name: 'Ember of Empyrean',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/be99d52c12f9359fc948b4563f74e712.jpg',
        stats: { [Stat.Resilience]: -10 },
        description: `Solar weapon or ability final blows extend the duration of restoration and radiant effects applied to you.`
        
    },
    EmberOfCombustion: {
        name: 'Ember of Combustion',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/45476d85d0e6aeded810f217a0627afb.jpg',
        stats: { [Stat.Strength]: 10 },
        description: `Final blows with a Solar Super causes targets to ignite and creates a Firesprite.`
    },
    EmberOfTorches: {
        name: 'Ember of Torches',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/1ef2e34dad0d52c762ed96e8c932dc38.jpg',
        stats: { [Stat.Discipline]: -10 },
        description: `Powered melee attacks against combatants make you and nearby allies radiant.`
    },
    EmberOfChar: {
        name: 'Ember of Char',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a299dde35bfcd830923458846d7a64f3.jpg',
        stats: { [Stat.Discipline]: 10 },
        description: `Your Solar ignitions spread scorch to affected targets.`
    },
    EmberOfTempering: {
        name: 'Ember of Tempering',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/cddc93648f0917dc8bd6663d38d7c379.jpg',
        stats: { [Stat.Recovery]: -10 },
        description: `Solar weapon final blows grant you and your allies increased recovery for a short duration. Stacks 3 times. While Ember of Tempering is active, your weapons have increased airborne effectiveness, and your Solar weapon final blows create a Firesprite.`
    },
    EmberOfBlistering: {
        name: 'Ember of Blistering',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/52dc6ef9a4b0642e36551542b3a2936e.jpg',
        description: `Defeating targets with Solar ignitions grants grenade energy.`
    },
    EmberOfSolace: {
        name: 'Ember of Solace',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/fb46711e0dff2bc2f55c21271e838fe2.jpg',
        description: `Radiant and restoration effects applied to you have increased duration.`
    },
    EmberOfEruption: {
        name: 'Ember of Eruption',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/8734774377b5e73a84ed045a78ce232c.jpg',
        stats: { [Stat.Strength]: 10 },
        description: `Your Solar ignitions have increased area of effect.`
    },
    EmberOfAshes: {
        name: 'Ember of Ashes',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/82203cd4545f6dccc3b231d138664ecd.jpg',
        description: `You apply more scorch stacks to targets.`
    },
    EmberOfWonder: {
        name: 'Ember of Wonder',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/9de7766c9c9b56b75bde1054e3eefb1a.jpg',
        stats: { [Stat.Resilience]: 10 },
        description: `Rapidly defeating multiple targets with Solar ignitions generates an Orb of Power.`
    },
    EmberOfSearing: {
        name: 'Ember of Searing',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/7312346d93dc0e84d46e539a10aebb52.jpg',
        stats: { [Stat.Recovery]: 10 },
        description: `Defeating scorched targets grants melee energy and creates a Firesprite.`,
    },
    EchoOfVigilance: {
        name: 'Echo of Vigilance',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/91e0f86be9e41faf06994faf41d818af.jpg',
        stats: { [Stat.Recovery]: -10 },
        description: `Defeating a target while your shields are depleted grants you a temporary Void overshield.`
    },
    EchoOfCessation: {
        name: 'Echo of Cessation',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/87d29fbf5c877e2a385354ae76a09f06.jpg',
        description: `Finisher final blows create a burst of Void damage that causes nearby combatants to become volatile.

        Defeating volatile targets creates a Void Breach.`
    },
    EchoOfExpulsion: {
        name: 'Echo of Expulsion',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d6500235bb175f0fc3752cab0a170fd2.jpg',
        stats: { [Stat.Intellect]: 10 },
        description: `Void ability final blows cause targets to explode.`
    },
    EchoOfProvision: {
        name: 'Echo of Provision',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/1c16b5205d6a648b9898cce6ac3a01b3.jpg',
        stats: { [Stat.Strength]: -10 },
        description: `Damaging targets with grenades grants melee energy.`
    },
    EchoOfExchange: {
        name: 'Echo of Exchange',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/e5a6ac0f38df212a40dc541bb46f354f.jpg',
        description: `Melee final blows grant grenade energy.`
    },
    EchoOfRemnants: {
        name: 'Echo of Remnants',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/ce12bd0f246e834c8f7e102079814bf9.jpg',
        description: `Your lingering grenade effects (Vortex Grenade, Void Wall, Void Spike, and Axion Bolt) have increased duration.`
    },
    EchoOfReprisal: {
        name: 'Echo of Reprisal',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6a0118280ba432e796048648993d7765.jpg',
        description: `Final blows when surrounded by combatants grant Super energy.`
    },
    EchoOfUndermining: {
        name: 'Echo of Undermining',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/b114e9d97c42a68b19ab7876a221b354.jpg',
        stats: { [Stat.Discipline]: -20 },
        description: `Your Void grenades weaken targets.`
    },
    EchoOfPersistence: {
        name: 'Echo of Persistence',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/914309029085289921f77d8207765150.jpg',
        stats: { [Stat.Class]: -10 },
        description: `Void buffs applied to you (Invisibility, Overshield, and Devour) have increased duration.`
    },
    EchoOfLeeching: {
        name: 'Echo of Leeching',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6aa22ca5ba309f264af5231969ec840a.jpg',
        stats: { [Stat.Resilience]: 10 },
        description: `Melee final blows start health regeneration for you and nearby allies.`
    },
    EchoOfDomineering: {
        name: 'Echo of Domineering',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/958ff340ae4ce16d7cf71c5268a13919.jpg',
        stats: { [Stat.Discipline]: 10 },
        description: `After suppressing a target, you gain greatly increased mobility for a short duration, and your equipped weapon is reloaded from reserves. Defeating suppressed targets creates a Void Breach.`
    },
    EchoOfDilation: {
        name: 'Echo of Dilation',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/029faa3dc0d82eefef581dec7820d643.jpg',
        stats: { [Stat.Mobility]: 10, [Stat.Intellect]: 10 },
        description: `While crouched, you sneak faster and gain enhanced radar resolution.`
    },
    EchoOfInstability: {
        name: 'Echo of Instability',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/0ad46f9c0c14535c4d5776daf48e871e.jpg',
        stats: { [Stat.Strength]: 10 },
        description: `Defeating targets with grenades grants Volatile Rounds to your Void weapons.`
    },
    EchoOfHarvest: {
        name: 'Echo of Harvest',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6bd23524f7129761043724acbe90c7b5.jpg',
        stats: { [Stat.Intellect]: -10 },
        description: `Defeating weakened targets creates an Orb of Power and a Void Breach.`
    },
    EchoOfObscurity: {
        name: 'Echo of Obscurity',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/7d711ce4bcfb264da29c289ff70b9876.jpg',
        stats: { [Stat.Recovery]: 10 },
        description: `Finisher final blows grant Invisibility.`
    },
    EchoOfStarvation: {
        name: 'Echo of Starvation',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/19219ecd56fef82e9ead65aed8fea63a.jpg',
        stats: { [Stat.Recovery]: -10 },
        description: `Picking up a Void Breach or an Orb of Power grants Devour.`
    },
    WhisperOfHedrons: {
        name: 'Whisper of Hedrons',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/70b60b70634c6e539a531d47e58e1b9f.png',
        description: `Dramatically increases weapon stability, weapon aim assist, weapon airborne effectiveness, mobility, resilience, and recovery after freezing a target with [Stasis] Stasis.`
    },
    WhisperOfFissures: {
        name: 'Whisper of Fissures',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/9eaa93107ad40372c335ce495273c318.png',
        description: `Increases the damage and size of the burst of [Stasis] Stasis when you destroy a [Stasis] Stasis crystal or defeat a frozen target.`
    },
    WhisperOfRefraction: {
        name: 'Whisper of Refraction',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5c9285f7b0320f56a560ce9c0aa65043.png',
        description: `Defeating slowed or frozen targets grants you class ability energy.`
    },
    WhisperOfDurance: {
        name: 'Whisper of Durance',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/263713a8639fb73350c13b5b520fefa2.png',
        stats: { [Stat.Strength]: 10 },
        description: `Slow that you apply to targets lasts longer. For those abilities that linger, their duration will also increase.`
    },
    WhisperOfBonds: {
        name: 'Whisper of Bonds',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/12b591b2720cc265d800e870484f6d5b.png',
        stats: { [Stat.Intellect]: 10 },
        description: `Defeating frozen targets generates an Orb of Power.`
    },
    WhisperOfShards: {
        name: 'Whisper of Shards',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5fa98c6e62d008621119d1b394e9cae9.png',
        description: `Shattering a [Stasis] Stasis crystal temporarily boosts your grenade recharge rate. Shattering additional [Stasis] Stasis crystals increases the duration of this benefit.`
    },
    WhisperOfChains: {
        name: 'Whisper of Chains',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d764e09a79be71fb5d37e612e610cf18.png',
        stats: { [Stat.Recovery]: 10 },
        description: `While you are near frozen targets or a friendly [Stasis] Stasis crystal, you take reduced damage from targets.`
    },
    WhisperOfTorment: {
        name: 'Whisper of Torment',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/0f69591331f4def8ab2a4bb27c55b2aa.png',
        description: `You gain grenade energy each time you take damage from targets.`
    },
    WhisperOfFractures: {
        name: 'Whisper of Fractures',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a28274406a8a0e7ec916a33ec830ba6f.png',
        stats: { [Stat.Discipline]: -10 },
        description: `Your melee energy recharges faster when you are near two or more targets.`
    },
    WhisperOfImpetus: {
        name: 'Whisper of Impetus',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/710c5e3aec26f0e3e468c656a2669e0d.png',
        stats: { [Stat.Resilience]: 10 },
        description: `Damaging targets with a [Stasis] Stasis melee reloads your stowed weapons and grants you a temporary boost to weapon ready speed.`
    },
    WhisperOfConduction: {
        name: 'Whisper of Conduction',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2d84a595d269762c434718e34d2e7d78.png',
        stats: { [Stat.Resilience]: 10, [Stat.Intellect]: 10 },
        description: `Nearby [Stasis] Stasis shards track to your position.`
    },
    WhisperOfRending: {
        name: 'Whisper of Rending',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a315a6aaba35eba7f021fbea22e5e0ba.png',
        description: `Primary ammo weapons do increased damage to [Stasis] Stasis crystals and frozen targets.`
    },
    WhisperOfHunger: {
        name: 'Whisper of Hunger',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/549368f903ac85dc177a56555ce69ae7.png',
        stats: { [Stat.Strength]: -20 },
        description: `Increases the melee energy gained from picking up [Stasis] Stasis shards.`
    },
    WhisperOfRime: {
        name: 'Whisper of Rime',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/0ec49820a6de05851c099cf7fb5d7554.png',
        description: `Collecting a [Stasis] Stasis shard grants a small amount of overshield, which falls off after 10 seconds. Collecting additional shards adds to the overshield and refreshes the timer.`
    },
    ThreadOfMind: {
        name: 'Thread of Mind',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a5d4a60d922293ebdbea1c22cbd93986.jpg',
        description: `Defeating suspended targets grants class ability energy.`
    },
    ThreadOfFury: {
        name: 'Thread of Fury',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5cc5657e9653cf793cde419236c3a499.jpg',
        stats: { [Stat.Strength]: -10 },
        description: `Damaging targets with a Tangle grants melee energy.`
    },
    ThreadOfAscent: {
        name: 'Thread of Ascent',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/17f17fe5573dfc1cce1ae688e9e8a873.jpg',
        stats: { [Stat.Mobility]: 10 },
        description: `Activating your grenade ability reloads your equipped weapon and grants bonus airborne effectiveness and handling for a short duration.`
    },
    ThreadOfFinality: {
        name: 'Thread of Finality',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5288badd31e7067d1af71a061aaff22b.jpg',
        stats: { [Stat.Recovery]: 10 },
        description: `Finisher final blows create Threadlings.`
    },
    ThreadOfWarding: {
        name: 'Thread of Warding',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/feffa1ea71e0317462b406807a9d8b45.jpg',
        stats: { [Stat.Resilience]: -10 },
        description: `Picking up an Orb of Power grants Woven Mail.`
    },
    ThreadOfWisdom: {
        name: 'Thread of Wisdom',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6f5d43447ee7de28bb94ac949f3fac44.jpg',
        description: `Defeating suspended targets creates an Orb of Power.`
    },
    ThreadOfRebirth: {
        name: 'Thread of Rebirth',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/00afc79d1bd165623c9e506bec1a0ff3.jpg',
        description: `[Strand] Strand weapon final blows have a chance to create a Threadling.`
    },
    ThreadOfTransmutation: {
        name: 'Thread of Transmutation',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/ac3a190922c806b770c4a84366476201.jpg',
        stats: { [Stat.Strength]: 10 },
        description: `While you have Woven Mail, weapon final blows create a Tangle.`
    },
    ThreadOfPropagation: {
        name: 'Thread of Propagation',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/4b78910e8b8b684d7dfca716eaea4616.jpg',
        stats: { [Stat.Strength]: 10 },
        description: `Powered melee final blows grant your [Strand] Strand weapons Unraveling Rounds.`
    },
    ThreadOfEvolution: {
        name: 'Thread of Evolution',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/badf77b1f1eeede2739b75bb3a817be0.jpg',
        stats: { [Stat.Intellect]: 10 },
        description: `Threadlings travel farther and deal additional damage.`
    },
    ThreadOfIsolation: {
        name: 'Thread of Isolation',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6d5c07db3f3b268d2cdcad206a8d4308.jpg',
        description: `Landing rapid precision hits emits a severing burst from the target.`
    },
    ThreadOfBinding: {
        name: 'Thread of Binding',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/42f10e9d82c7c2f364856fbef56b6354.jpg',
        stats: { [Stat.Resilience]: 10 },
        description: `Super final blows emit a suspending burst from the target.`
    },
    ThreadOfGeneration: {
        name: 'Thread of Generation',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/f3d9e926cd9b18f7b68ecb49dcadefc4.jpg',
        stats: { [Stat.Discipline]: -10 },
        description: `Dealing damage generates grenade energy.`
    },
    ThreadOfContinuity: {
        name: 'Thread of Continuity',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2d629d0adbf1d1cab255f087caaafeae.jpg',
        description: `Suspend, unravel, and sever effects applied to targets have increased duration.`
    },
    FacetOfBalance: {
        name: 'Facet of Balance',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `Rapidly defeating targets with Light damage grants melee energy. Rapidly defeating targets with Dark damage grants grenade energy.`
    },
    FacetOfBravery: {
        name: 'Facet of Bravery',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `Defeating targets with grenades grants Volatile Rounds to your Void weapons. Defeating targets with powered melee final blows grants Unraveling Rounds to your Strand weapons.`
    },
    FacetOfDawn: {
        name: 'Facet of Dawn',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `Powered melee hits against targets make you Radiant. Powered melee final blows make both you and nearby allies Radiant.`
    },
    FacetOfDefiance: {
        name: 'Facet of Defiance',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `Finishers create a detonation that either Jolts, Scorches, Slows, Severs, or makes targets Volatile, based on the damage type of your equipped super.`
    },
    FacetOfDominance: {
        name: 'Facet of Dominance',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `Your Void grenades Weaken, and Arc grenades Jolt targets.`
    },
    FacetOfGenerosity: {
        name: 'Facet of Generosity',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `Defeating targets while Transcendent creates Orbs of Power for your allies.`
    },
    FacetOfGrace: {
        name: 'Facet of Grace',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `Damaging targets with Kinetic weapons grants you bonus Transcendence energy. Defeating targets with your Super grants you and nearby allies bonus Transcendence energy.`
    },
    FacetOfHope: {
        name: 'Facet of Hope',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `While you have an elemental buff, your class ability regenerates more quickly.`
    },
    FacetOfJustice: {
        name: 'Facet of Justice',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `While Transcendent, your ability final blows explode.`
    },
    FacetOfProtection: {
        name: 'Facet of Protection',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `While surrounded by enemies, you are more resistant to incoming damage.`
    },
    FacetOfPurpose: {
        name: 'Facet of Purpose',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `Picking up an Orb of Power grants either Amplified, Restoration, Frost Armor, Woven Mail, or Overshield, based on the damage type of your equipped super.`
    },
    FacetOfRuin: {
        name: 'Facet of Ruin',
        element: Element.Prismatic,
        icon: <LooksIcon style={{width: '100%', height: "auto"}}/>,
        description: `This increases the size and damage of the burst when you shatter a Stasis Crystal or Frozen target and increases the size of Solar Ignitions.`
    },
}

export const Aspect = {
    ArcSoul: {
        name: 'Arc Soul',
        element: Element.Arc,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/004c3c7aacba971cbee88f9b5098b53f.jpg',
        description: `Cast your Rift to create an Arc Soul that fires at targets in front of you. Allies can pass through your Rift to get an Arc Soul. Your Rift charges faster when allies are near.

        While amplified, your Arc Souls are supercharged and gain increased fire rate.`
    },
    LightningSurge: {
        name: 'Lightning Surge',
        element: Element.Arc,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6b6c073fcda69ee79b095af430692f9a.jpg',
        description: `While sliding, activate your charged melee ability to blink forward, calling down lightning strikes that jolt targets as you rematerialize.`
    },
    ElectrostaticMind: {
        name: 'Electrostatic Mind',
        element: Element.Arc,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/19cd504321b8b05ce3549c148176af9b.jpg',
        description: `Defeating targets with Arc abilities or defeating jolted or blinded targets creates an Ionic Trace.

        Collecting an Ionic Trace makes you amplified.`
    },
    FlowState: {
        name: 'Flow State',
        element: Element.Arc,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/7a06c8d2240676ebe3d9479bca280375.jpg',
        description: `Defeating a jolted target makes you amplified.

        While you are amplified, your dodge recharges more quickly, you are more resilient while dodging, and your reload speed is greatly increased.`
    },
    TempestStrike: {
        name: 'Tempest Strike',
        element: Element.Arc,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d266f93e125acfac0892067bfbf760e5.jpg',
        description: `While sliding, activate your charged melee ability to unleash a devastating uppercut attack that travels along the ground in front of you, damaging and jolting targets it hits.`
    },
    LethalCurrent: {
        name: 'Lethal Current',
        element: Element.Arc,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6a3e6a42ba1ebbd55714f64f26e0d63e.jpg',
        description: `After dodging, your next melee attack has increased lunge range, jolts the target, and creates a damaging aftershock.

        Damaging any jolted target with melee attacks also blinds them.`
    },
    Ascension: {
        name: 'Ascension',
        element: Element.Arc,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6a3e6a42ba1ebbd55714f64f26e0d63e.jpg',
        description: ``
    },
    TouchOfThunder: {
        name: 'Touch of Thunder',
        element: Element.Arc,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/eb978c97cca38a1aadad68321a5dc479.jpg',
        description: `Your Flashbang, Pulse, Lightning, and Storm Grenades have enhanced functionality.

        Flashbang Grenade: Emits an additional blinding flash on bounce.
        
        Pulse Grenade: Creates Ionic Traces periodically as it damages targets and increases in damage over time.
        
        Lightning Grenade: Grants an additional grenade charge, and jolts targets hit by the initial blast.
        
        Storm Grenade: After detonating, creates a roaming thundercloud that tracks nearby targets and fires bolts of lightning at foes beneath it.`
    },
    Juggernaut: {
        name: 'Juggernaut',
        element: Element.Arc,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/263cec426c20726ab9803ea0657092d5.jpg',
        description: `With full class ability energy and after sprinting for a short time, you gain a frontal shield that blocks incoming damage. When the frontal shield breaks, your class ability energy is depleted.

        While amplified, the shield blocks significantly more damage before breaking.`
    },
    Knockout: {
        name: 'Knockout',
        element: Element.Arc,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/3af3d284f768983bf44d9427f3286226.jpg',
        description: `Critically wounding a target or breaking their shield infuses your melee attacks with Arc energy and increases your melee range and damage for a short time.

        Defeating targets with melee attacks starts health regeneration and makes you amplified.`
    },
    IcarusDash: {
        name: 'Icarus Dash',
        element: Element.Solar,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/55a690bbd9cd53777df674a279422865.jpg',
        description: `[Air Dodge] : Dodge quickly while airborne.

        While Heat Rises is active, you have an additional dodge.
        
        While airborne, rapidly defeating targets with your Super or weapons cures you.`
    },
    HeatRises: {
        name: 'Heat Rises',
        element: Element.Solar,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d647fd2019707f044ce97b52f802e465.jpg',
        description: `You can fire weapons, melee, and throw grenades while gliding.

        [Grenade] : Hold to consume your grenade, gaining Heat Rises and releasing a burst of healing energy that cures nearby allies. The strength of the burst is increased when a Healing Grenade is consumed.
        
        Final blows while airborne increase the duration of Heat Rises and grant melee energy.`
    },
    TouchOfFlame: {
        name: 'Touch of Flame',
        element: Element.Solar,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d6b44fe69ff876449e732b52b7d9d334.jpg',
        description: `Your Healing, Solar, Firebolt, and Fusion Grenades have enhanced functionality.

        Healing Grenade: Improves the strength of cure and restoration effects applied. Consuming a Healing Grenade with Heat Rises also applies restoration to nearby allies.
        
        Solar Grenade: Increases linger duration. Periodically emits blobs of lava around its perimeter.
        
        Firebolt Grenade: Increases target search radius and maximum target count.
        
        Fusion Grenade: Explodes twice.`
    },
    Hellion: {
        name: 'Hellion',
        element: Element.Solar,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d6b44fe69ff876449e732b52b7d9d334.jpg',
        description: ``
    },
    KnockEmDown: {
        name: "Knock 'Em Down",
        element: Element.Solar,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/01a74478066a61232e0eb0d71d56d89c.jpg',
        description: `Your Solar Supers are enhanced. Deadshot Golden Gun has damage resistance, Marksman Golden Gun has increased duration, and Blade Barrage launches more projectiles.

        While radiant, final blows with your equipped Throwing Knife fully refund your melee energy.`
    },
    OnYourMark: {
        name: 'On Your Mark',
        element: Element.Solar,
        class: ClassKey.Hunter,
        fragmentSlots: 3,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/6b1ce964c0d1dc6beba3083353efc3f6.jpg',
        description: `Precision final blows grant you and nearby allies increased weapon handling and reload speed for a short duration. Stacks 3 times.

        Activating your class ability immediately grants maximum stacks of On Your Mark.`
    },
    GunpowderGamble: {
        name: 'Gunpowder Gamble',
        element: Element.Solar,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/765402ea0cdaa799f62fe46b57ad8437.jpg',
        description: `Defeat targets with Solar abilities, Solar debuffs, or Solar weapons to charge up an improvised Solar explosive.

        [Grenade] : Throw a Solar explosive that can be shot in mid-air to cause an ignition.`
    },
    RoaringFlames: {
        name: 'Roaring Flames',
        element: Element.Solar,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/208e4d0d635832dd78288de5532fe062.jpg',
        description: `Final blows with [Solar] Solar abilities or ignitions increase the damage of your Solar abilities. Stacks 3 times.`
    },
    SolInvictus: {
        name: 'Sol Invictus',
        element: Element.Solar,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/1552df4d776b01d9f8c00d6c910aec97.jpg',
        description: `Solar ability final blows, Hammer of Sol impacts, and defeating scorched targets creates Sunspots. Your abilities regenerate faster, and your Super drains more slowly while standing in a Sunspot.

        Sunspots apply scorch and deal damage to targets inside. Entering a Sunspot applies restoration.`
    },
    Consecration: {
        name: 'Consecration',
        element: Element.Solar,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5c71b63e8b653cd63a2ae0d8a8c64979.jpg',
        description: `While sliding, activate your charged melee ability to launch a wave of [Solar] Solar energy forward, damaging and scorching targets in front of you as you leap into the air.

        While airborne, activate your charged melee again to slam to the ground and create a second larger wave of damaging [Solar] Solar energy. If the wave hits a scorched target, they ignite.`
    },
    ChaosAccelerant: {
        name: 'Chaos Accelerant',
        element: Element.Void,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5a8a03d4876d34a89db6aedf6189ab86.jpg',
        description: `[Grenade] : Hold to overcharge your grenade, making it deadlier and more effective.

        Vortex Grenade: Increases the size and linger duration of the vortex.
        
        Axion Bolt: Creates an additional seeker.
        
        Scatter Grenade: Has submunitions track to nearby targets.
        
        Magnetic Grenade: Releases a short-range Void blast.`
    },
    FeedTheVoid: {
        name: 'Feed the Void',
        element: Element.Void,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/178bb0e78e55c5b960aa6f42660b9b66.jpg',
        description: `Defeat a target with a Void ability to activate Devour.

        Your Devour effects are improved, restoring additional health and grenade energy.`
    },
    ChildOfTheOldGods: {
        name: 'Child of the Old Gods',
        element: Element.Void,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/ced6965ddcda23fdc728639aaf0ebd4d.jpg',
        description: `Cast your rift to create a Void Soul. When you damage a target with a weapon, your Void Soul flies to them and drains them, doing damage and weakening them.

        When a target is being drained, you are granted grenade and melee energy (if running healing rift) or health (if running empowering rift).
        
        Defeating a target who is being drained by your Void Soul grants class ability energy.`
    },
    TrappersAmbush: {
        name: "Trapper's Ambush",
        element: Element.Void,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/e91760df2b81d191da9e2c62cb3fcda7.jpg',
        description: `Activate Quickfall to spend your melee charge and dive to the ground, creating a large smoke cloud on impact, which then dissipates. Targets caught in the cloud are weakened, and allies are made invisible.

        Your Smoke Bomb makes nearby allies invisible when it attaches to nearby surfaces or targets.
        The eyes which bear down upon you from a place you cannot see. Ensnare your enemies before they can see you coming.`
    },
    VanishingStep: {
        name: 'Vanishing Step',
        element: Element.Void,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/f23323d11f0ad79781f91c70f4c644ac.jpg',
        description: `Dodging makes you invisible.
        The wiles of a seasoned interlocutor; dodge any unwanted affronts and slip by unseen.`
    },
    StylishExecutioner: {
        name: 'Stylish Executioner',
        element: Element.Void,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/ed7f8c49b77fa46f4eec87a3c167c4b1.jpg',
        description: `Defeating a weakened, suppressed, or volatile target grants Invisibility and Truesight.

        After performing a Stylish Execution, your next melee attack while invisible weakens targets.
        The boast which sucks the air from the room. Let your enemies know your might before you take them down.`
    },
    ControlledDemolition: {
        name: 'Controlled Demolition',
        element: Element.Void,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/ab2545998f350de7b29549d2b19996e2.jpg',
        description: `Hitting a target with a Void ability or Volatile explosion makes them volatile. Further damage to a volatile target causes them to explode. Grants you and nearby allies health when volatile targets explode near you.
        The seed of the unknown, which sprouts into yawning absence. Sustain your allies with the destruction of those who oppose you.`
    },
    Bastion: {
        name: 'Bastion',
        element: Element.Void,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5428b311ff07d2bacb073e551ed2bea1.jpg',
        description: `Cast your Super to grant Overshield to nearby allies.

        Casting your Barricade grants Overshield to yourself and nearby allies and empowers it, enabling it to slowly regenerate the Overshield of allies bunkering behind it and extend their Overshield duration.
        Food for the schism between allies and enemies. Protect those close to you with a barrier of distilled time.`
    },
    OffensiveBulwark: {
        name: 'Offensive Bulwark',
        element: Element.Void,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/68683d4544848844a1fe0acdf7417019.jpg',
        description: `While you have an Overshield or are inside Ward of Dawn, your grenade charges significantly faster, you have increased melee range and damage, and melee final blows extend the duration of your Overshield.

        You gain an additional shield throw while in Sentinel Shield.
        A dilation in space used to manipulate the timing of Void abilities. Press the attack to double down on your advantage.`
    },
    Unbreakable: {
        name: 'Unbreakable',
        element: Element.Void,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/68683d4544848844a1fe0acdf7417019.jpg',
        description: ``
    },
    IceflareBolts: {
        name: 'Iceflare Bolts',
        element: Element.Stasis,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/ab67bdd1cdbc7d0f925707c6343d4d54.png',
        description: `Shattering a frozen target spawns seekers that track and freeze other nearby targets.`
    },
    Frostpulse: {
        name: 'Frostpulse',
        element: Element.Stasis,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/3a8e991dae47b351c45b9c182e9050e4.png',
        description: `Casting your Rift generates a shockwave that freezes nearby combatants.`
    },
    BleakWatcher: {
        name: 'Bleak Watcher',
        element: Element.Stasis,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/51d335e369d83d303203448c75fce439.png',
        description: `Press and hold the grenade button to convert your grenade into a [Stasis] Stasis turret that fires slowing projectiles at nearby targets.`
    },
    GlacialHarvest: {
        name: 'Glacial Harvest',
        element: Element.Stasis,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5fc9529c49b1d81ebeb7ed67779e6170.png',
        description: `Freezing targets creates [Stasis] Stasis shards around the frozen targets. Higher tier combatants create more shards.`
    },
    WintersShroud: {
        name: "Winter's Shroud",
        element: Element.Stasis,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/edf787affb7089495610e8f8c770d323.png',
        description: `Dodging slows nearby targets.`
    },
    Shatterdive: {
        name: 'Shatterdive',
        element: Element.Stasis,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a177413a2eea2ddbefde9c9111ecd04a.png',
        description: `Activate while midair to quickly descend and shatter nearby targets on impact.`
    },
    GrimHarvest: {
        name: 'Grim Harvest',
        element: Element.Stasis,
        class: ClassKey.Hunter,
        fragmentSlots: 3,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/aedf0a4a86c5dc63ab810879662b417a.png',
        description: `Defeating slowed or frozen combatants creates [Stasis] Stasis shards. These shards grant melee energy when picked up by you or your allies.`
    },
    TouchOfWinter: {
        name: 'Touch of Winter',
        element: Element.Stasis,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/b8e7bcaa96ec08540ff225a6d113a0d8.png',
        description: `Your Glacier, Duskfield, and Coldsnap grenades have enhanced functionality. 
        
        Glacier Grenade: Adds a [Stasis] Stasis crystal and changes the formation. 
        Duskfield Grenade: Increases slow-field size and creates a small [Stasis] Stasis crystal on impact. 
        Coldsnap Grenade: Seeker creates a [Stasis] Stasis crystal on detonation and chains one additional time.`
    },
    Cryoclasm: {
        name: 'Cryoclasm',
        element: Element.Stasis,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/784d860ee0cf74b4afff93f2ffbd4d83.png',
        description: `After sprinting for a short while, enables a longer and more powerful slide. Sliding into [Stasis] Stasis crystals or frozen targets shatters them and any nearby frozen targets.`
    },
    TectonicHarvest: {
        name: 'Tectonic Harvest',
        element: Element.Stasis,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/590dc7e1ceaf43b5ca55bd6d3aaa2739.png',
        description: `Shattering a [Stasis] Stasis crystal creates a [Stasis] Stasis shard. This shard grants melee energy when picked up by you or your allies.`
    },
    HowlOfTheStorm: {
        name: 'Howl of the Storm',
        element: Element.Stasis,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/cc2ac1ffdba3d5dc32afdaef4b002505.png',
        description: `While sliding, activate your charged melee ability to launch a wave of [Stasis] Stasis energy forward that freezes targets and creates [Stasis] Stasis crystals.`
    },
    DiamondLance: {
        name: 'Diamond Lance',
        element: Element.Stasis,
        class: ClassKey.Titan,
        fragmentSlots: 3,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/663ed4baf3983fa13d5daf766ae60bd4.png',
        description: `Shatter or defeat targets with [Stasis] Stasis abilities or weapons to create a Stasis lance. [Shoot] : Throw the lance to freeze targets on impact. [Melee] : Slam the lance to the ground to freeze targets in a small area.`
    },
    WeaversCall: {
        name: "Weaver's Call",
        element: Element.Strand,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/773ca77508718b3a14afa2e64f9ff6af.jpg',
        description: `Cast your Rift to weave three Threadlings and deploy any Threadlings you have perched.`
    },
    MindspunInvocation: {
        name: 'Mindspun Invocation',
        element: Element.Strand,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/b12f09f57c1aa57f13cf4849461424a3.jpg',
        description: `Your grapple, Shackle Grenade, and Threadling Grenade have enhanced functionality.

        Your grapple melee spawns three Threadling eggs.
        [Grenade] : Hold to consume your Shackle Grenade and activate Weaver's Trance. Final blows while Weaver's Trance is active create a suspending detonation.
        [Grenade] : Hold to consume your Threadling Grenade and immediately generate five perched Threadlings.`
    },
    TheWanderer: {
        name: 'The Wanderer',
        element: Element.Strand,
        class: ClassKey.Warlock,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/9b8da38f8082a905b2e8d54a315a8b3b.jpg',
        description: `Tangles you throw attach to targets and detonate into a suspending burst. Destroying a Tangle creates a suspending burst after a short delay.

        Threadling final blows create a Tangle.`
    },
    Weavewalk: {
        name: 'Weavewalk',
        element: Element.Strand,
        class: ClassKey.Warlock,
        fragmentSlots: 1,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/dbf533cafaa43fd6e02bb58327859fb9.jpg',
        description: `[Air Dodge] : Dodge while airborne to consume your melee energy and enter the weave, gaining damage resistance from combatants and players. Reactivate your air dodge or cast your Rift to exit the weave.

        While in the weave, your melee energy is drained and you generate perched Threadlings over time.`
    },
    WidowsSilk: {
        name: "Widow's Silk",
        element: Element.Strand,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/ec08c97044253bc3a44d28e792a1a930.jpg',
        description: `You have an additional grenade charge.

        Your Grappling Hook creates a Grapple Tangle at the grapple point.`
    },
    EnsnaringSlam: {
        name: 'Ensnaring Slam',
        element: Element.Strand,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/fb8ad358e003be53e70e1f25aa9f607a.jpg',
        description: `Activate your air move to consume your class ability energy and dive to the ground, suspending all nearby targets on impact. Using this ability extends class ability cooldown time.`
    },
    ThreadedSpecter: {
        name: 'Threaded Specter',
        element: Element.Strand,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/4ba20180085e906f97ab2d039c20f1de.jpg',
        description: `Activating your class ability leaves behind a decoy woven from [Strand] Strand matter that draws the attention of nearby combatants.

        After taking significant damage or when combatants approach, the decoy detonates, dealing damage and releasing Threadlings that seek out and attack nearby foes.`
    },
    WhirlingMaelstrom: {
        name: 'Whirling Maelstrom',
        element: Element.Strand,
        class: ClassKey.Hunter,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/838134e7e3d13bb5b74ae995653715fc.jpg',
        description: `Destroying a Tangle will weave a violent, writhing mass of [Strand] Strand fibers. The Strand mass seeks out and damages targets, emitting Unraveling projectiles when it defeats them.`
    },
    IntoTheFray: {
        name: 'Into the Fray',
        element: Element.Strand,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/4c5a1a04e73dbce39ba0a253773e126e.jpg',
        description: `Destroying a Tangle or casting your Super grants Woven Mail for nearby allies.

        While you have Woven Mail, your melee regeneration rate is increased.`
    },
    DrengrsLash: {
        name: "Drengr's Lash",
        element: Element.Strand,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/34742d88e23704dc45fc85a64001638b.jpg',
        description: `Activate your class ability to create a ripple in reality that travels forward along the ground, suspending and damaging targets it hits.`
    },
    FlechetteStorm: {
        name: 'Flechette Storm',
        element: Element.Strand,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/220a7a94fa6d34cb513b3cd2aa2302b8.jpg',
        description: `While sliding, activate your charged melee ability to leap into the air, knocking nearby targets away and dealing damage.

        While airborne, activate your charged melee again to launch a cluster of damaging, Unraveling projectiles. Repeatedly activating melee will chain additional throws.`
    },
    BannerOfWar: {
        name: 'Banner of War',
        element: Element.Strand,
        class: ClassKey.Titan,
        fragmentSlots: 2,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/0b3bb9462bfe4b2c2382be8979f384f2.jpg',
        description: `Defeat a target with a melee attack, finisher, or Sword to raise a Banner of War that pulses with energy, periodically healing nearby allies and increasing melee and Sword damage.

        Targets defeated by you and nearby allies charge the banner, increasing the speed of its pulses.`
    },
}

export const ClassAbility = {
    HealingRift: {
        name: 'Healing Rift',
        class: ClassKey.Warlock,
        description: `Conjure a well of power that continuously heals those inside it.`,
        icons: {
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/12e2bcbfb817e4f67ec6eeda111c0790.jpg',
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/21c913ded4a635f654ee47bed3d780be.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/1cf5b4574886d65d39e4016d798c96a6.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/5af22b96589e8e20148a711d46b90ff3.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/5af22b96589e8e20148a711d46b90ff3.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/5af22b96589e8e20148a711d46b90ff3.png',
        }
    },
    EmpoweringRift: {
        name: 'Empowering Rift',
        class: ClassKey.Warlock,
        description: `Conjure a well of power that increases weapon damage for those inside it.`,
        icons: {
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/7ed7831666e47cd300a5b1529584c18c.jpg',
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/9b08ff451b1b63c88096bc32698c62a0.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/88925f27c4a89905a3bde07f9602763c.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/3208b3698319d9ef6c771c583ed0e36a.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/3208b3698319d9ef6c771c583ed0e36a.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/3208b3698319d9ef6c771c583ed0e36a.png',
        }
    },
    PhoenixDive: {
        name: 'Phoenix Dive',
        class: ClassKey.Warlock,
        description: `Dive to the ground and create a burst of Solar Light that cures nearby allies.

        While Heat Rises is active, you gain restoration while diving and scorch targets upon landing.`,
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/344563b4a5bee94734c4499ef83a6ac2.jpg'
    },
    MarksmansDodge: {
        name: "Marksman's Dodge",
        class: ClassKey.Hunter,
        description: ``,
        icons: {
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/17c8532ba08411f2cad7fe8a443a63d0.jpg',
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/a0c91a37b3f1ad57da0e4e64ded13569.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/a87b7b80d34f93fecf446486e9e683e7.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/6148e0135ab295e24888a4b083e4e480.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/6148e0135ab295e24888a4b083e4e480.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/6148e0135ab295e24888a4b083e4e480.png',
        }
    },
    GamblersDodge: {
        name: "Gambler's Dodge",
        class: ClassKey.Hunter,
        description: ``,
        icons: {
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/06f00a7fbe80686a21c95d53e1506ed1.jpg',
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/7ad146429d1375f825841d33641650ac.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/a701bc92b729da5331aee944106912cf.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/5bb832a0d1719210c76442528d9ca1ac.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/5bb832a0d1719210c76442528d9ca1ac.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/5bb832a0d1719210c76442528d9ca1ac.png',
        }
    },
    AcrobatsDodge: {
        name: "Acrobat's Dodge",
        class: ClassKey.Hunter,
        description: ``,
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/7b7917cbb4697af66a810def2b558328.jpg'
    },
    ToweringBarricade: {
        name: "Towering Barricade",
        class: ClassKey.Titan,
        description: `Create a large barrier that can be used to reinforce a position with cover from enemy fire.`,
        icons: {
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/8042b480c9dea2fc3301e15e7695feaf.jpg',
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/44389de78b61efe84346a87ed55a5f80.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/b727baee6fe4228fbe35743a3bb199a9.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/04e9c35e9f14147ce3898cea505dc7cf.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/04e9c35e9f14147ce3898cea505dc7cf.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/04e9c35e9f14147ce3898cea505dc7cf.png',
        }
    },
    RallyBarricade: {
        name: "Rally Barricade",
        class: ClassKey.Titan,
        description: `Create a small barrier that you can peek over while aiming down sights and that increases weapon reload speed, stability, and range when you stand behind it.`,
        icons: {
            [Element.Void]: 'https://www.bungie.net/common/destiny2_content/icons/dbf616cee47329f90049fa84655790b8.jpg',
            [Element.Arc]: 'https://www.bungie.net/common/destiny2_content/icons/095be27fd5f742a241fe77176e80cfdf.jpg',
            [Element.Solar]: 'https://www.bungie.net/common/destiny2_content/icons/278392023803641890167602fef8bb4f.jpg',
            [Element.Stasis]: 'https://www.bungie.net/common/destiny2_content/icons/d29754d40cae05f736b8318b1654dc9a.png',
            [Element.Strand]: 'https://www.bungie.net/common/destiny2_content/icons/d29754d40cae05f736b8318b1654dc9a.png',
            [Element.Prismatic]: 'https://www.bungie.net/common/destiny2_content/icons/d29754d40cae05f736b8318b1654dc9a.png',
        }
    },
    Thruster: {
        name: "Thruster",
        class: ClassKey.Titan,
        description: ``,
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/24fec20486dcadb8be276226b56c5500.jpg'
    },
}

export const Super = {
    Stormtrance: {
        name: 'Stormtrance',
        element: Element.Arc,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/31a0445d352fd44b62c9a8dd2752ccdf.png',
        description: `Chain Arc Lightning from your hands to electrify targets with devastating streams of Arc Light that intensify over time. Casting Stormtrance creates a jolting shockwave underneath you.

        [Sprint] : Teleport forward, consuming a small amount of Super energy.`,
    },
    ChaosReach: {
        name: 'Chaos Reach',
        element: Element.Arc,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/b54195b2d82a31ae970ca85fb7fb0be7.png',
        description: `Unleash a long-range channeled beam of concentrated Arc energy. Sustained damage on a single target creates a jolting lightning strike at their position.

        [Super] while active to deactivate your Super early, saving Super energy.`,
    },
    ArcStaff: {
        name: 'Arc Staff',
        element: Element.Arc,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/435489f514e2bf88d25c452a96f2dff9.png',
        description: `Form a staff of pure Arc energy and acrobatically take out your foes.

        [Block] : Hold to deflect incoming projectiles with your Arc staff.`,
    },
    GatheringStorm: {
        name: 'Gathering Storm',
        element: Element.Arc,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/edf23f2e6951efcab4c4b10630b6f7c4.png',
        description: `Hurl your Arc Staff forward, embedding it into surfaces or large targets and jolting nearby targets.

        After a short time, a devastating lightning bolt strikes the staff, overcharging it with Arc energy. While overcharged, your staff discharges lightning at nearby foes.`,
    },
    StormsEdge: {
        name: "Storm's Edge",
        element: Element.Arc,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/edf23f2e6951efcab4c4b10630b6f7c4.png',
        description: ``,
    },
    FistsOfHavoc: {
        name: 'Fists of Havoc',
        element: Element.Arc,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5bc4f4029b38fd41d0232460b4295600.png',
        description: `Supercharge your fists and slam the ground with the force of a maelstrom. While active:

        [Light Attack] : Quickly charge forward, dealing damage to any impacted targets.
        
        [Heavy Attack] : Slam your fists to the ground, blinding targets and dealing damage in an area around you, leaving aftershocks in your wake. Performing a slam from the air deals additional damage.`,
    },
    Thundercrash: {
        name: 'Thundercrash',
        element: Element.Arc,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/adb140aba83a6c14345852531d4ee2e0.png',
        description: `Hurtle through the air like a missile and crash into targets to inflict meteoric damage.`,
    },
    WellOfRadiance: {
        name: 'Well of Radiance',
        element: Element.Solar,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2f3615ddcd86ab7c50653d2d1847c3bf.png',
        description: `Thrust your sword into the ground, damaging and scorching nearby combatants. The sword projects a continuous aura, granting radiant effects and restoration to nearby allies, protecting them from the effects of Stasis.`,
    },
    Daybreak: {
        name: 'Daybreak',
        element: Element.Solar,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/89b89220e92c5b363d3e105c25a21640.png',
        description: `Fashion Solar Light into blades and smite your foes from the sky.

        Your Daybreak projectiles launch a streak of deadly flames on impact.`,
    },
    SongOfFlame: {
        name: 'Song of Flame',
        element: Element.Solar,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/89b89220e92c5b363d3e105c25a21640.png',
        description: ``,
    },
    BladeBarrage: {
        name: 'Blade Barrage',
        element: Element.Solar,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/0b01a6ddceb7b0e2e86ebcb7a6a83eaa.png',
        description: `Vault into the air and unleash a volley of Solar-charged explosive knives.`,
    },
    GoldenGunMarksman: {
        name: 'Golden Gun: Marksman',
        element: Element.Solar,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/52f2eb1fefa20e9c7b064c190855d588.png',
        description: `Summon a precision flaming pistol that disintegrates targets with Solar Light.

        Your Golden Gun deals massively increased precision damage, overpenetrates targets, and creates Orbs of Power on precision hits.
        
        Benefits from being radiant.`,
    },
    GoldenGunDeadshot: {
        name: 'Golden Gun: Deadshot',
        element: Element.Solar,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/e19ebe4d56d6f95d582703f6b481813f.png',
        description: `Summon a rapid-fire flaming pistol that disintegrates targets with Solar Light.

        Causing Solar ignitions while your Super is active refunds a Golden Gun round.
        
        Benefits from being radiant.`,
    },
    HammerOfSol: {
        name: 'Hammer of Sol',
        element: Element.Solar,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/9d1fd669f61cce4abd35dbefd22ba90c.png',
        description: `Summon a flaming hammer and wreak destruction down upon your enemies.

        [Light Attack] : Throw your hammer at foes.
        
        Upon impact, your hammers shatter into explosive molten shards.`,
    },
    BurningMaul: {
        name: 'Burning Maul',
        element: Element.Solar,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a0391bd2a8cf73c58cec261961db0136.png',
        description: `Summon a flaming maul and crush targets with the force of an earthquake.

        [Light Attack] : Spin the maul in a circle around you, damaging nearby targets.
        
        [Heavy Attack] : Slam the maul to the ground and create a cyclone of flames that seeks targets, dealing damage and scorching them.`,
    },
    NovaBombCataclysm: {
        name: 'Nova Bomb: Cataclysm',
        element: Element.Void,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/e9dc1cc0179cda4d2445845cf8992a7e.png',
        description: `Hurl an explosive bolt of Void Light at a target, disintegrating those caught within its blast.

        Nova Bomb travels slowly and seeks targets. Detonations shatter into smaller seeker projectiles.`,
    },
    NovaBombVortex: {
        name: 'Nova Bomb: Vortex',
        element: Element.Void,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/b1efa0eaa710653d85e2fcf5321047fb.png',
        description: `Hurl an explosive bolt of Void Light at a target, disintegrating those caught within its blast.

        Nova Bomb creates a singularity that pulls targets inward and continually damages those trapped inside.`,
    },
    NovaWarp: {
        name: 'Nova Warp',
        element: Element.Void,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/feb001db8e9776bc822007c74564c1b6.png',
        description: `Step between dimensions to subvert the laws of physics.

        [Super Secondary] : Teleport a short distance.
        
        [Super Primary] : Unleash a deadly Void eruption. A fully charged attack makes targets volatile.`,
    },
    ShadowshotDeadfall: {
        name: 'Shadowshot: Deadfall',
        element: Element.Void,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/61feac4f1271ba6cecc29cc50e20ab5a.png',
        description: `Tether foes to a Void Anchor, weakening and suppressing them for you and your allies.

        The Void Anchors fired from Shadowshot pull targets toward the impact point, which then become traps and wait for prey. Void Anchors have increased range and last longer.`,
    },
    ShadowshotMoebiusQuiver: {
        name: 'Shadowshot: Moebius Quiver',
        element: Element.Void,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/986e8f2dd0699371d605a331bb63742a.png',
        description: `Tether foes to a Void Anchor, weakening and suppressing them for you and your allies.

        Fire two volleys of three Void Arrows that seek nearby targets. Shadowshot makes targets volatile and deals increased damage to tethered targets. Defeating tethered targets creates Orbs of Power.`,
    },
    SpectralBlades: {
        name: 'Spectral Blades',
        element: Element.Void,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/1fbfacd5dfe847c5cd0262c5616653ff.png',
        description: `Summon a pair of deadly Void blades and stalk the battlefield in a veil of shadows.

        [Light Attack] : Perform a quick melee attack.
        
        [Heavy Attack] : Perform a heavy attack that weakens your target, and once again vanish from sight.`,
    },
    WardOfDawn: {
        name: 'Ward of Dawn',
        element: Element.Void,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/1caf1eccf1072969ab93bd35fde62599.png',
        description: `Create an indestructible dome to protect you and your allies. Passing through Ward of Dawn grants you and your allies a temporary increase to weapon damage.`,
    },
    SentinelShield: {
        name: 'Sentinel Shield',
        element: Element.Void,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a929ea604d638e5e99125e48f76989e2.png',
        description: `[Super] : Summon a shield of Void Light.
        While Sentinel Shield is active:
        [Light Attack] : Attack.
        [Block] : Block.
        [Grenade] : Perform a Shield Throw.
        
        Guard with Sentinel Shield to create a defensive wall. Allies who shoot through the wall have increased weapon damage, and guarding allies makes the shield last longer.`,
    },
    TwilightArsenal: {
        name: 'Twilight Arsenal',
        element: Element.Void,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a929ea604d638e5e99125e48f76989e2.png',
        description: ``,
    },
    WintersWrath: {
        name: "Winter's Wrath",
        element: Element.Stasis,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/c9f25c8f6d5e647366ffc4f71a825961.png',
        description: `Summon a [Stasis] Stasis staff. While your Super is active:

        [Super Primary] : Cast out a barrage of [Stasis] Stasis shards that freeze targets.
        
        [Super Secondary] : Cast out a shockwave that shatters all frozen targets.`,
    },
    SilenceAndSquall: {
        name: "Silence and Squall",
        element: Element.Stasis,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a8bbee32ce8f259e7b9e112c0c8a401a.png',
        description: `Summon two Stasis kamas, Silence and Squall, that you throw one after another.

        Silence : Creates a flash-freeze blast that freezes and damages targets.
        
        Squall : Creates a Stasis Storm that slows and damages targets caught inside.`,
    },
    GlacialQuake: {
        name: "Glacial Quake",
        element: Element.Stasis,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/3c522f849a7d4d86d5224d7d5d5671a4.png',
        description: `Summon a [Stasis] Stasis gauntlet. While your Super is active:

        [Heavy Attack] : Slam your gauntlet down to create a shockwave that freezes targets and sends out [Stasis] Stasis crystals to freeze additional ones.
        
        [Light Attack] : Supercharge your Shiver Strike for bonus damage. Sprint through [Stasis] Stasis crystals or frozen targets to shatter them instantly.`
    },
    Needlestorm: {
        name: "Needlestorm",
        element: Element.Strand,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2e486aef07bd3551c35807f416ba0b6c.png',
        description: `[Super] : Conjure a hail of deadly woven needles.
        After embedding themselves in the environment, the needles will re-weave into a host of Threadlings.
        
        Threadlings you create will return to you and perch if they can't find any nearby targets. Damaging targets with your weapons or melee will send out perched Threadlings to attack.`,
    },
    Silkstrike: {
        name: "Silkstrike",
        element: Element.Strand,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2e486aef07bd3551c35807f416ba0b6c.png',
        description: `Weave a vicious rope dart and tear through your targets.

        [Light Attack] : Swing the rope dart in front of you to deal damage. The tip of the dart deals bonus damage, and defeating targets with the tip causes them to explode.
        
        [Heavy Attack] : Swing the rope dart in a circular motion to deal heavy damage all around you.
        
        [Grenade] : Use the grapple to move forward quickly.`,
    },
    Bladefury: {
        name: "Bladefury",
        element: Element.Strand,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2e486aef07bd3551c35807f416ba0b6c.png',
        description: `[Super] : Create two woven blades and roam the battlefield. While active:

        [Light Attack] : Perform a leaping slash towards nearby targets, severing them on hit and dealing bonus damage to suspended targets. Each successful hit briefly increases your attack speed and grants energy for your heavy attack.
        
        [Heavy Attack] : Unleash a heavy upward slash, creating two suspending projectiles that seek out targets.`,
    },
}

export const Melee = {
    BallLightning: {
        name: 'Ball Lightning',
        element: Element.Arc,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5539f0c752919a84714366dcb3fe6893.jpg',
        description: `Fire an Arc projectile forward that releases a perpendicular lightning strike after a short time.

        While amplified, Ball Lightning releases additional lightning strikes before detonating.`
    },
    ChainLightning: {
        name: 'Chain Lightning',
        element: Element.Arc,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/f07bbec19064eec2de3d02e3b700cfc2.jpg',
        description: `An extended range melee that jolts your target and chains lightning to nearby targets.

        While amplified, it creates an additional set of chains.`
    },
    CombinationBlow: {
        name: 'Combination Blow',
        element: Element.Arc,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a7ff3045c06a03649199c6b8e185d7a3.jpg',
        description: ``
    },
    DisorientingBlow: {
        name: 'Disorienting Blow',
        element: Element.Arc,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/0ac85c928868a34d47dba95dcff2da2a.jpg',
        description: ``
    },
    SeismicStrike: {
        name: 'Seismic Strike',
        element: Element.Arc,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/ef72ed2a57e2cf3405e72b93f2218e50.jpg',
        description: ``
    },
    BallisticSlam: {
        name: 'Ballistic Slam',
        element: Element.Arc,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/c9e36caa03cdb0d8dfcacc57f0e01938.jpg',
        description: ``
    },
    Thunderclap: {
        name: 'Thunderclap',
        element: Element.Arc,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/45e77f67fbb1e12c36972cd923f7b9e0.jpg',
        description: ``
    },
    IncineratorSnap: {
        name: 'Incinerator Snap',
        element: Element.Solar,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/e9dee44a01ea409afe80a939b6341843.jpg',
        description: `Snap your fingers to create a fan of burning sparks that explode and scorch targets.`
    },
    CelestialFire: {
        name: 'Celestial Fire',
        element: Element.Solar,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/fb42651d89b71d2ca952f593c09b1448.jpg',
        description: `Send out a spiral of three explosive Solar energy blasts, scorching targets with each hit.`
    },
    LightweightKnife: {
        name: 'Lightweight Knife',
        element: Element.Solar,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/05609c418be3a985a640632ccaca796b.jpg',
        description: ``
    },
    WeightedThrowingKnife: {
        name: 'Weighted Throwing Knife',
        element: Element.Solar,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5875031ec666d94d7358a0c7d3a1191f.jpg',
        description: ``
    },
    KnifeTrick: {
        name: 'Knife Trick',
        element: Element.Solar,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d9607f7db9d1f53b16af6ee6f6bcb428.jpg',
        description: ``
    },
    ProximityExplosiveKnife: {
        name: 'Proximity Explosive Knife',
        element: Element.Solar,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/3616d8241a759780586c0869fa1b3d1e.jpg',
        description: ``
    },
    HammerStrike: {
        name: 'Hammer Strike',
        element: Element.Solar,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/c05584046992239cbbd0d319228058b8.jpg',
        description: `After sprinting for a short time, use this melee ability to swing a blazing hammer that scorches your target and deals damage in a cone behind them.

        If your target is defeated by Hammer Strike, they ignite.`
    },
    ThrowingHammer: {
        name: 'Throwing Hammer',
        element: Element.Solar,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/2db60293ac428ae066a5bb2fed04f6e7.jpg',
        description: `Throw a hammer from a distance. Picking up a thrown hammer fully recharges your melee ability. If the hammer struck a target, picking it up grants cure.`
    },
    PocketSingularity: {
        name: 'Pocket Singularity',
        element: Element.Void,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/afd8d014a0cf2e76a73172beae7ef0ee.jpg',
        description: `Launch an unstable ball of Void energy that detonates when it nears a target, pushing them away from the blast and making them volatile.`
    },
    SnareBomb: {
        name: 'Snare Bomb',
        element: Element.Void,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/df5d0a9f49f37de0cb781b8d2fd70516.jpg',
        description: `Throw a Smoke Bomb, which attaches to surfaces and pings enemy radar. Weakens targets on detonation.`
    },
    ShieldBash: {
        name: 'Shield Bash',
        element: Element.Void,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/7a7f502312a1217de5b76e416fb85677.jpg',
        description: `After sprinting for a short time, use this melee ability to unleash a devastating Shield Bash that suppresses the target. Final blows with Shield Bash grant an Overshield.`
    },
    ShieldThrow: {
        name: 'Shield Throw',
        element: Element.Void,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/18ba97e43f603ca3cbc1507255992d39.jpg',
        description: `Hurl your Void Shield toward a target. The shield can ricochet off targets and surfaces, granting Overshield for each target hit.`
    },
    PenumbralBlast: {
        name: 'Penumbral Blast',
        element: Element.Stasis,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/f77faacb093918106f28b2b49e168264.png',
        description: ``
    },
    WitheringBlade: {
        name: 'Withering Blade',
        element: Element.Stasis,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d4dbdba1bbc4f102f865b14dc2ec186f.png',
        description: ``
    },
    ShiverStrike: {
        name: 'Shiver Strike',
        element: Element.Stasis,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/1f34b1d2eb5377ac68346cb42db6f008.png',
        description: ``
    },
    ArcaneNeedle: {
        name: 'Arcane Needle',
        element: Element.Strand,
        class: ClassKey.Warlock,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/e996524beb9d4371b1adc1212a6accdf.png',
        description: `Sling a woven needle that will embed in your target, unraveling them. Activate your melee ability again to chain up to two additional attacks.`
    },
    ThreadedSpike: {
        name: 'Threaded Spike',
        element: Element.Strand,
        class: ClassKey.Hunter,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/46620e1f142778a0d429be7a12c39f21.png',
        description: ``
    },
    FrenziedBlade: {
        name: 'Frenzied Blade',
        element: Element.Strand,
        class: ClassKey.Titan,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/7cccab938e8218821aed5d76740f61e9.png',
        description: `Activate your charged melee ability to dash forward, slashing at targets in front of you and severing them.`
    },
}

export const Grenade = {
    Solar: {
        name: 'Solar Grenade',
        element: Element.Solar,  
        icon: 'https://www.bungie.net/common/destiny2_content/icons/720bf9cc73dce537c5ee5577e0edcf9b.jpg',
        description: `A grenade that creates a flare of Solar Light that continuously damages and scorches targets trapped inside.`,
    },
    Tripmine: {
        name: 'Tripmine Grenade',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/05609c418be3a985a640632ccaca796b.jpg',
        description: `An explosive grenade that sticks to surfaces and detonates when targets pass through its laser trigger, dealing damage and moderately scorching them.`,
    },
    Thermite: {
        name: 'Thermite Grenade',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/e4aa73bf21f3da3e22f9e07475e2e614.jpg',
        description: `A grenade that sends forth a burning line of fire, dealing damage and scorching targets in its path.`,
    },
    Incendiary: {
        name: 'Incendiary Grenade',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/1dc2e8b05e9311431682e2bab28cf1ea.jpg',
        description: `A grenade that explodes in a fiery burst and heavily scorches nearby targets.`,
    },
    Swarm: {
        name: 'Swarm Grenade',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/0c322768dd41ac416e04dac131cf5fb5.jpg',
        description: `A grenade that detonates on impact, releasing multiple drones that seek nearby targets. Each drone slightly scorches affected targets.`,
    },
    Fusion: {
        name: 'Fusion Grenade',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/37a4f29aa2ef6ffcdee1a8eb6b5bfade.jpg',
        description: `An explosive grenade that attaches to targets, damaging and moderately scorching them on detonation.`,
    },
    Firebolt: {
        name: 'Firebolt Grenade',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/bb77ba17b16192e7f383bc10a71bf014.jpg',
        description: `A grenade that unleashes bolts of damaging Solar Light at nearby targets and slightly scorches them.`,
    },
    Healing: {
        name: 'Healing Grenade',
        element: Element.Solar,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/d7fa54aea967c101aeafcbc1163508d6.jpg',
        description: `A grenade that cures allies on impact and creates an Orb of benevolent Solar Light, granting restoration to allies when picked up.`,
    },
    Vortex: {
        name: 'Vortex Grenade',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/12997544665705bbbd94742268ce7091.jpg',
        description: `A grenade that creates a Vortex that pulls targets inward and continually damages those trapped inside.`,
    },
    VoidSpike: {
        name: 'Void Spike',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/7dabddafe91884860ed108c440cc9591.jpg',
        description: `Conjure a well of power that increases weapon damage for those inside it.`,
    },
    VoidWall: {
        name: 'Void Wall',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a905c46ea9edc45f2773b30b719bd6dc.jpg',
        description: `A grenade that creates a horizontal wall of burning Void Light.`,
    },
    Suppressor: {
        name: 'Suppressor Grenade',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/17372092d1add4cf52bb4862037b418c.jpg',
        description: `An explosive grenade that suppresses, preventing targets from using abilities for a short time.`,
    },
    Scatter: {
        name: 'Scatter Grenade',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/562f1b6ca7ecda82248f4b02e833d211.jpg',
        description: `A grenade that splits into many submunitions and covers a large area with multiple explosions.`,
    },
    Magnetic: {
        name: 'Magnetic Grenade',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/72966b5c97e45d8668483f2263138613.jpg',
        description: `A grenade that attaches to targets and explodes twice.`,
    },
    AxionBolt: {
        name: 'Axion Bolt',
        element: Element.Void,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/3ecbc1ab120d80d0aa322c0a18fa22a1.jpg',
        description: `A bolt of Void Light that forks on impact into smaller bolts that seek out targets.`,
    },
    Pulse: {
        name: 'Pulse Grenade',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/11837de6306d28df467178af097d748b.jpg',
        description: `A grenade that periodically damages targets within its explosion radius.`,
    },
    Storm: {
        name: 'Storm Grenade',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/9f4ace2bd6ab050148f54ff6f4ce9a0f.jpg',
        description: `A grenade that calls down a focused lightning storm.`,
    },
    Lightning: {
        name: 'Lightning Grenade',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/fd8f038f2d0a62c6a43024767998f383.jpg',
        description: `A grenade that sticks to any surface and emits bolts of lightning.`,
    },
    Flashbang: {
        name: 'Flashbang Grenade',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/937015647a56bc422dd87fe784465548.jpg',
        description: `An explosive grenade that damages and blinds nearby targets.`,
    },
    Skip: {
        name: 'Skip Grenade',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/4beec209d1009f30116a9a10721864aa.jpg',
        description: `A grenade that splits upon impact, creating multiple projectiles that seek targets.`,
    },
    Flux: {
        name: 'Flux Grenade',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/1d7e8eb7c2c5884cb658a1877a084548.jpg',
        description: `An explosive grenade that attaches to targets.`,
    },
    Arcbolt: {
        name: 'Arcbolt Grenade',
        element: Element.Arc,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/04c36b4cfebda156a8eca2b257e3ce4f.jpg',
        description: `A grenade that chains bolts of lightning to nearby targets.`,
    },
    Coldsnap: {
        name: 'Coldsnap Grenade',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/ff53139f18628530891598936e7a8c13.png',
        description: 'A grenade that freezes on impact and sends another seeker to find and freeze targets.',
    },
    Glacier: {
        name: 'Glacier Grenade',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/08b4d8632636f78c57425b844d7b42e4.png',
        description: 'A grenade that creates walls out of [Stasis] Stasis crystals to block damage and freeze targets; these walls can be shattered for damage.'
    },
    Duskfield: {
        name: 'Duskfield Grenade',
        element: Element.Stasis,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5ed90305d6131582eb76abd99c487826.png',
        description: 'A grenade that shatters on impact, leaving behind a field that slows targets and freezes those who do not leave the volume.',
    },
    Threadling: {
        name: 'Threadling Grenade',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/18a14aeac121b2a5c8699556b63e38fd.jpg',
        description: 'A woven projectile that reweaves itself into Threadlings while in flight.',
    },
    Grapple: {
        name: 'Grapple',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/5d65ba9eb04c2f3760eba0b5eec9be8a.jpg',
        description: `Weave a grappling hook, and quickly pull yourself forward. Grappling to a Tangle immediately recharges your grenade energy.

        During and shortly after grappling, your melee attack deals area damage and unravels targets.`,
    },
    Shackle: {
        name: 'Shackle Grenade',
        element: Element.Strand,
        icon: 'https://www.bungie.net/common/destiny2_content/icons/a2c73298753fdd565a22d3d6712820ab.jpg',
        description: 'A thrown weapon of weighted Strand matter that detonates on impact, suspending targets and creating additional suspending subprojectiles.',
    },
}