import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Dropdown from "./Dropdown"
import { Class,  } from "components/D2Ghost/constants/characters"
import { Element, ElementIcon } from "components/D2Ghost/constants/element"
import { ClassAbility, Grenade } from "components/D2Ghost/constants/abilities"
import { ReactComponent as WarlockIcon } from 'assets/warlock.svg'
import { ReactComponent as HunterIcon } from 'assets/hunter.svg'
import { ReactComponent as TitanIcon } from 'assets/titan.svg'
import { getAspects, getClassAbilities, getFragments, getGrenades, getMelees, getMovementAbilities, getSupers, updateAspects, updateClass, updateFragments, updateSubClass } from "./utils"
import styles from "styles"

type BuildInspectorProps = {
    isEditing?: boolean,
}

export type Build = {
    class: typeof Class | undefined,
    subclass: Element | undefined,
    super: any | undefined,
    grenade: any | null,
    melee: any | undefined,
    classAbility: any | undefined,
    movementAbility: any | undefined,
    aspects: any[],
    fragments: any[],
    exotic: any | undefined,
    stats: any[],
    title: "",
    // author: 'angery reaxx',
    description: '',
}

const defaultBuild = {
    class: undefined,
    subclass: undefined,
    super: undefined,
    grenade: undefined,
    melee: undefined,
    classAbility: undefined,
    movementAblity: undefined,
    aspects: [],
    fragments: [],
    exotic: undefined,
    stats: [],
    title: "",
    // author: 'angery reaxx',
    description: '',
} as Build

const subclassOptions = [
    {
        label: Element.Void,
        value: Element.Void
    },
    {
        label: Element.Solar,
        value: Element.Solar
    },
    {
        label: Element.Arc,
        value: Element.Arc
    },
    {
        label: Element.Stasis,
        value: Element.Stasis
    },
    {
        label: Element.Strand,
        value: Element.Strand
    },
    {
        label: Element.Prismatic,
        value: Element.Prismatic
    },
]

const BuildInspector = (props: BuildInspectorProps) => {
    const [build, setBuild] = useState(defaultBuild)

    const totalFragmentSlots = build.aspects.reduce((partial, a) => partial + a.fragmentSlots, 0)

    const getClassIcon = () => {
        switch (build.class) {
            case Class.Titan:
                return <TitanIcon fill='white'/>
            case Class.Hunter:
                return <HunterIcon fill='white'/>
            case Class.Warlock:
            default:
                return <WarlockIcon fill='white'/>
        }
    }

    return <Container {...styles.MotionProps.fadeInOutDown}>
        <Scrollable>
            {/* <NameInput placeholder='Name your build'/>
            <Author>by: angery reaxx</Author>
            <br/> */}
            <div style={{display: 'flex', width: '100%', marginBottom: '20px', flexWrap: 'wrap'}}>
                <Dropdown
                    placeholder="Class"
                    label='Class'
                    value={build.class?.name}
                    icon={getClassIcon()}
                    onClick={(option) => { updateClass(option, build, setBuild) }}
                    options={[
                        {
                            label: Class.Warlock.name,
                            value: Class.Warlock,
                            icon: <WarlockIcon fill='white'/>,
                        },
                        {
                            label: Class.Hunter.name,
                            value: Class.Hunter,
                            icon: <HunterIcon fill='white'/>,
                        },
                        {
                            label: Class.Titan.name,
                            value: Class.Titan,
                            icon: <TitanIcon fill='white'/>,
                        },
                    ]}
                />
                <Dropdown
                    disabled={!build.class}
                    label='Subclass'
                    placeholder="Subclass"
                    value={build.subclass && Class[build.class?.name as ClassKey]?.subclasses[build.subclass]?.name}
                    subtitle={build.subclass}
                    icon={ElementIcon[build.subclass]}
                    onClick={(option) => { updateSubClass(option, build, setBuild) }}
                    options={Object.values(Element).map(element => ({ 
                        label: Class[build.class?.name as ClassKey]?.subclasses[element]?.name,
                        value: element,
                        icon: ElementIcon[element],
                        subtitle: element,
                    }))}
                />
                <Dropdown
                    disabled={!build.class}
                    label='Exotic Armor'
                    placeholder="Exotic Armor"
                    value={build.exotic}
                    onClick={(option: any) => { 
                        setBuild({ 
                            ...build,
                            exotic: option.value
                        }) 
                    }}
                    options={[]}
                />
            </div>
            <div style={{display: 'flex', width: '100%', marginBottom: '20px', flexWrap: 'wrap'}}>
                <Dropdown
                    disabled={!build.subclass}
                    label='Super'
                    placeholder="Super"
                    value={build.super?.name}
                    description={build.super?.description}
                    icon={build.super?.icon}
                    onClick={(option: any) => { 
                        setBuild({ 
                            ...build,
                            super: option.value
                        }) 
                    }}
                    options={getSupers(build).map((superAbility: any) => ({ 
                        value: superAbility,
                        label: superAbility.name,
                        icon: superAbility.icon,
                        description: superAbility.description
                    }))}
                />
                <Dropdown
                    disabled={!build.subclass}
                    placeholder="Grenade"
                    label='Grenade'
                    value={build.grenade?.name}
                    description={build.grenade?.description}
                    icon={build.grenade?.icon}
                    onClick={(option: any) => { 
                        setBuild({ 
                            ...build,
                            grenade: option.value
                        }) 
                    }}
                    options={getGrenades(build).map((grenade: any) => ({ 
                        value: grenade,
                        label: grenade.name,
                        icon: grenade.icon,
                        description: grenade.description,
                    }))}
                />
                <Dropdown
                    placeholder="Melee"
                    label='Melee'
                    value={build.melee?.name}
                    icon={build.melee?.icon}
                    description={build.melee?.description}
                    disabled={!build.subclass}
                    onClick={(option: any) => { 
                        setBuild({ 
                            ...build,
                            melee: option.value
                        }) 
                    }}
                    options={getMelees(build).map((melee: any) => ({
                        value: melee,
                        label: melee.name,
                        icon: melee.icon,
                        description: melee.description,
                    }))}
                />
            </div>
            <div style={{display: 'flex', width: '100%', marginBottom: '20px', flexWrap: 'wrap'}}>
                <Dropdown
                    placeholder="Class Ability"
                    label='Class Ability'
                    value={build.classAbility?.name}
                    description={build.classAbility?.description}
                    icon={build.subclass && (build.classAbility?.icon || build.classAbility?.icons[build.subclass])}
                    disabled={!build.subclass}
                    onClick={(option: any) => { 
                        setBuild({ 
                            ...build,
                            classAbility: option.value
                        }) 
                    }}
                    options={getClassAbilities(build).map(classAbility => ({ 
                        value: classAbility, 
                        label: classAbility.name, 
                        icon: classAbility.icon || (classAbility?.icons && classAbility?.icons[build.subclass]),
                        description: classAbility.description
                    }))}
                />
                <Dropdown
                    placeholder="Movement Ability"
                    label='Movement Ability'
                    value={build.movementAbility?.name}
                    description={build.movementAbility?.description}
                    disabled={!build.subclass}
                    icon={build.subclass && (build.movementAbility?.icon || build.movementAbility?.icons[build.subclass])}
                    onClick={(option: any) => { 
                        setBuild({ 
                            ...build,
                            movementAbility: option.value
                        }) 
                    }}
                    options={build.subclass && getMovementAbilities(build).map(movementAbility => ({ 
                        value: movementAbility, 
                        label: movementAbility.name, 
                        icon: movementAbility.icon ||  movementAbility.icons[build.subclass],
                        description: movementAbility.description
                    }))}
                />
            </div>
            <div style={{display: 'flex', width: '100%', marginBottom: '20px', flexWrap: 'wrap'}}>
                <Dropdown
                    placeholder="Aspect"
                    label='Aspects'
                    value={build.aspects[0]?.name}
                    icon={build.aspects[0]?.icon}
                    subtitle={build.aspects[0] && `Fragment slots: ${build.aspects[0].fragmentSlots}`}
                    disabled={!build.subclass}
                    description={build.aspects[0]?.description}
                    onClick={(option) => updateAspects(option, 0, build, setBuild)}
                    options={getAspects(build, build.aspects[0]).map((aspect: any) => ({ 
                        value: aspect,
                        label: aspect.name,
                        subtitle: `Fragment slots: ${aspect.fragmentSlots}`,
                        icon: aspect.icon,
                        description: aspect.description,
                    }))}
                />
                <Dropdown
                    placeholder="Aspect"
                    value={build.aspects[1]?.name}
                    icon={build.aspects[1]?.icon}
                    subtitle={build.aspects[1] && `Fragment slots: ${build.aspects[1].fragmentSlots}`}
                    disabled={!build.subclass}
                    description={build.aspects[1]?.description}
                    onClick={(option) => updateAspects(option, 1, build, setBuild)}
                    options={getAspects(build, build.aspects[1]).map((aspect: any) => ({ 
                        value: aspect,
                        label: aspect.name,
                        subtitle: `Fragment slots: ${aspect.fragmentSlots}`,
                        icon: aspect.icon,
                        description: aspect.description,
                    }))}
                />
            </div>
            <div style={{display: 'flex', width: '100%', marginBottom: '20px', flexWrap: 'wrap'}}>
                <Dropdown
                    placeholder="Fragment"
                    label='Fragments'
                    value={build.fragments[0]?.name}
                    subtitle={build.fragments[0]?.description}
                    description={build.fragments[0]?.description}
                    icon={build.fragments[0]?.icon}
                    disabled={totalFragmentSlots < 1}
                    onClick={(option) => updateFragments(option, 0, build, setBuild)}
                    options={getFragments(build).map(fragment => ({ 
                        value: fragment,
                        label: fragment.name,
                        icon: fragment.icon,
                        description: fragment.description
                    }))}
                />
                <Dropdown
                    placeholder="Fragment"
                    value={build.fragments[1]?.name}
                    subtitle={build.fragments[1]?.description}
                    description={build.fragments[1]?.description}
                    icon={build.fragments[1]?.icon}
                    disabled={totalFragmentSlots < 2}
                    onClick={(option) => updateFragments(option, 1, build, setBuild)}
                    options={getFragments(build).map(fragment => ({ 
                        value: fragment,
                        label: fragment.name,
                        icon: fragment.icon,
                        description: fragment.description
                    }))}
                />
                <Dropdown
                    placeholder="Fragment"
                    value={build.fragments[2]?.name}
                    description={build.fragments[2]?.description}
                    subtitle={build.fragments[2]?.description}
                    icon={build.fragments[2]?.icon}
                    disabled={totalFragmentSlots < 3}
                    onClick={(option) => updateFragments(option, 2, build, setBuild)}
                    options={getFragments(build).map(fragment => ({ 
                        value: fragment,
                        label: fragment.name,
                        icon: fragment.icon,
                        description: fragment.description
                    }))}
                />
                <Dropdown
                    placeholder="Fragment"
                    value={build.fragments[3]?.name}
                    description={build.fragments[3]?.description}
                    subtitle={build.fragments[3]?.description}
                    icon={build.fragments[3]?.icon}
                    disabled={totalFragmentSlots < 4}
                    onClick={(option) => updateFragments(option, 3, build, setBuild)}
                    options={getFragments(build).map(fragment => ({ 
                        value: fragment,
                        label: fragment.name,
                        icon: fragment.icon,
                        description: fragment.description
                    }))}
                />
                <Dropdown
                    placeholder="Fragment"
                    value={build.fragments[4]?.name}
                    description={build.fragments[4]?.description}
                    subtitle={build.fragments[4]?.description}
                    icon={build.fragments[4]?.icon}
                    disabled={totalFragmentSlots < 5}
                    onClick={(option) => updateFragments(option, 4, build, setBuild)}
                    options={getFragments(build).map(fragment => ({ 
                        value: fragment,
                        label: fragment.name,
                        icon: fragment.icon,
                        description: fragment.description
                    }))}
                />
                <Dropdown
                    placeholder="Fragment"
                    value={build.fragments[5]?.name}
                    description={build.fragments[5]?.description}
                    subtitle={build.fragments[5]?.description}
                    icon={build.fragments[5]?.icon}
                    disabled={totalFragmentSlots < 6}
                    onClick={(option) => updateFragments(option, 5, build, setBuild)}
                    options={getFragments(build).map(fragment => ({ 
                        value: fragment,
                        label: fragment.name,
                        icon: fragment.icon,
                        description: fragment.description
                    }))}
                />
            </div>
            <div style={{display: 'flex', justifyContent: 'end'}}>
                <SaveButton onClick={() => { console.log(build) }}>Publish Build</SaveButton>
            </div>
        </Scrollable>
    </Container>
}

const SaveButton = styled.button`
    background-color: #871ac7;
    border: 1px solid #871ac7;
    border-radius: 8px;
    min-width: 240px;
    height: 42px;
    letter-spacing: 2px;
    padding: 10px 40px;
    ${styles.Animation.Transition}
    &:hover {
        background-color: transparent;
        color: #871ac7;
    }
`

const Author = styled.div`
    font-size: 20px;
    padding: 0 8px;
`

const NameInput = styled.input`
    background-color: rgba(0,0,0,0.35);
    border-bottom: 1px solid white;
    font-size: 32px;
    outline: 0;
    padding: 4px 8px;
    &::placeholder {
        opacity: 0.25;
    }
`

const Scrollable = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    overflow-y: scroll;
    overflow-x: hidden;
    ${styles.Scrollbar.D2Ghost}
    font-family: Lato;
    color: white;
`

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    padding: 20px;
`

export default BuildInspector