import { useEffect, useState } from 'react'
import { Flex, Box, Image } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import { useNavigate, useParams } from "react-router-dom";
import { ChampionTypes, FeaturedTabs, PlanetaryTabs, ReputationBonus, classes, menu } from './constants.ts'
import { planets } from './planets.js'
import { raids } from './raids.js'
import { seasons } from './seasons.js'
import { getAltarsWeapon, getCruciblePartyMode, getCrucibleRelentlessMode, getSKStoryMission, getDungeon, getLightfallStoryMission, getExoticMission, getExoSimulation, getEmpireHunt, getLostSector, getLostSectorArmor, getNightfall, getRaid, getTerminalOverload, getWQStoryMission, getWellspring } from './util.js'
import styled from 'styled-components';
import { ReactComponent as HelmetIcon } from 'assets/helmet.svg'
import { ReactComponent as ArmsIcon } from 'assets/arms.svg'
import { ReactComponent as ChestIcon } from 'assets/chest.svg'
import { ReactComponent as BootsIcon } from 'assets/boots.svg'
import { ReactComponent as RaidIcon } from 'assets/raid.svg'
import { ReactComponent as CrucibleIcon } from 'assets/crucible.svg'
import { ReactComponent as NightfallIcon } from 'assets/strike.svg'
import { ReactComponent as UnstoppableIcon } from 'assets/unstop.svg'
import { ReactComponent as BarrierIcon } from 'assets/barrier.svg'
import { ReactComponent as OverloadIcon } from 'assets/overload.svg'
import { ReactComponent as LostSectorIcon } from 'assets/lostSector.svg'
import { ReactComponent as ResilienceIcon } from 'assets/resilience.svg'
import { ReactComponent as DisciplineIcon } from 'assets/discipline.svg'
import { ReactComponent as RecoveryIcon } from 'assets/recovery.svg'
import moment from 'moment';
import styles from '../../../styles.ts';
import { ExoticArmor } from './exoticArmor.ts'
import { Element, Fragments, Warlock } from './characters.ts'
import { dungeons } from './dungeons.js';
import { nightfalls } from './nightfalls.js';
import { ExoticMissions } from './exoticMissions.js';

const fadeProps = {
    initial: {opacity: 0, y: -20},
    exit: {opacity: 0, y: -20},
    animate: {opacity: 1, y: 0},
    transition: {duration: 0.5}
}

const sampleBuild = {
    character: 'Warlock',
    subclass: Element.Void,
    super: Warlock[Element.Void].Super.NovaBomb,
    grenade: Warlock[Element.Void].Grenade.Vortex,
    melee: Warlock[Element.Void].Melee.PocketSingularity,
    class: Warlock[Element.Void].ClassAbility.HealingRift,
    aspects: [Warlock[Element.Void].Aspects.FeedTheVoid, Warlock[Element.Void].Aspects.ChaosAccelerant],
    fragments: [Fragments[Element.Void].Remnants, Fragments[Element.Void].Harvest, Fragments[Element.Void].Undermining, Fragments[Element.Void].Instability],
    exotic: ExoticArmor.Warlock.Arms.ContraverseHolds,
    weapons: {
        kinetic: ['Heritage', 'Conditional Finality', 'Succession'],
        energy: ['Unforgiven', 'Reckless Oracle', 'Collective Obligation'],
        power: ["Leviathan's Breath", 'Braytech Osprey', 'Tarantula'],
    },
    mods: {
        head: ['Void Siphon', 'Ashes to Assets', 'Heavy Ammo Finder'],
        arms: ['Firepower', 'Void Reloader', 'Fastball'],
        chest: ['Arc Elemental Resistance', 'Arc Elemental Resistance', 'Concussive Dampener'],
        legs: ['Recuperation', 'Void Scavenger', 'Void Surge'],
        class: ['Bomber', 'Reaper', 'Elemental Time Dilation'],
    },
    stats: ['Resilience', 'Discipline', 'Recovery'],
    title: "Felwinter's Abyss",
    author: 'angery reaxx',
    description: 'My standard Contraverse loadout',
    youtube: 'https://www.youtube.com/watch?v=HORdXGjum14',
}

const activities = [
    raids,
    dungeons,
    nightfalls,
    ExoticMissions
]

const Activities = () => {
    let { character, buildID } = useParams();
    const navigate = useNavigate();

    const [index, setIndex] = useState(0)

    const lastIndex = () => {
        setIndex(Math.max(0, index - 3))
    }

    const nextIndex = () => {
        setIndex(Math.min(activities.length - 3, index + 3))
    }

    const goToBuild = (ev) => {
        navigate(`/d2ghost/builds/${character}/${ev.target.id}`)
    }

    const getStatIcon = (stat) => {
        switch(stat) {
            case 'Recovery':
                return <RecoveryIcon fill='white' style={{width: '30px', height: '30px'}}/>
            case 'Resilience':
                return <ResilienceIcon fill='white' style={{width: '30px', height: '30px'}}/>
            case 'Discipline':
            default:
                return <DisciplineIcon fill='white' style={{width: '30px', height: '30px'}}/>
        }
    }

    const mapActivityToButton = (activity) => {
        return <ActivityButton image={activity.image}>
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>{activity.name}</div>
            <div style={{overflow: 'hidden', textOverflow: 'ellipsis', opacity: 0.5, fontSize: '14px'}}>{activity.destination} {activity.expansion ? `- ${activity.expansion}` : ''}</div>
        </ActivityButton>
    }

    return <Flex 
        h='100vh' 
        w='100vw'
        backgroundColor='rgb(10,10,10)'
        overflowY='hidden'
        backgroundImage='url(https://cdn.wallpapersafari.com/54/28/GiwTOz.jpg)'
        backgroundSize='cover'
        backgroundPosition='center'
    >
        <AnimatePresence exitBeforeEnter>
            {/* {sidebarContent} */}
            <Content>
                <Flex alignItems='center' margin='10px 20px'>
                    <Flex onClick={() => { navigate('/d2ghost') }} userSelect='none' _hover={{ backgroundColor: 'white', color: 'black' }} transition='all 0.2s ease-in' cursor='pointer' alignItems='center' backgroundColor='#171717' borderRadius='4px' border='1px solid white' padding='1px 10px'>
                        <Image width='40px' height='40px' marginRight='10px' src='/d2ghost.png' />
                        D2Ghost
                    </Flex>
                </Flex>
                <AnimatePresence exitBeforeEnter>
                    {!character && <motion.div key='menu' {...fadeProps} style={{display: 'flex', overflow: 'hidden', flexDirection: 'column', flex: 1, padding: '20px'}}>
                        <TabHeader>Activities</TabHeader>
                        <ButtonContainer>
                            <PageButton visible={index !== 0} onClick={lastIndex}>{'<'}</PageButton>
                            <Column>
                                <div style={{fontSize: '20px', borderBottom: '1px solid white', display: 'flex', alignItems: 'center', margin: '10px 0'}}>
                                    <RaidIcon style={{ fill: 'white', width: '40px', height: 'auto', marginRight: '10px'}}/>
                                    Raids
                                </div>
                                <Scrollable>
                                    {Object.values(activities[index]).map(mapActivityToButton)}
                                </Scrollable>
                            </Column>
                            <Column>
                                <div style={{fontSize: '20px', borderBottom: '1px solid white', display: 'flex', alignItems: 'center', margin: '10px 0'}}>
                                    <RaidIcon style={{ fill: 'white', width: '40px', height: 'auto', marginRight: '10px'}}/>
                                    Dungeons
                                </div>
                                <Scrollable>
                                    {Object.values(activities[index + 1]).map(mapActivityToButton)}
                                </Scrollable>
                            </Column>
                            <Column>
                                <div style={{fontSize: '20px', borderBottom: '1px solid white', display: 'flex', alignItems: 'center', margin: '10px 0'}}>
                                    <NightfallIcon style={{ fill: 'white', width: '40px', height: 'auto', marginRight: '10px'}}/>
                                    Strikes
                                </div>
                                <Scrollable>
                                    {Object.values(activities[index + 2]).map(mapActivityToButton)}
                                </Scrollable>
                            </Column>
                            <PageButton visible={index !== activities.length - 3} onClick={nextIndex}>{'>'}</PageButton>
                        </ButtonContainer>
                    </motion.div>}
                </AnimatePresence>
            </Content>
        </AnimatePresence>
    </Flex>
}

const Scrollable = styled.div`
    overflow-y: scroll;
    overflow-x: hidden;
    flex: 1;
    padding-right: 14px;
    overflow: -moz-scrollbars-vertical; 
    ${styles.Scrollbar.D2Ghost}
`

const Column = styled.div`
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
`

const PageButton = styled.button`
    width: 20px;
    max-width: 20px;
    flex: 1;
    font-family: Lato;
    font-weight: 500;
    background-color: rgba(255,255,255,0.1);
    display: flex;
    opacity: ${props => props.visible ? 1 : 0};
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    border-radius: 4px;
    ${styles.Animation.Transition}
    &:hover {
        background-color: rgba(255,255,255,0.25);
    }
`

const ActivityButton = styled.div`
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding: 2px 20px;
    font-weight: 500;
    font-family: Lato;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 24px;
    border: 1px solid rgba(255,255,255,0.5);
    height: 80px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 1) 100%), url("${props => props.image}");
    border-radius: 4px;
    cursor: pointer;
    ${styles.Animation.Transition}
    * {
        ${styles.Animation.Transition}
    }
    &:hover {
        // background-image: url("${props => props.image}");
        border: 1px solid rgba(255,255,255,1);
        * {
            opacity: 1 !important;
        }
    }
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    flex: 1;
    // ${styles.MediaQueries.Mobile} {
    //     flex-direction: column;
    // }
`

const Content = styled(motion.div)`
    display: flex;
    height: 100%;
    width: 100%;
    color: white;
    flex-direction: column;
`

const TabHeader = styled.div`
    font-family: Lato;
    font-size: 72px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    border-bottom: 1px solid white;
    height: fit-content;
    width: 100%;
`


export default Activities