import React from 'react';
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'

class HomePage extends React.Component {
    render() {
        const { nextPage } = this.props;

        return (<>
            <motion.div
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                transition={{
                    duration: 1.6
                }}
            >
                <Heading  marginTop='6%' fontSize={['7vw', '3vw']} color="whiteAlpha.800">Education</Heading>
            </motion.div>
            <Flex flex={1} direction='column' justify='space-around' w='100%'>
                <motion.div
                    initial={{
                        opacity: 0,
                        x: '80%'
                    }}
                    animate={{
                        opacity: 1,
                        x: '0%',
                    }}
                    transition={{
                        duration: 1,
                    }}
                >
                    <Flex w='100%' align='center'>
                        <Image
                            src="42.png"
                            alt="Workday"
                            height="auto"
                            borderRadius="15px"
                            width='20%'
                            maxWidth='200px'
                            margin='20px 0'
                            border='1px solid #333333'
                            marginLeft='4vw'
                        />
                        <Box h='100%' flex={1} margin='0 20px'>
                            <Text fontSize={['3vw', '1.4vw']} color='whiteAlpha.800' fontWeight='bold' w='100%' textAlign='center'>42 Silicon Valley (2018 - 2019)</Text>
                            <Text fontSize={['3vw', '1.4vw']} color='whiteAlpha.600' w='100%' textAlign='center'>
                                <li>Studied in Computer Science </li>
                                <li>Focus on graphics, data structures and algorithms, and web development</li>
                                <li>Languages: C, Pyhon, Ruby, JavaScript</li>
                            </Text>
                        </Box>
                    </Flex>
                </motion.div>
                <motion.div
                    initial={{
                        opacity: 0,
                        x: '80%'
                    }}
                    animate={{
                        opacity: 1,
                        x: '0%',
                    }}
                    transition={{
                        duration: 1,
                    }}
                >
                    <Flex w='100%' align='center'>
                        <Image
                            src="ua.jpeg"
                            alt="HyperMap"
                            height="auto"
                            borderRadius="15px"
                            width='20%'
                            maxWidth='200px'
                            margin='20px 0'
                            border='1px solid #333333'
                            marginLeft='4vw'
                        />
                        <Box h='100%' flex={1} margin='0 20px'>
                            <Text fontSize={['3vw', '1.4vw']} color='whiteAlpha.800' fontWeight='bold' w='100%' textAlign='center'>University of Alabama (2014 - 2016)</Text>
                            <Text fontSize={['3vw', '1.4vw']} color='whiteAlpha.600' w='100%' textAlign='center'>
                            <li>Major in Computer Science and Business Administration</li>
                            <li>Additional courses in Mathematics and Electrical Engineering</li>
                            <li>Languages: C, C++, Pyhon</li>
                            </Text>
                        </Box>
                    </Flex>
                </motion.div>
                {/* <motion.div
                    initial={{
                        opacity: 0,
                        x: '80%'
                    }}
                    animate={{
                        opacity: 1,
                        x: '0%',
                    }}
                    transition={{
                        duration: 1,
                    }}
                >
                    <Flex w='100%' align='center'>
                        <Image
                            src="contracts.png"
                            alt="Freelance"
                            height="auto"
                            borderRadius="15px"
                            width='20%'
                            border='1px solid #333333'
                            marginLeft='4vw'
                        />
                        <Box h='100%' flex={1} margin='0 20px'>
                            <Text color='whiteAlpha.800' fontWeight='bold' w='100%' textAlign='center'>Contract Work</Text>
                            <Text color='whiteAlpha.600' w='100%' textAlign='center'>
                                <li>Porsche: 3D Responsive Generative Art for Web</li>
                                <li>The Boring Company: Shopify API Webtool</li>
                            </Text>
                        </Box>
                    </Flex>
                </motion.div> */}
            </Flex>
        </>)
    }
}

export default HomePage;