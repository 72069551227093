import { ExoticType } from "./constants.ts"

export const ExoticMissions = {
    Presage: {
        name: 'Presage',
        exoticName: "Dead Man's Tale",
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/cfc2c246cfd404d749fffbfe3ae3dfec.jpg',
        exoticType: ExoticType.Craftable,
        image: 'https://www.bungie.net/img/destiny_content/pgcr/exotic_quest_presage.jpg',
        lightGG: 'https://www.light.gg/db/items/2188764214/dead-mans-tale/',
        gear: 'Haunted & Opulent',
    },
    VoxObscura: {
        name: 'Vox Obscura',
        exoticName: "Dead Messenger",
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/0824b34bb37e0bb7c32b91adf6dcb79e.jpg',
        exoticType: ExoticType.Craftable,
        image: 'https://www.bungie.net/img/destiny_content/pgcr/exotic_mission_chrome.jpg',
        lightGG: 'https://www.light.gg/db/items/46125926/dead-messenger/',
        gear: 'Risen'
    },
    SeraphShield: {
        name: 'Operation: Seraph Shield',
        exoticName: "Revision Zero",
        exoticImage: 'https://www.bungie.net/common/destiny2_content/icons/040a104defcc7011b570886e3ec3c73f.jpg',
        exoticType: ExoticType.Craftable,
        image: 'https://www.bungie.net/img/destiny_content/pgcr/season_19_exotic_mission.jpg',
        gear: 'Seraph & IKELOS',
    },
}