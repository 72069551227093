import { useEffect, useState } from 'react'
import { Flex, Box, Image } from "@chakra-ui/react"
import { AnimatePresence, motion } from "framer-motion"
import { useNavigate, useParams } from "react-router-dom";
import { ChampionTypes, FeaturedTabs, PlanetaryTabs, ReputationBonus, classes, menu } from './constants.ts'
import { planets } from './planets'
import { raids } from './raids'
import { seasons } from './seasons'
import { getAltarsWeapon, getCruciblePartyMode, getCrucibleRelentlessMode, getSKStoryMission, getDungeon, getLightfallStoryMission, getExoticMission, getExoSimulation, getEmpireHunt, getLostSector, getLostSectorArmor, getNightfall, getRaid, getTerminalOverload, getWQStoryMission, getWellspring } from './util'
import styled from 'styled-components';
import { ReactComponent as HelmetIcon } from 'assets/helmet.svg'
import { ReactComponent as ArmsIcon } from 'assets/arms.svg'
import { ReactComponent as ChestIcon } from 'assets/chest.svg'
import { ReactComponent as BootsIcon } from 'assets/boots.svg'
import { ReactComponent as RaidIcon } from 'assets/raid.svg'
import { ReactComponent as CrucibleIcon } from 'assets/crucible.svg'
import { ReactComponent as NightfallIcon } from 'assets/strike.svg'
import { ReactComponent as UnstoppableIcon } from 'assets/unstop.svg'
import { ReactComponent as BarrierIcon } from 'assets/barrier.svg'
import { ReactComponent as OverloadIcon } from 'assets/overload.svg'
import { ReactComponent as LostSectorIcon } from 'assets/lostSector.svg'
import { ReactComponent as ResilienceIcon } from 'assets/resilience.svg'
import { ReactComponent as DisciplineIcon } from 'assets/discipline.svg'
import { ReactComponent as RecoveryIcon } from 'assets/recovery.svg'
import moment from 'moment';
import styles from '../../../styles.ts';
import { ExoticArmor } from './exoticArmor.ts'
import { Element, Fragments, Warlock } from './characters.ts'

const fadeProps = {
    initial: {opacity: 0, y: -20},
    exit: {opacity: 0, y: -20},
    animate: {opacity: 1, y: 0},
    transition: {duration: 0.5}
}

const sampleBuild = {
    character: 'Warlock',
    subclass: Element.Void,
    super: Warlock[Element.Void].Super.NovaBomb,
    grenade: Warlock[Element.Void].Grenade.Vortex,
    melee: Warlock[Element.Void].Melee.PocketSingularity,
    class: Warlock[Element.Void].ClassAbility.HealingRift,
    aspects: [Warlock[Element.Void].Aspects.FeedTheVoid, Warlock[Element.Void].Aspects.ChaosAccelerant],
    fragments: [Fragments[Element.Void].Remnants, Fragments[Element.Void].Harvest, Fragments[Element.Void].Undermining, Fragments[Element.Void].Instability],
    exotic: ExoticArmor.Warlock.Arms.ContraverseHolds,
    weapons: {
        kinetic: ['Heritage', 'Conditional Finality', 'Succession'],
        energy: ['Unforgiven', 'Reckless Oracle', 'Collective Obligation'],
        power: ["Leviathan's Breath", 'Braytech Osprey', 'Tarantula'],
    },
    mods: {
        head: ['Void Siphon', 'Ashes to Assets', 'Heavy Ammo Finder'],
        arms: ['Firepower', 'Void Reloader', 'Fastball'],
        chest: ['Arc Elemental Resistance', 'Arc Elemental Resistance', 'Concussive Dampener'],
        legs: ['Recuperation', 'Void Scavenger', 'Void Surge'],
        class: ['Bomber', 'Reaper', 'Elemental Time Dilation'],
    },
    stats: ['Resilience', 'Discipline', 'Recovery'],
    title: "Felwinter's Abyss",
    author: 'angery reaxx',
    description: 'My standard Contraverse loadout',
    youtube: 'https://www.youtube.com/watch?v=HORdXGjum14',
}

const Builds = () => {
    let { character, buildID } = useParams();
    const navigate = useNavigate();

    const goToBuild = (ev) => {
        navigate(`/d2ghost/builds/${character}/${ev.target.id}`)
    }

    const getStatIcon = (stat) => {
        switch(stat) {
            case 'Recovery':
                return <RecoveryIcon fill='white' style={{width: '30px', height: '30px'}}/>
            case 'Resilience':
                return <ResilienceIcon fill='white' style={{width: '30px', height: '30px'}}/>
            case 'Discipline':
            default:
                return <DisciplineIcon fill='white' style={{width: '30px', height: '30px'}}/>
        }
    }

    return <Flex 
        h='100vh' 
        w='100vw'
        backgroundColor='rgb(10,10,10)'
        overflowY='auto'
        backgroundImage='url(https://cdn.wallpapersafari.com/54/28/GiwTOz.jpg)'
        backgroundSize='cover'
        backgroundPosition='center'
    >
        <AnimatePresence exitBeforeEnter>
            {/* {sidebarContent} */}
            <Content>
                <Flex alignItems='center' margin='10px 20px'>
                    <Flex onClick={() => { navigate('/d2ghost') }} userSelect='none' _hover={{ backgroundColor: 'white', color: 'black' }} transition='all 0.2s ease-in' cursor='pointer' alignItems='center' backgroundColor='#171717' borderRadius='4px' border='1px solid white' padding='1px 10px'>
                        <Image width='40px' height='40px' marginRight='10px' src='/d2ghost.png' />
                        D2Ghost
                    </Flex>
                </Flex>
                <AnimatePresence exitBeforeEnter>
                    {!character && <motion.div key='menu' {...fadeProps} style={{display: 'flex', flexDirection: 'column', flex: 1, padding: '20px'}}>
                        <TabHeader>Builds</TabHeader>
                        <ButtonContainer>
                            <Button onClick={() => { navigate('/d2ghost/builds/warlock') }} backgroundColor='rgba(143,155,34,0.25)' backgroundImage={classes.Warlock.image} backgroundSize='cover' margin='10px' border='1px solid white' cursor='pointer' justifyContent='center' alignItems='end' flex={1}>
                                <Flex textTransform='uppercase' margin='20px' fontFamily='Lato' fontSize='32px' letterSpacing='0.2em'>Warlock</Flex>
                            </Button>
                            <Button onClick={() => { navigate('/d2ghost/builds/hunter') }} backgroundColor='rgba(13,17,127,0.25)' backgroundImage={classes.Hunter.image} backgroundSize='cover' margin='10px' border='1px solid white' cursor='pointer' justifyContent='center' alignItems='end' flex={1}>
                                <Flex textTransform='uppercase' margin='20px' fontFamily='Lato' fontSize='32px' letterSpacing='0.2em'>Hunter</Flex>
                            </Button>
                            <Button onClick={() => { navigate('/d2ghost/builds/titan') }} backgroundColor='rgb(191,18,18,0.25)' backgroundSize='cover' transition='all 0.2s ease-in' backgroundImage={classes.Titan.image}  margin='10px' border='1px solid white' cursor='pointer' justifyContent='center' alignItems='end' flex={1}>
                                <Flex textTransform='uppercase' margin='20px' fontFamily='Lato' fontSize='32px' letterSpacing='0.2em'>Titan</Flex>
                            </Button>
                        </ButtonContainer>
                    </motion.div>}
                    {character && !buildID && <motion.div key={character} {...fadeProps} style={{display: 'flex', flexDirection: 'column', flex: 1, padding: '20px'}}>
                        <TabHeader>{character.toUpperCase()}</TabHeader>
                        <div>
                            {['Void', 'Solar', 'Arc', 'Stasis', 'Strand'].map(build => <BuildItem id={build} onClick={goToBuild}>
                                {build}
                            </BuildItem>)}
                        </div>
                    </motion.div>}
                    {buildID && <motion.div key={buildID} {...fadeProps} style={{display: 'flex', flexDirection: 'column', flex: 1, padding: '20px'}}>
                        <BuildHeader>
                            <Flex alignItems='end'>
                                <div>{sampleBuild.title}</div>
                                <Box fontSize='1rem' margin='6px 10px'>by {sampleBuild.author}</Box>
                            </Flex>
                            <Flex>
                                <Box fontSize='1rem' margin='6px 10px'>{sampleBuild.character}</Box>
                                <Box fontSize='1rem' margin='6px 10px'>{sampleBuild.subclass}</Box>
                            </Flex>
                        </BuildHeader>
                        <BuildContainer>
                            <div style={{width: '25%', height: '100%', padding: '20px'}}>
                                {sampleBuild.youtube && <Flex flexDir='column' flex={1} maxWidth='40em'>
                                    <Box h='fit-content'>YouTube</Box>
                                    <Box flex={1}>
                                        <iframe height='100%' width='100%' style={{aspectRatio: '16/9'}} src={`${sampleBuild.youtube.replace('/watch', '/embed').replace('?v=', '/')}?autoplay=1&mute=1`} frameBorder="0" allowFullScreen title='YouTube' />
                                    </Box>
                                </Flex>}
                                <Flex flex={1} flexDir='column' margin='20px 0'>
                                    <Box h='fit-content'>Description</Box>
                                    <Box color='#aaaaaa' flex={1} fontSize='1em' margin='10px 0'>
                                        This build leans hard into the strength of the blinding/weakening finisher bonus from Felwinter's Helm by pairing it with Echo of Obscurity for Invisibility on finisher, Echo of Cessation for bonus damage and Volatile, and Devour for instant heals and grenade regen. Stacking these effects with Proximity Ward for overshields every time you use a finisher, and Bomber + Font of Wisdom for high uptime on Nova Bombs, you can tank entire rooms of enemies while debuffing them for your allies.
                                    </Box>
                                </Flex>
                            </div>
                            <div style={{width: '75%'}}>
                                <Flex justifyContent='space-between' margin='20px'>
                                    <Flex flex={1} alignItems='center' marginRight='20px'>
                                        <Flex
                                            position='relative'
                                            backgroundSize='cover'
                                            backgroundPosition='center'
                                            width='6vw'
                                            height='6vw'
                                            justifyContent='center'
                                            alignItems='center'
                                        >
                                            <Box zIndex='2' overflow='hidden' w='100%' h='100%' backgroundSize='cover' backgroundPosition='center' backgroundImage='url(https://www.bungie.net/common/destiny2_content/icons/b1efa0eaa710653d85e2fcf5321047fb.png)'/>
                                            <Box zIndex='1' backgroundColor='black' position='absolute' overflow='hidden' w='75%' h='75%'  border='1px solid white' transform='rotate(45deg)'/>
                                        </Flex>
                                        <Box marginLeft='20px'>
                                            <Box fontFamily='Lato' fontSize='1.2rem' w='fit-content' color='grey' borderBottom='1px solid grey'>Super</Box>
                                            <Box fontFamily='Lato' fontSize='1.6rem' whiteSpace='nowrap'>{sampleBuild.super.name}</Box>
                                        </Box>
                                        
                                    </Flex>
                                    <Flex flex='1' margin='0 10px' h='100%' flexDir='column' alignItems='space-between'>
                                        {sampleBuild.aspects.map(aspect => 
                                            <Flex alignItems='center' margin='10px' >
                                                <Box w='4vw' h='4vw' border='1px solid white' backgroundImage={aspect.image} backgroundSize='cover' />
                                                <Box marginLeft='20px'>
                                                    <Box fontFamily='Lato' fontSize='1rem' w='fit-content' color='grey' borderBottom='1px solid grey'>Aspect</Box>
                                                    <Box fontFamily='Lato' fontSize='1.2rem'>{aspect.name}</Box>
                                                </Box>
                                            </Flex>
                                        )}
                                    </Flex>
                                    <Flex flex='1' margin='0 10px' h='100%' flexDir='column' alignItems='space-between'>
                                        <Flex alignItems='center' cursor='pointer' margin='10px' border='1px solid transparent' transition='all 0.2s ease-in' _hover={{ backgroundColor: 'rgba(200,200,200,0.2)', border: '1px solid white' }} >
                                            <Box w='4vw' h='4vw' border='1px solid white' backgroundImage={sampleBuild.grenade.image} backgroundSize='cover' />
                                            <Box marginLeft='20px'>
                                                <Box fontFamily='Lato' fontSize='1rem' w='fit-content' color='grey' borderBottom='1px solid grey'>Grenade</Box>
                                                <Box fontFamily='Lato' fontSize='1.2rem'>{sampleBuild.grenade.name}</Box>
                                            </Box>
                                        </Flex>
                                        <Flex alignItems='center' margin='10px' >
                                            <Box w='4vw' h='4vw' border='1px solid white' backgroundImage={sampleBuild.melee.image} backgroundSize='cover' />
                                            <Box marginLeft='20px'>
                                                <Box fontFamily='Lato' fontSize='1rem' w='fit-content' color='grey' borderBottom='1px solid grey'>Melee Ability</Box>
                                                <Box fontFamily='Lato' fontSize='1.2rem'>{sampleBuild.melee.name}</Box>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                    <Flex flex='1' margin='0 10px' h='100%' flexDir='column' alignItems='space-between'>
                                        <Flex alignItems='center' margin='10px' >
                                            <Box w='4vw' h='4vw' border='1px solid white' backgroundImage={sampleBuild.class.image} backgroundSize='cover' />
                                            <Box marginLeft='20px'>
                                                <Box fontFamily='Lato' fontSize='1rem' w='fit-content' color='grey' borderBottom='1px solid grey'>Class Ability</Box>
                                                <Box fontFamily='Lato' fontSize='1.2rem'>{sampleBuild.class.name}</Box>
                                            </Box>
                                        </Flex>
                                        <Flex alignItems='center' margin='10px' >
                                            <Box w='4vw' h='4vw' border='1px solid white' backgroundImage={sampleBuild.exotic.image} backgroundSize='cover' />
                                            <Box marginLeft='20px'>
                                                <Box fontFamily='Lato' fontSize='1rem' w='fit-content' color='grey' borderBottom='1px solid grey'>Exotic Armor</Box>
                                                <Box fontFamily='Lato' fontSize='1.2rem'>{sampleBuild.exotic.name}</Box>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Flex>
                                    <Flex flex={1} justifyContent='start' flexDir='column' padding='0 10px' marginRight='20px'>
                                        <Box>Stat Focus</Box>
                                        <Flex flex={1} flexDir='column'>
                                            {sampleBuild.stats.map(stat => <Flex alignItems='center' color='#aaaaaa' margin='10px 0'>
                                                {getStatIcon(stat)}
                                                <Box fontSize='1.2em' marginLeft='10px' letterSpacing='0.1em'>{stat}</Box>
                                            </Flex>)}
                                        </Flex>
                                    </Flex>
                                    <Box flex={3}>
                                        <Box>Fragments</Box>
                                        <Flex flexWrap='wrap' flex='2' justifyContent='space-between'>
                                            {Array(4).fill(0).map((zero, fragmentIndex) => <Flex margin='10px 0' width='50%' alignItems='center' >
                                                {sampleBuild.fragments[fragmentIndex] && <>
                                                    <Box minW='60px' minH='60px' border='1px solid white' marginRight='10px' backgroundImage={sampleBuild.fragments[fragmentIndex].image} backgroundSize='cover' />
                                                    <Box marginLeft='10px' overflow='hidden'>
                                                        <Box fontFamily='Lato' fontSize='1rem' w='fit-content' color='grey' borderBottom='1px solid grey'>Fragment</Box>
                                                        <Box fontFamily='Lato' whiteSpace='nowrap' fontSize='1.2rem' >{sampleBuild.fragments[fragmentIndex].name}</Box>
                                                    </Box>
                                                </>}
                                            </Flex>)}
                                        </Flex>
                                    </Box>
                                </Flex>
                                <Flex margin='20px'>
                                    <Flex flexDir='column' flex={2}>
                                        <Box>Suggested Weapons</Box>
                                        <Flex flex={1} flexDir='column' justifyContent='space-between'>
                                            <Box>
                                                <Box>Kinetic</Box>
                                                <Flex justifyContent='space-between'>
                                                    {sampleBuild.weapons.kinetic.map(weapon => <Flex flex={1} alignItems='center' textAlign='center' color='grey'>
                                                        <ArmorMod/>
                                                        <Flex flex={1} justifyContent='center'>{weapon}</Flex>
                                                    </Flex>)}
                                                </Flex>
                                            </Box>
                                            <Box>
                                                <Box>Energy</Box>
                                                <Flex justifyContent='space-between'>
                                                    {sampleBuild.weapons.energy.map(weapon => <Flex flex={1} alignItems='center' textAlign='center' color='grey'>
                                                        <ArmorMod/>
                                                        <Flex flex={1} justifyContent='center'>{weapon}</Flex>
                                                    </Flex>)}
                                                </Flex> 
                                            </Box>
                                            <Box>
                                                <Box>Power</Box>
                                                <Flex justifyContent='space-between'>
                                                    {sampleBuild.weapons.power.map(weapon => <Flex flex={1} alignItems='center' textAlign='center' color='grey'>
                                                        <ArmorMod/>
                                                        <Flex flex={1} justifyContent='center'>{weapon}</Flex>
                                                    </Flex>)}
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                    <Flex flex={3} flexDir='column'>
                                        <Box>Armor Mods</Box>
                                        <Flex flexDir='column' alignItems='space-between'>
                                            <Flex flex={1} flexDir='column'>
                                                <Box>Head</Box>
                                                <Flex flex={1}>
                                                    {sampleBuild.mods.head.map(mod => <Flex flex={1} alignItems='center' textAlign='center' color='grey'>
                                                        <ArmorMod/>
                                                        <Flex flex={1} justifyContent='center'>{mod}</Flex>
                                                    </Flex>)}
                                                </Flex>
                                            </Flex>
                                            <Flex flex={1} flexDir='column'>
                                                <Box>Arms</Box>
                                                <Flex flex={1}>
                                                    {sampleBuild.mods.arms.map(mod => <Flex flex={1} alignItems='center' textAlign='center' color='grey'>
                                                        <ArmorMod/>
                                                        <Flex flex={1} justifyContent='center'>{mod}</Flex>
                                                    </Flex>)}
                                                </Flex>
                                            </Flex>
                                            <Flex flex={1} flexDir='column'>
                                                <Box>Chest</Box>
                                                <Flex flex={1}>
                                                    {sampleBuild.mods.chest.map(mod => <Flex flex={1} alignItems='center' textAlign='center' color='grey'>
                                                        <ArmorMod/>
                                                        <Flex flex={1} justifyContent='center'>{mod}</Flex>
                                                    </Flex>)}
                                                </Flex>
                                            </Flex>
                                            <Flex flex={1} flexDir='column'>
                                                <Box>Legs</Box>
                                                <Flex flex={1}>
                                                    {sampleBuild.mods.legs.map(mod => <Flex flex={1} alignItems='center' textAlign='center' color='grey'>
                                                        <ArmorMod/>
                                                        <Flex flex={1} justifyContent='center'>{mod}</Flex>
                                                    </Flex>)}
                                                </Flex>
                                            </Flex>
                                            <Flex flex={1} flexDir='column'>
                                                <Box>Class</Box>
                                                <Flex flex={1}>
                                                    {sampleBuild.mods.class.map(mod => <Flex flex={1} alignItems='center' textAlign='center' color='grey'>
                                                        <ArmorMod/>
                                                        <Flex flex={1} justifyContent='center'>{mod}</Flex>
                                                    </Flex>)}
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </div>
                        </BuildContainer>
                    </motion.div>}
                </AnimatePresence>
            </Content>
        </AnimatePresence>
    </Flex>
}

const ArmorMod = styled.div`
    width: 60px;
    min-width: 60px;
    height: 60px;
    min-height: 60px;
    border: 1px solid white;
`

const BuildContainer = styled.div`
    background-color: rgba(0,0,0,0.7);
    flex: 1;
    display: flex;
`

const BuildItem = styled.div`
    display: flex;
    width: 100%;
    border: 1px solid white;
    border-radius: 4px;
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 0.4rem;
    backdrop-filter: blur(4px);
    font-family: Lato;
    padding: 10px 20px;
    cursor: pointer;
    &:hover {
        color: black;
        background-color: white;
    }
    ${styles.Animation.Transition}
`

const Button = styled(Flex)`
    display: flex;
    background-position: center;
`

const ButtonContainer = styled.div`
    display: flex;
    flex: 1;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const Content = styled(motion.div)`
    display: flex;
    height: 100%;
    width: 100%;
    color: white;
    flex-direction: column;
`

const BuildHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: end;
    font-family: Lato;
    font-size: 2.4rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    border-bottom: 1px solid white;
    height: fit-content;
    width: 100%;
`

const TabHeader = styled.div`
    font-family: Lato;
    font-size: 72px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    border-bottom: 1px solid white;
    height: fit-content;
    width: 100%;
`

const BlurbSubtitle = styled.div`
    width: min-content;
    border-bottom: 1px solid white;
    font-size: 14px;
    // color: grey;
    margin-bottom: 2px;
`

const InnerTitle = styled.div`
    line-height: normal;
    font-size: 1.8em;
    border-radius: 4px;
    padding: 0 2px;
    backdrop-filter: blur(1px);
    background-color: rgba(0,0,0,0.1);
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 20px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    user-select: none;
    width: min-content;
`

const InnerBlurb = styled(motion.div)`
    width: fit-content;
    display: flex;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 6px 10px;
    background-color: rgba(0,0,0,0.5);
    border: 1px solid transparent;
    backdrop-filter: blur(8px);
    white-space: nowrap;
    user-select: none;
    transition: all 0.2s ease-in;
    fill: white;
    margin-top: 10px;
    ${props => props.onClick && `
        cursor: pointer;
        border: 1px solid white;
        &:hover {
            background-color: white;
            color: black !important;
            fill: black !important;
        }
    `}
`

const ColumnItem = styled.div`
    ${props => props.clickable && 'cursor: pointer;'}
    display: flex;
    padding: 4px 10px;
    flex: 1;
    overflow: hidden;
    :not(:last-child) { 
        border-bottom: 1px solid grey;
    }
`

const FeaturedItem = styled.div`
    cursor: pointer;
    display: flex;
    padding: 2px 14px;
    align-items: center;
    flex: 1;
    max-height: 80px;
    min-width: 140px;
    background-color: rgba(0,0,0,0.85);
    ${props => props.selected && `
        background-color: #2222a2 !important;
        color: white !important;
        opacity: 1 !important;
    `}
    &:hover {
        background-color: rgba(255,255,255,0.1);
    }
    :not(:last-child) { 
        border-bottom: 1px solid grey;
    }
    transition: all 0.2s ease-in;
`

const RowItem = styled.div`
    display: flex;
    padding: 10px;
    flex: ${props => props.flex || 1};
    :not(:last-child) { 
        border-right: 1px solid grey;
    }
`

const BubbleContainer = styled.div`
    ${props => props.maxHeight && `max-height: ${props.maxHeight};`}
    ${props => props.minHeight && `min-height: ${props.minHeight};`}
    display: flex;
    flex: ${props => props.flex || 1};
    padding: 10px;
    flex-direction: column;
`

const InnerBubble = styled.div`
    border: 1px solid rgba(255,255,255,0.8);
    // border: 1px solid #2222a2;
    border-radius: 8px;
    // padding: 10px;
    flex: 1;
    font-family: 'Lato';
    display: flex;
    overflow: hidden;
    background-color: rgba(0,0,0,0.2);
    backdrop-filter: blur(4px);
    box-shadow: 10px 5px 5px rgba(0,0,0,0.3);
`

const Title = styled.div`
    margin-left: 15px;
    padding: 2px 12px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-family: 'Lato';
    width: fit-content;
    background-color: #2222a2;
`

const Bubble = (props) => {
    return <BubbleContainer flex={props.flex}>
        <Title>{props.title}</Title>
        <InnerBubble>
            {props.children}
        </InnerBubble>
    </BubbleContainer>
}

export default Builds