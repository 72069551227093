import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation
} from "react-router-dom";
import { Box, Image, Flex, Badge, Text, forwardRef, Heading, Button } from "@chakra-ui/react";
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import styled from 'styled-components';
import styles from '../../../styles';
import { ExpandLess, ExpandMore, Home as HomeIcon, Reorder, Link as MUILink, Close } from '@mui/icons-material';

const SidebarTab = {
    Home: 'Home',
    Hypermap: 'Hypermap',
    D2Ghost: 'D2Ghost',
    MusicNerd: 'MusicNerd',
  }

type NavigationSidebarLayoutProps = {
    onClose: () => void,
}

const NavigationSidebarLayout = (props: NavigationSidebarLayoutProps) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [sidebarTab, setSidebarTab] = useState(SidebarTab.Home)

    const pathname = location.pathname.slice(1)

    return <>
        <Flex w='100%' justifyContent='space-between' alignItems='center'>
            <Box fontSize='24px' color='white'>projects</Box>
            <IconButton onClick={ props.onClose }>
                <StyledClose/>
            </IconButton>
        </Flex>
        <Scrollable>
            <SidebarItem 
                id={SidebarTab.Home}
                onClick={() => { navigate('/') }}
                selected={pathname === ''}
            >
                <Flex>
                    <Image src='hhicon.png' height='40px' borderRadius='50%'  marginTop='5px' marginRight='10px'/>
                    <Box>
                        <SidebarItemLabel className='label'>hysteria house</SidebarItemLabel>
                        <SidebarItemText>project homepage</SidebarItemText>
                    </Box>
                </Flex>
                <Image
                    src='pages/Home/Home.png'
                    minHeight='170px'
                />
            </SidebarItem>
            <SidebarItem 
                id={SidebarTab.Hypermap}
                onClick={() => { window.location.href = 'https://hypermap.co'; }}
            >
                <Flex>
                    <Image src='hyperloopFav.png' height='40px' borderRadius='50%'  marginTop='5px' marginRight='10px'/>
                    <Box>
                        <SidebarItemLabel className='label'>hypermap</SidebarItemLabel>
                        <SidebarItemText>an interactive Hyperloop map.</SidebarItemText>
                    </Box>
                </Flex>
                <Image
                    src='hypermapPreview.png'
                    minHeight='170px'
                />
            </SidebarItem>
            <SidebarItem 
                id={SidebarTab.D2Ghost}
                onClick={() => { navigate('/d2ghost') }}
                selected={pathname === 'd2ghost'}
            >
                <Flex>
                    <Image src='d2ghost.png' height='40px' borderRadius='50%'  marginTop='5px' marginRight='10px'/>
                    <Box>
                        <SidebarItemLabel className='label'>d2ghost</SidebarItemLabel>
                        <SidebarItemText>destiny 2 guides and info</SidebarItemText>
                    </Box>
                </Flex>
                <Image
                    src='d2ghostPreview.png'
                    minHeight='170px'
                />
            </SidebarItem>
            <SidebarItem 
                id={SidebarTab.MusicNerd}
                onClick={() => { navigate('/musicnerd') }}
                selected={pathname === 'musicnerd'}
            >
                <Flex>
                    <Image src='musicnerd.png' height='40px' borderRadius='50%'  marginTop='5px' marginRight='10px'/>
                    <Box>
                        <SidebarItemLabel className='label'>music nerd</SidebarItemLabel>
                        <SidebarItemText>a spotify wrapper with lyrics + more</SidebarItemText>
                    </Box>
                </Flex>
                <Image
                    src='musicnerdPreview.png'
                    minHeight='170px'
                />
            </SidebarItem>
        </Scrollable>
    </>
}

const IconButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    ${styles.Animation.Transition}
    border: 1px solid transparent;
    &:hover {
        border: 1px solid #fc15fc;
        path {
            fill: #fc15fc !important;
        }
    }
`

const StyledClose = styled(Close)`
    path {
        fill: white;
        ${styles.Animation.Transition}
    }
`

const StyledReorder = styled(Reorder)`
    fill: inherit;
    path {
        fill: inherit;
    }
`

const SidebarItemText = styled.div`
    color: grey;
    margin-bottom: 8px;
`

const Scrollable = styled.div`
    margin-top: 20px;
    overflow-y: scroll;
    padding-right: 20px;
    padding-top: 20px;
    flex: 1;
    border-top: 1px solid #fc15fc;
    ${styles.Animation.Transition}
    ${styles.Scrollbar.HysteriaHouse}
`

const SidebarItemLabel = styled.div`
    letter-spacing: 0.1em;
    width: fit-content;
`

const SidebarItem = styled.div`
    width: 100%;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    :not(:last-child) {
        margin-bottom: 10px;
    }
    ${styles.Animation.Transition}
    .label {
        ${props => props.selected ? `color: #fc15fc;` : `color: white;`}
    }
    ${props => props.selected ? `
        border: 1px solid #fc15fc !important;
        ` : `
        border: 1px solid transparent;
    `}
    &:hover {
        border: 1px solid white;
        color: #fc15fc;
    }
`

export default NavigationSidebarLayout