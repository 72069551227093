import { Add, Search } from "@mui/icons-material"
import React from "react"
import styled from "styled-components"
import styles from "../../../../../styles"
import Card from "../Card"
import { motion } from "framer-motion"
import { useNavigate } from "react-router-dom"

const Builds = () => {
    const navigate = useNavigate()

    return <Container {...styles.MotionProps.fadeInOutDown}>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center'}}>
            <Card
                icon={<Add style={{width: 'auto', height: '100px', fill: 'white'}}/>}
                title='How to Play'
                subtitle="What should I do next??"
                onClick={() => { navigate('/d2beta/info/howToPlay') }}
            />
            <Card
                icon={<Add style={{width: 'auto', height: '100px', fill: 'white'}}/>}
                title='Rarity Systems'
                subtitle="What the colors mean."
                onClick={() => { navigate('/d2beta/info/rarity') }}
            />
            <Card
                icon={<Add style={{width: 'auto', height: '100px', fill: 'white'}}/>}
                title='Armor Farming'
                subtitle="How to get that high RES roll."
                onClick={() => { navigate('/d2beta/info/armorFarming') }}
            />
            <Card
                icon={<Add style={{width: 'auto', height: '100px', fill: 'white'}}/>}
                title='Farming R&D'
                subtitle="Artifice Armor, Raid Adepts, and More."
                onClick={() => { navigate('/d2beta/info/farmingEndgame') }}
            />
            <Card
                icon={<Add style={{width: 'auto', height: '100px', fill: 'white'}}/>}
                title='Transmog'
                subtitle="Get your fashion on."
                onClick={() => { navigate('/d2beta/info/transmog') }}
            />
        </div>
    </Container>
}

const Container = styled(motion.div)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.5), rgba(0,0,0,0.7)), url("https://images.contentstack.io/v3/assets/blte410e3b15535c144/blt32cc1dc5c56ee73b/63ebd81ec7306d1b0f973049/FrontPageBanner_PC_1920x590.jpg");
    background-position: center;
    background-size: cover;
`

export default Builds