import React, { useEffect, useState } from 'react';
import emailjs from '@emailjs/browser'
import { Box, Link, Image, Input, Flex, Badge, Text, forwardRef, Heading, Button as ChakraButton, Textarea, background } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import StarField from './StarField';
import SlideIn from './CardPages/SlideIn';
import HomePage from './CardPages/HomePage';
import ProfessionalExperience from './CardPages/ProfessionalExperience';
import Education from './CardPages/Education';
import Portfolio from './CardPages/Portfolio';
import AnaglyphText from './AnaglyphText';
import { motion, isValidMotionProp, AnimatePresence } from 'framer-motion'
import Menu from '@mui/material/Menu';
import Icon from '@mui/material/Icon';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';
import SpotifyIcon from '@mui/icons-material/LibraryMusic';
import MinimalShapes from './MinimalShapes';
import InteractiveCube from './InteractiveCube';
import Hexagons from './Hexagons';
import styles from '../styles';
import styled, { keyframes } from 'styled-components';
import { ExpandLess, ExpandMore, Home as HomeIcon, Reorder, Link as MUILink, Close } from '@mui/icons-material';
import Sidebar from './common/Sidebar/Sidebar'
import NavigationSidebarLayout from './common/Sidebar/NavigationSidebarLayout'

const LinkedInLink = 'https://www.linkedin.com/in/jkimminau/'
const githubLink = 'https://github.com/jkimminau/'
// Make instagram public
const instagramLink = 'https://www.instagram.com/jamisonkimminau/'
const spotifyLink = 'https://open.spotify.com/user/1225066737'

function gradient(startColor, endColor, steps) {
    var start = {
            'Hex'   : startColor,
            'R'     : parseInt(startColor.slice(1,3), 16),
            'G'     : parseInt(startColor.slice(3,5), 16),
            'B'     : parseInt(startColor.slice(5,7), 16)
    }
    var end = {
            'Hex'   : endColor,
            'R'     : parseInt(endColor.slice(1,3), 16),
            'G'     : parseInt(endColor.slice(3,5), 16),
            'B'     : parseInt(endColor.slice(5,7), 16)
    }
    let diffR = end['R'] - start['R'];
    let diffG = end['G'] - start['G'];
    let diffB = end['B'] - start['B'];

    let stepsHex  = new Array();
    let stepsR    = new Array();
    let stepsG    = new Array();
    let stepsB    = new Array();

    for(var i = 0; i <= steps; i++) {
            stepsR[i] = start['R'] + ((diffR / steps) * i);
            stepsG[i] = start['G'] + ((diffG / steps) * i);
            stepsB[i] = start['B'] + ((diffB / steps) * i);
            stepsHex[i] = '#' + Math.round(stepsR[i]).toString(16) + '' + Math.round(stepsG[i]).toString(16) + '' + Math.round(stepsB[i]).toString(16);
    }
    return stepsHex;

}

const SidebarTab = {
    Home: 'Home',
    Hypermap: 'Hypermap',
    D2Ghost: 'D2Ghost',
    MusicNerd: 'MusicNerd',
}

const Home = (props) => {
    const navigate = useNavigate()

    const [backgroundIndex, setBackgroundIndex] = useState(0)
    const [page, setPage] = useState(0)
    const [showMenu, setShowMenu] = useState(false)
    const [anchorEl, setAnchorEl] = useState(0)
    const [theposition, setTheposition] = useState(0)
    const [showContact, setShowContact] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const [sidebarTab, setSidebarTab] = useState(SidebarTab.Home)
    const [name, setName] = useState('')
    const [message, setMessage] = useState('')
    const [email, setEmail] = useState('')

    const openMenu = () => {
        setShowMenu(true)
    }

    const listenScroll = (e) => {
        const winScroll =
          document.body.scrollTop || document.documentElement.scrollTop
      
        const height =
          document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      
        const scrolled = winScroll / height
      
        this.setState({
          theposition: Math.max(Math.floor(e.target.scrollTop), 0),
        })
    }

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    const createSidebarItemClickHandler = (tab) => (ev) => {
        setSidebarTab(tab)
        setIsSidebarOpen(!isSidebarOpen)
    }

    const handleSidebarItemClick = (ev) => {
        console.log(ev.target.id)
        setSidebarTab(ev.target.id)
        setIsSidebarOpen(!isSidebarOpen)
    }

    const  getBackgroundColors = (value) => {
        return [gradient('#871ac7', '#17cde6', 1639)[value], gradient('#e73c7e', '#17e655', 1639)[value]]
        // return  value < 1000 ? ['#871ac7', '#e73c7e'] : ['#17cde6', '#17e655']
    }

    const backgrounds = [
        <MinimalShapes/>,
        <Hexagons/>,
        <InteractiveCube/>
    ]

    const toggleBackground = (value) => {
        const newValue = value === backgrounds.length - 1 ? 0 : value + 1
        setBackgroundIndex(newValue)
        setTimeout(() => { 
            toggleBackground(newValue)
        }, 15000)
    }

    const sendEmail = () => {
        const templateParams = {
            fromName: name,
            message,
            email,
        }

        if ([name, email, message].find(item => item === '') === undefined){
            emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, templateParams, process.env.REACT_APP_EMAILJS_PUB_KEY)
            setName('')
            setEmail('')
            setMessage('')
            setShowContact(!showContact)
        }
    }

    const [color1, color2] = getBackgroundColors(theposition)

    useEffect(() => {
        toggleBackground(backgroundIndex)
    }, [])

    console.log(backgroundIndex)

    return(
        <Container>
            <Sidebar onClose={toggleSidebar} isOpen={isSidebarOpen}>
                <NavigationSidebarLayout onClose={toggleSidebar}/>
            </Sidebar>
            <AnimatePresence>
                <motion.div key={backgroundIndex} {...styles.MotionProps.fadeInOut}>
                    {backgrounds[backgroundIndex]}
                </motion.div>
            </AnimatePresence>
            <Flex
                onClick={toggleSidebar}
                className='HomeIcon'
                width='60px'
                height='60px'
                color='white'
                justifyContent='center'
                alignItems='center'
                position='absolute'
                top='20px'
                left='20px'
                borderRadius='50%'
                cursor='pointer'
                zIndex='3'
                border='1px solid transparent'
                transition='all 0.2s ease-in'
                overflow='hidden'
                _hover={{ border: '1px solid #fc15fc', path: { fill: '#fc15fc !important' } }}
            >
                <StyledReorder/>
                {/* <Image src='hhicon.png'/> */}
            </Flex>
            <Main>
                <LinksContainer>
                    <StyledLink target='_blank' href={LinkedInLink}>
                        <LinkedInIcon style={{ path: { transition: 'all 0.2s ease-in'} }} />
                    </StyledLink>
                    <StyledLink target='_blank' href={githubLink}>
                        <GitHubIcon  style={{ path: { transition: 'all 0.2s ease-in'} }} />
                    </StyledLink>
                    <StyledLink target='_blank' href={instagramLink} >
                        <InstagramIcon style={{ path: { transition: 'all 0.2s ease-in'} }} />
                    </StyledLink>
                    <StyledLink target='_blank' href={spotifyLink}>
                        <SpotifyIcon style={{ path: { transition: 'all 0.2s ease-in'} }} />
                    </StyledLink>
                </LinksContainer>
                <Content>
                    <AnimatePresence exitBeforeEnter>
                        <TextContent key={'welcome'} {...styles.MotionProps.fadeInOut}>
                            {showContact ? <>
                                <StandardText style={{margin: '0 0 10px'}}>contact me for opportunities, questions, or other feedback. </StandardText>
                                <StandardText style={{margin: '0 0 20px'}}>thank you :)</StandardText>
                                <Box marginBottom='10px'>
                                    <StandardText style={{margin: '0'}}>your name</StandardText>
                                    <Input value={name} onChange={(ev) => { setName(ev.target.value) }} animation={undefined} border='1px solid white !important'/>
                                </Box>
                                <Box marginBottom='10px'>
                                    <StandardText style={{margin: '0'}}>e-mail</StandardText>
                                    <Input value={email} onChange={(ev) => { setEmail(ev.target.value) }} animation={undefined} border='1px solid white !important'/>
                                </Box>
                                <Box marginBottom='10px'>
                                    <StandardText style={{margin: '0'}}>message</StandardText>
                                    <Textarea value={message} onChange={(ev) => { setMessage(ev.target.value) }} minHeight='80px' animation={undefined} border='1px solid white !important'/>
                                </Box>
                                <button style={{ backgroundColor: '#8e1fb6', borderRadius: '4px', margin: '20px 0 0 0', height: '40px' }} onClick={sendEmail}>send message</button>
                            </> : <>
                                <div>
                                    <Title>hysteria house</Title>
                                    <Subtitle>jamison kimminau &bull; fullstack developer</Subtitle>
                                </div>
                                <StandardText>welcome to hysteria house -- my hub for a few coding projects.</StandardText>
                                <StandardText>inspired by a unique background of digital art and open-source education, i strive to create software that feels organic.</StandardText>
                                <ButtonContainer>
                                    <Button as={RouterLink} onClick={toggleSidebar}>projects</Button>
                                    <Button as={RouterLink} onClick={() => { setShowContact(!showContact) }}>contact me</Button>
                                </ButtonContainer>
                            </>}
                        </TextContent>
                    </AnimatePresence>
                </Content>
            </Main>
    </Container>
    );

    getPageContent = () => {
        const { page } = this.state;

        switch(page) {
            case 3:
                return <Portfolio nextPage={this.nextPage}/>
            case 2:
                return <Education nextPage={this.nextPage}/>
            case 1:
                return <ProfessionalExperience nextPage={this.nextPage}/>
            case 0:
            default:
                return <HomePage nextPage={this.nextPage}/>
        }
    }

    nextPage = () => {
        const { page } = this.state;
        this.setState({page: page > 2 ? 0 : page + 1})
    }

    prevPage = () => {
        const { page } = this.state;
        this.setState({page: page === 0 ? 1 : page - 1})
    }
}

const Main = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const SidebarWrapper = styled(motion.div)`
    position: absolute;
    height: 100vh;
    width: 100vw;
    backdrop-filter: blur(4px);
    z-index: 999;
`

const Scrollable = styled.div`
    margin-top: 20px;
    overflow-y: scroll;
    padding-right: 20px;
    flex: 1;
    ${styles.Scrollbar.HysteriaHouse}
`

const IconButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    ${styles.Animation.Transition}
    border: 1px solid transparent;
    &:hover {
        border: 1px solid #fc15fc;
        path {
            fill: #fc15fc !important;
        }
    }
`

const StyledClose = styled(Close)`
    path {
        fill: white;
        ${styles.Animation.Transition}
    }
`

const StyledReorder = styled(Reorder)`
    path {
        ${styles.Animation.Transition}
        fill: white;
    }
`

const SidebarItemText = styled.div`
    color: grey;
    margin-bottom: 8px;
`

const SidebarItemLabel = styled.div`
    letter-spacing: 0.1em;
    width: fit-content;
`

const SidebarItem = styled.div`
    width: 100%;
    cursor: pointer;
    padding: 10px;
    border-radius: 8px;
    :not(:last-child) {
        margin-bottom: 10px;
    }
    ${styles.Animation.Transition}
    .label {
        ${props => props.selected ? `color: #fc15fc;` : `color: white;`}
    }
    ${props => props.selected ? `
        border: 1px solid #fc15fc !important;
        ` : `
        border: 1px solid transparent;
    `}
    &:hover {
        border: 1px solid white;
        color: #fc15fc;
    }
`

const Container = styled.div`
    display: flex;
    position: relative;
    width: 100vw;
    height: 100vh;
    max-height: -webkit-fill-available;
    overflow: hidden;
    background-color: black;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column-reverse;
    }
`

const LinksContainer = styled.div`
    display: flex;
    padding: 20px;
    
    color: white;
    ${styles.MediaQueries.Desktop} {
        height: 100%;
        flex-direction: column;
        justify-content: center;
    }
    ${styles.MediaQueries.Mobile} {
        height: fit-content;
        width: 100%;
        justify-content: end;

    }
`

const StyledLink = styled(Link)`
    margin: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    box-shadow: none !important;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    ${styles.Animation.Transition}
    &:hover {
        border: 1px solid #fc15fc;
        path {
            fill: #fc15fc !important;
        }
    };
`

const StandardText = styled(Text)`
    font-family: Lato;
    font-size: 18px;
    overflow: hidden;
    white-space: normal;
    height: fit-content;
    ${styles.MediaQueries.Desktop} {
        margin: 60px 0;
    }
    ${styles.MediaQueries.Mobile} {
        text-align: center;
        font-size: 1.0rem;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    ${styles.MediaQueries.Mobile} {
        flex-direction: column;
    }
`

const animatedPurpleBorder = keyframes`
    0% { border: 1px solid blue; }
    25% { border: 1px solid #8e1fb6; }
    50% { border: 1px solid purple; }
    75% { border: 1px solid #8e1fb6; }
    100% { border: 1px solid blue; }
`

const TextContent = styled(motion.div)`
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(4px);
    padding: 20px 20px;
    border-radius: 15px;
    overflow: hidden;
    transition: all 0.2s ease-in;
    animation: ${animatedPurpleBorder} 10s ease infinite;
    ${styles.MediaQueries.Mobile} {
        width: 100%;
        max-width: 100%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgba(0,0,0,0.4);
    }
    ${styles.MediaQueries.Desktop} {
        margin: 0 20px;
        background-color: rgba(0,0,0,0.3);
    }
`



const Button = styled(ChakraButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato;
    border-radius: 4px;
    min-width: 86px;
    background-color: #8e1fb6;
    color: white;
    border: 1px solid #8e1fb6;
    ${styles.Animation.Transition}
    ${styles.MediaQueries.Mobile} {
        height: 50px;
        :not(:last-child) {
            margin-bottom: 20px;
        }
    }
    ${styles.MediaQueries.Desktop} {
        height: 40px;
        padding: 30px 40px;
        margin-right: 20px;
    }
    &:hover {
        box-shadow: 0 0 20px purple;
        background-color: rgba(0,0,0,0.6);
    }
`

const Content = styled.div`
    display: flex;
    flex: 1;
    color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    ${styles.MediaQueries.Mobile} {
        width: calc(100% - 40px);
        margin: 20px;
        min-height: 200px;
    }
    ${styles.MediaQueries.Desktop} {
        min-width: 280px;
        height: 100%;
        margin: 0 20px;
    }
`

const Subtitle = styled.div`
    font-family: Roboto;
    overflow: hidden;
    ${styles.MediaQueries.Desktop} {
        font-size: 1.6rem;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 1.0rem;
    }
`

const Title = styled.div`
    letter-spacing: 0.05em;
    font-family: Roboto;
    overflow: hidden;
    ${styles.MediaQueries.Desktop} {
        font-size: 72px;
    }
    ${styles.MediaQueries.Mobile} {
        font-size: 2.4rem;
    }
`

export default Home;
